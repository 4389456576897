var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answer-parse"},[_c('navBar',{scopedSlots:_vm._u([(_vm.answerStatus.length)?{key:"right",fn:function(){return [_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.questionObject[_vm.activeQuestion].parentNum + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.questionObject[_vm.questionObject.length - 1].parentNum + 1))])])]},proxy:true}:null],null,true)},[_c('span',[_vm._v("查看解析")])]),_c('div',{staticClass:"proress"},[_c('div',{staticClass:"con",style:(("width:" + (((_vm.activeQuestion + 1) / _vm.answerStatus.length) * 100) + "%"))})]),(_vm.answerStatus.length)?_c('van-tabs',{attrs:{"swipeable":false},model:{value:(_vm.activeQuestion),callback:function ($$v) {_vm.activeQuestion=$$v},expression:"activeQuestion"}},_vm._l((_vm.answerStatus),function(item,index){return _c('van-tab',{key:index,attrs:{"name":item.num - 1,"title-class":{
        empty: item.status == 0,
        success: item.status == 1,
        error: item.status == 2,
        subject: item.status == 3,
      }}},[_c('div',{staticClass:"item-question"},[_c('div',{staticClass:"item-content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.questionBody.content)}})]),_c('div',{staticClass:"item-qa"},[_c('div',{staticClass:"item-q",domProps:{"innerHTML":_vm._s(_vm.testpapeContent)}}),_c('div',{staticClass:"item-a"},[(_vm.questionType < 3)?_c('ul',{staticClass:"options"},_vm._l((_vm.qusetionOptionjson),function(opt,index){return _c('li',{key:("a" + index),class:{
                  success:
                    String(_vm.questionBody.answerIdx).indexOf(index) != -1 &&
                    _vm.answerStatus[_vm.activeQuestion].status == 1,
                  error:
                    String(_vm.questionBody.answerIdx).indexOf(index) != -1 &&
                    _vm.answerStatus[_vm.activeQuestion].status == 2,
                }},[_c('span',{staticClass:"tag"},[_vm._v(_vm._s(opt.value))]),_c('span',{staticClass:"txt",domProps:{"innerHTML":_vm._s(opt.text)}})])}),0):_c('div',{staticClass:"a-box"},[_c('van-field',{attrs:{"type":"textarea","disabled":true},model:{value:(_vm.qusetionAnswer),callback:function ($$v) {_vm.qusetionAnswer=$$v},expression:"qusetionAnswer"}})],1)])]),_c('div',{staticClass:"item-p"},[_c('div',{staticClass:"answer"},[_c('span',{staticClass:"txt"},[_vm._v("【您的答案】")]),(_vm.questionBody.answerVal)?_c('span',{staticClass:"con",domProps:{"innerHTML":_vm._s(_vm.questionBody.answerVal)}}):_c('span',[_vm._v("未作答")])]),_c('div',{staticClass:"answer"},[_c('span',{staticClass:"txt"},[_vm._v("【正确答案】")]),_c('span',{staticClass:"con",domProps:{"innerHTML":_vm._s(_vm.questionBody.rightkey)}})]),_c('div',{staticClass:"answer"},[_c('span',{staticClass:"txt"},[_vm._v("【答案解析】")]),(_vm.questionBody.analysiscontent)?_c('span',{staticClass:"con",domProps:{"innerHTML":_vm._s(_vm.questionBody.analysiscontent)}}):_c('span',[_vm._v("略")])])])])])}),1):_vm._e(),_c('div',{staticClass:"question-handle"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(1)}}},[_vm._v("上一题")]),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(0)}}},[_vm._v("下一题")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }