<template>
  <div class="user-test">
    <navBar>
      <span>{{ currentTitle }}</span>
    </navBar>
    <template v-if="testQuestionData.length">
      <div class="proress">
        <div
          class="con"
          :style="`width:${((current + 1) / testQuestionData.length) * 100}%`"
        ></div>
      </div>

      <div class="acount-num-time">
        <div class="num">
          <span class="large">{{ currentQuestionModle + 1 }}</span>
          <span class="small">/{{ accountReauest }}</span>
        </div>
        <div class="time">
          <span class="tit">已用时间：</span>
          <span v-for="(tiem, index) in currentTime.split(':')" :key="index">
            <span class="itme-item">{{ tiem }}</span>
            <i>{{ index != currentTime.split(":").length - 1 ? ":" : "" }}</i>
          </span>
        </div>
      </div>

      <div class="test-box" ref="testBox">
        <div class="qusetion-body" v-if="testQuestionData[current]">
          <!-- <div class="qtit" style="font-size: 0.3rem; font-weight: 500">
            {{ testQuestionData[current].title }}
          </div> -->
          <div
            class="qcontent"
            style="font-size: 0.3rem; margin-bottom: 0.5rem"
            v-html="testQuestionData[current].content"
            v-if="testQuestionData[current].questionType == 6"
          ></div>

          <div
            class="question-mean"
            v-html="testQuestionData[current].testpapecontent"
          ></div>
          <div class="answer-area">
            <ul class="option" v-if="testQuestionData[current].type < 3">
              <li
                v-for="(opt, idx) in testQuestionData[current].optionjson"
                :key="`opt${idx}`"
                :class="{
                  select: opt.status == 1,
                  proper: opt.status == 2,
                  error: opt.status == 3,
                }"
                @click="selectOpt(idx, opt, testQuestionData[current].type)"
              >
                {{ opt.value }}<span class="gap"></span
                ><span v-html="opt.text"></span>
              </li>
            </ul>
            <div v-if="2 < testQuestionData[current].type" class="answer-box">
              <van-field
                v-model="testQuestionData[current].answerVal"
                type="textarea"
                rows="3"
                :disabled="!startAnswer"
              ></van-field>
            </div>
          </div>
          <!-- <ul class="question-list">
            <li
              v-for="(question, index) in testQuestionData[current]"
              :key="`q${index}`"
            >
              
            </li>
          </ul> -->
        </div>
      </div>
      <div class="question-handle">
        <div class="btn start-btn" v-if="!startAnswer" @click="startAction">
          {{ submited ? "继续答题" : "开始答题" }}
        </div>
        <template v-else>
          <div class="btn" @click="changeQuestion(1)">上一题</div>
          <div class="btn" @click="changeQuestion(0)">下一题</div>
          <div class="btn jiexi-btn" @click="submitAction">查看解析</div>
        </template>
      </div>
    </template>

    <!-- <div class="submit-layer" v-if="submited" @click="continueAction">
      <div
        class="submit-main"
        :style="`backgroundImage:url(${submitBg})`"
        @click.stop="submited = true"
      >
        <div class="con">
          <ul class="qlist">
            <li
              :class="{
                right: item.status == 1,
                error: item.status == 2,
                free: item.status == 3,
              }"
              v-for="(item, index) in answerStatus"
              :key="`cc${index}`"
            >
              {{ item.num }}
            </li>
          </ul>
          <div class="notify">
            <p>灰色部分为未答题目；</p>
            <p>红色部分为答错题目；</p>
            <p>橙色部分为主管题目；</p>
          </div>
          <div class="continue" @click.stop="continueAction">继续答题</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  computeOptionjson,
  formatAddPointWords,
  formatImg,
  html2string,
  middleImg,
} from "@/utils/public";
export default {
  name: "userTest",
  watch: {
    accountReauest: function (val) {
      if (this.totalRequest == val) {
        this.loadingEnd = true;
        Object.keys(this.testQuestionObj).forEach((item) => {
          this.testQuestionData = [
            ...this.testQuestionData,
            ...this.testQuestionObj[item],
          ];
        });
        this.$store.commit("common/setQuestionObject", this.testQuestionData);
        this.$nextTick(() => {
          this.setImgStyle();
        });
      }
    },
    current: function (val) {
      let num = this.testQuestionData[val].parentNum;
      this.currentQuestionModle = num;
    },
  },

  data() {
    return {
      submitBg: require("@/assets/img/submitBg.png"),
      startAnswer: false,
      submited: false,
      loadingEnd: false,
      answerStatus: [],
      timer: null,
      accountTime: 0,
      currentTime: "00:00:00",
      current: 0,
      currentQuestionModle: 0,
      totalScore: 0,
      testQuestionData: [],
      testQuestionObj: {},
      totalRequest: 0,
      accountReauest: 0,
      currentCourse: "",
    };
  },
  created() {
    this.currentCourse = this.$route.query.class;
    this.initTestQuestion();
  },
  mounted() {
    this.addHistory()
  },
  methods: {
    computeOptionjson,
    formatAddPointWords,
    formatImg,
    html2string,
    middleImg,
    addHistory() {
      this.$api
        .addLearnHistory({
          course_id: this.$route.query.courseId,
          coursechapters_id: this.$route.query.id,
          rateoflearning: "0",
          learning_info: this.$store.getters["common/getUnitNav"],
          active: this.$route.query.active,
          showToast:1
        })
        .then();
    },
    setImgStyle() {
      this.MathJax.MathQueue("math-formula"); //传入组件id，让组件被MathJax渲染
    },
    initTestQuestion() {
      this.loadingEnd = false;
      this.$api
        .questionBase({
          papeid:
            this.$route.query.binddata && this.$route.query.binddata != 0
              ? this.$route.query.binddata
              : null || this.$route.query.id,
        })
        .then((res) => {
          if (res.data?.id) {
            this.getQuestionType(res.data.id);
          }
        });
    },
    getQuestionType(id) {
      this.$api.questionTitle({ papeinfoid: id, showToast: 1 }).then((res) => {
        let resData = res.data || [];
        this.totalRequest = resData.length;
        if (resData.length) {
          for (let i = 0; i < resData.length; i++) {
            this.getQuestionContent(resData, i, resData[i].content);
          }
        }
      });
    },
    getQuestionContent(data, i, content) {
      this.$api
        .questionContent({ papetypeid: data[i].id, showToast: 1 })
        .then((res) => {
          let resData = res.data;
          resData.forEach((item, index) => {
            item.content = content;
            item.questionType = data[i].type;
            item.parentNum = i;
            item.answerVal = "";
            item.answerIdx = "";
            item.questionIdx = [i, index];
            item.showParse = false;
            item.showAnswer = false;
            if (["语文", "英语"].includes(this.currentCourse)) {
              item.optionjson = this.computeOptionjson(
                this.formatAddPointWords(item.optionjson)
              );
              item.content = this.formatAddPointWords(data[i].content || "");
              item.title = data[i].title || "";
              item.testpapecontent = this.formatAddPointWords(
                item.testpapecontent
              );

              item.rightkey = this.formatAddPointWords(item.rightkey);

              item.analysiscontent = this.formatAddPointWords(
                item.analysiscontent
              );
            } else {
              item.optionjson = this.computeOptionjson(item.optionjson);
            }

            item.testpapecontent = this.formatImg(item.testpapecontent);
            item.rightkey = this.formatImg(item.rightkey);
            item.analysiscontent = this.formatImg(item.analysiscontent);
            item.testpapecontent = this.middleImg(item.testpapecontent);
            item.rightkey = this.middleImg(item.rightkey);
            item.analysiscontent = this.middleImg(item.analysiscontent);

            item.content = this.formatAddPointWords(content);
            // if (item.type == 6) {
            //   item.content = content;
            // }
          });
          this.$set(this.testQuestionObj, i, resData);
          this.accountReauest = ++this.accountReauest;
        });
    },

    selectOpt(idx1, opt, type) {
      if (this.startAnswer) {
        let optionJson = this.testQuestionData[this.current].optionjson;
        let answerIdx = this.testQuestionData[this.current].answerIdx;
        let answerVal = this.testQuestionData[this.current].answerVal;
        if (type == 1) {
          if (answerIdx.includes(idx1)) {
            return;
          }
          answerIdx = `${answerIdx}${idx1}`;
          answerVal = `${answerVal}${opt.value}`;
        } else {
          answerIdx = idx1;
          answerVal = opt.value;
        }
        this.$set(this.testQuestionData[this.current], "answerVal", answerVal);
        this.$set(this.testQuestionData[this.current], "answerIdx", answerIdx);
        if (optionJson) {
          optionJson.forEach((item, index) => {
            if (type == 0 || type == 2) {
              item.status = 0;
            }

            if (idx1 == index) {
              item.status = 1;
            }
          });
          this.$set(
            this.testQuestionData[this.current],
            "optionjson",
            optionJson
          );
        }
      } else {
        this.$notify({
          type: "warning",
          message: "请先点击“开始答题",
        });
      }
    },

    computeStatus() {
      let n = 0;
      let arr = [];
      let list = this.testQuestionData;
      for (let j = 0; j < list.length; j++) {
        let answerVal = list[j].answerVal;
        let answerIdx = list[j].answerIdx;
        let rightkey = this.html2string(list[j].rightkey);
        let type = list[j].type;
        let optionJson = list[j].optionjson;
        if (optionJson) {
          optionJson.forEach((item, index) => {
            if (answerIdx == index) {
              for (let i = 0; i < answerVal.length; i++) {
                if (rightkey.includes(answerVal[i])) {
                  item.status = 2;
                } else {
                  item.status = 3;
                }
              }
            }
          });
          this.$set(this.testQuestionData[j], "optionjson", optionJson);
        }

        if (answerVal) {
          if (type < 3) {
            if (type == 0 || type == 2) {
              if (answerVal == rightkey) {
                arr.push({
                  status: 1,
                  num: ++n,
                });
              } else {
                arr.push({
                  status: 2,
                  num: ++n,
                });
              }
            } else {
              answerVal = answerVal.split("");
              answerVal.sort(function (a, b) {
                let aa = a.toUpperCase();
                let bb = b.toUpperCase();
                if (aa < bb) {
                  return -1;
                }
                if (aa > bb) {
                  return 1;
                }
                return 0;
              });
              answerVal = answerVal.join("");
              if (rightkey == answerVal) {
                arr.push({
                  status: 1,
                  num: ++n,
                });
              } else {
                arr.push({
                  status: 2,
                  num: ++n,
                });
              }
            }
          } else {
            arr.push({
              status: 3,
              num: ++n,
            });
          }
        } else {
          arr.push({
            status: 0,
            num: ++n,
          });
        }
      }

      this.$store.commit("common/setAnswerStatus", arr);
      this.answerStatus = arr;
    },
    submitAction() {
      clearInterval(this.timer);
      this.startAnswer = false;
      this.submited = true;

      this.computeStatus();
      this.$router.push({
        path: "/answerParse",
      });
    },
    continueAction() {
      this.submited = false;
      this.startAction();
    },
    startAction() {
      this.startAnswer = true;
      this.computeTIme();
    },

    computeTIme() {
      clearInterval(this.timer);
      let i = this.accountTime;
      this.timer = setInterval(() => {
        i++;
        let second = i % 60 < 10 ? `0${i % 60}` : i % 60;
        let minutes = parseInt(i / 60);
        let hour =
          parseInt(minutes / 60) < 10
            ? `0${parseInt(minutes / 60)}`
            : parseInt(minutes / 60);
        let minute =
          parseInt(minutes - hour * 60) < 10
            ? `0${parseInt(minutes - hour * 60)}`
            : parseInt(minutes - hour * 60);
        this.accountTime = i;
        this.currentTime = `${hour}:${minute}:${second}`;
      }, 1000);
    },
    changeQuestion(n) {
      // this.$refs["testBox"].scrollTop = 0;
      switch (n) {
        case 0: {
          if (this.current < this.testQuestionData.length - 1) {
            this.current = ++this.current;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是最后一题了！",
            });
          }
          break;
        }
        case 1: {
          if (this.current) {
            this.current = --this.current;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是第一题了！",
            });
          }
          break;
        }
        default: {
          break;
        }
      }
      this.$nextTick(() => {
        this.setImgStyle();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-test {
  ::v-deep .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
.qcontent {
  ::v-deep * {
    font-size: 0.3rem !important;
    line-height: 1.6 !important;
  }
}
.test-box {
  font-family: initial !important;
}
.user-test {
  padding-top: 1.23rem;
  min-height: 100vh;
  background: #fafafa;
  ::v-deep img{
    height: auto !important;
  }
}
.answer-box {
  border: 0.01rem #969799 solid;
  border-radius: 0.1rem;
  overflow: hidden;
}
.option {
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.32rem;
    margin-bottom: 0.32rem;
    background: #f8f8f8;
    font-size: 0.3rem;
    line-height: 1.6;
    color: #3a3a3a;
    border-radius: 0.1rem;
    .gap {
      width: 0.02rem;
      height: 0.24rem;
      background: #d8d8d8;
      margin: 0 0.2rem;
    }
    ::v-deep .mubiaozi {
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: -0.07rem;
        transform: translateX(-50%);
        content: "";
        width: 0.07rem;
        height: 0.07rem;
        border-radius: 50%;
        background: #333;
      }
    }
    ::v-deep .kongge {
      padding: 0 0.05rem;
    }

    &.select {
      background: #e2f5f1;
    }
    &.proper {
      background: #28b295;
      color: #fff;
    }
    &.error {
      background: #ff1e1e;
      color: #fff;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.1;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.submit-layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.23rem;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .submit-main {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 5.8rem;
    min-height: 6.12rem;
    padding-top: 2.8rem;
    border-radius: 0.1rem;
    overflow: hidden;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 5.8rem auto;
    animation: fadeIn 0.3s ease-in-out;
    .con {
      flex: 1;
      width: 100%;
      background: #fff;
    }
    .continue {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 0.88rem;
      margin: 0.4rem auto;
      background: #28b295;
      border-radius: 0.44rem;
      color: #fff;
      font-size: 0.328em;
      font-weight: 500;
      &:active {
        opacity: 0.8;
      }
    }
    .notify {
      font-size: 0.22rem;
      color: #d3d6d5;
      padding: 0 0.3rem;
    }
    .qlist {
      padding-top: 0.5rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.3rem;
        margin-bottom: 0.3rem;
        border-radius: 50%;
        background: #d3d6d5;
        font-size: 0.28rem;
        color: #fff;
        &.right {
          background: #28b295;
        }
        &.error {
          background: #ff1e1e;
        }
        &.free {
          background: #ff6034;
        }
      }
    }
  }
}
.qusetion-body {
  .qtit {
    margin-bottom: 0.5rem;
  }
}
.question-list {
  li {
    margin-bottom: 0.5rem;
  }
}
.question-mean {
  font-size: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.6;
  overflow: auto;
  ::v-deep .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
  ::v-deep img {
    max-width: 100%;
  }
}
.proress {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.08rem;
  z-index: 3;
  height: 0.15rem;
  background: #f7f8fa;
  .con {
    height: 100%;
    background: #28b295;
    width: 50%;
  }
}
.test-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 2.4rem;
  bottom: 1.26rem;
  font-size: 0.3rem;
  color: #333;
  padding: 0.28rem;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.result-box {
  margin-top: 0.3rem;
  border: 0.01rem #28b295 solid;
  border-radius: 0.1rem;
  min-height: 3rem;
  padding: 0.28rem;
  font-size: 0.24rem !important;
  color: #333 !important;
  line-height: 2 !important;
}
.acount-num-time {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.23rem;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.28rem;
  background: #fff;
  .time {
    display: flex;
    align-items: center;
    color: #ff1e1e;
    font-size: 0.24rem;
    font-weight: 400;
    .tit {
      color: #333;
    }
    .itme-item {
      display: inline-block;
      text-align: center;
      line-height: 0.56rem;
      width: 0.48rem;
      height: 0.56rem;
      background: #36ba9e;
      border-radius: 0.04rem;
      color: #fff;
      font-size: 0.28rem;
    }
    i {
      font-style: normal;
      padding: 0 0.1rem;
      color: #36ba9e;
    }
  }
  .num {
    display: flex;
    align-items: flex-end;
    margin-right: 0.3rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
    .large {
      color: #28b295;
      font-size: 0.48rem;
      transform: translateY(0.03rem);
    }
  }
}

.page-content {
  .qtit {
    margin-bottom: 0.3rem;
    font-size: 0.3rem !important;
    line-height: 2 !important;
    font-weight: 400 !important;
    color: #333 !important;
  }
  .qq {
    font-size: 0.3rem !important;
    font-weight: 400 !important;
    line-height: 0.42rem;
    color: #333;
  }
}
.question-handle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.28rem;
  background: #fff;
  .btn {
    width: 2.1rem;
    height: 0.68rem;
    border: 0.02rem solid #28b295;
    border-radius: 0.34rem;
    text-align: center;
    line-height: 0.68rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333;
    background: #fff;
    &:active {
      background: rgba(40, 178, 149, 0.1);
    }
    &.disable {
      border-color: #e1e1e1;
      background: #f1f1f1;
    }
  }
  .start-btn {
    width: 100%;
    background: #28b295;
    color: #fff;
  }
}
</style>