<template>
	<div class="text-content" id="image-preview">
		<navBar>
			<span>{{ currentTitle }}</span>
		</navBar>
		<ul class="class-list" v-if="1 < contentData.length && currentTitle == '原文解析'">
			<li
				v-for="(item, index) in contentData"
				:key="index"
				:class="{ active: currentClass == index }"
				@click="changeClass(index)"
			>
				<span>文章{{ Number(index) + 1 }}</span>
			</li>
		</ul>
		<div class="content-body" id="imgTextContent">
			<template v-if="currentTitle == '原文解析'">
				<div class="body-content" :class="{ hasMore: 1 < contentData.length }" v-if="contentData.length">
					<van-image
						fit="cover"
						:src="setSourceUrl(contentData[currentClass].img)"
						class="class-image"
					></van-image>
					<div class="class-title">
						{{ contentData[currentClass].title }}
					</div>
					<div class="class-auther">
						{{ contentData[currentClass].auther }}
					</div>
					<div
						class="class-content"
						:style="`text-indent:${contentData[currentClass].courseclass_id == 2 ? '0' : '2em'}`"
						id="classContent"
					>
						<div
							class="con"
							:class="{ center: contentData[currentClass].courseclass_id == 2 }"
							v-html="contentData[currentClass].content"
						></div>
					</div>
				</div>
			</template>

			<div class="body-content" v-else>
				<van-swipe class="my-swipe textBody" indicator-color="#20a287" v-if="imageData.length&&istong == 0">
					<van-swipe-item v-for="(img, index) in imageData" :key="index">
						<div class="image-box textBody">
							<van-image fit="scale-down" :src="img"></van-image>
						</div>
					</van-swipe-item>
				</van-swipe>

				<div class="class-content" v-if="contentData.content && !contentData.flash">
					<div class="con" v-html="contentData.content"></div>
				</div>
				<div class="video-box" v-if="contentData.videourl">
					<div id="dplayer" class="dplayer"></div>
				</div>
				<div class="flash-box" v-if="contentData.flash">
					<span class="notify">不支持Flash播放，请移步PC端使用360浏览器学习此模块知识。</span>
					<!-- <object style="height: 100%">
            <embed
              :src="setSourceUrl(contentData.flash)"
              width="100%"
              height="100%"
            />
          </object> -->
				</div>
			</div>
		</div>

		<audio
			:src="audioFileSrc"
			controlslist="nodownload"
			controls="controls"
			ref="audioFile"
			class="status-vioce"
		></audio>
	</div>
</template>

<script>
import { setSourceUrl, removeSpace, formatImg, html2string, setMathBa, middleImg } from '@/utils/public';
import { ImagePreview } from 'vant';
import DPlayer from 'dplayer';
export default {
	name: 'textContent',
	data() {
		return {
			videoMask1: require('@/assets/img/mask1.png'),
			videoMask2: require('@/assets/img/mask2.png'),
			videoMask3: require('@/assets/img/mask3.png'),
			videoMask4: require('@/assets/img/mask4.png'),
			videoMask5: require('@/assets/img/mask5.png'),
			isLoading: false,
			currentClass: 0,
			contentData: [],
			imageData: [],

			audioFile: null,
			audioSource: {},
			audioFileSrc: '',
			istong:this.$route.query.istong||0
		};
	},
	watch: {
		contentData: {
			handler: function (val) {
				this.$nextTick(() => {
					this.setImgStyle();
					if (val.videourl) {
						let videoMask = '';
						let moudleName = window.sessionStorage.getItem('moudleName');
						let parentName = window.sessionStorage.getItem('parentName');
						if (moudleName == '语法知识') {
							videoMask = this.videoMask2;
						} else if (moudleName == '名师考点精讲') {
							videoMask = this.videoMask4;
						} else if (['高分课堂'].includes(parentName)) {
							videoMask = this.videoMask1;
						} else if (['同步课堂'].includes(parentName)) {
							videoMask = this.videoMask5;
						} else {
							videoMask = this.videoMask3;
						}

						this.initVideo(val.videourl, videoMask);
					}
				});
			},
			deep: true
		}
	},
	computed: {
		isWechat() {
			var ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				return true;
			} else {
				return false;
			}
		}
	},
	created() {
		this.initCommonContent();
	},
	mounted() {
		this.addHistory();
	},
	methods: {
		setSourceUrl,
		removeSpace,
		formatImg,
		html2string,
		setMathBa,
		middleImg,
		addHistory() {
			this.$api
				.addLearnHistory({
					course_id: this.$route.query.courseId,
					coursechapters_id: this.$route.query.id,
					rateoflearning: '0',
					learning_info: this.$store.getters['common/getUnitNav'],
					active: this.$route.query.active,
					showToast: 1
				})
				.then();
		},
		getAudioSource(text) {
			if (this.audioSource[text]) {
				this.audioFileSrc = this.audioSource[text];
				this.$nextTick(() => {
					this.audioFile.play();
				});
			} else {
				this.$api
					.getCourseAudio({
						coursechaptersid: this.$route.query.id,
						tex: text,
						showToast: 1
					})
					.then((res) => {
						let resData = res.data?.realpath || '';
						if (resData) {
							this.audioFileSrc = this.setSourceUrl(resData);
							this.$set(this.audioSource, text, this.audioFileSrc);
							this.$nextTick(() => {
								this.audioFile.play();
							});
						}
					});
			}
		},

		setImgStyle() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},
		changeClass(n) {
			this.currentClass = n;
			this.$nextTick(() => {
				this.initEvent();
			});
		},
		previewImg(index) {
			ImagePreview({
				images: this.imageData,
				startPosition: index
			});
		},
		initVideo(url, img) {
			let that = this;
			let opt = {
				url: that.setSourceUrl(url)
			};
			if (img) {
				opt.pic = img;
			}
			let dp = new DPlayer({
				container: document.getElementById('dplayer'),
				video: opt
			});
			dp.on('canplay', () => {
				console.log(1);
				// that.$set(that.videosList[i], 'show', true);
			});
			dp.on('fullscreen_cancel', () => {
				console.log(2);
				// that.$set(that.videosList[i], 'showMask', true);
				dp.pause();
			});
			console.log(dp, 'rrrrrrrrrrrrrrrrrrr');
		},
		initCommonContent() {
			this.isLoading = true;
			this.$api
				.getCourseUnitContent({
					chapterid:
						this.$route.query.binddata && this.$route.query.binddata != 0
							? this.$route.query.binddata
							: null || this.$route.query.id,
					type: this.$route.query.type,
					iscourse: this.$route.query.iscourse || 1
				})
				.then((res) => {
					let resData = res.data || {};

					if (this.currentTitle == '原文解析') {
						resData.forEach((item) => {
							item.content = this.formatContent(item.content);
						});
						this.contentData = resData;
						this.$nextTick(() => {
							this.initEvent();
						});
					} else {
						if (resData.img) {
							this.imageData = [this.setSourceUrl(resData.img)];
						}

						resData.content = this.setMathBa(resData.content);
						resData.content = this.formatImg(resData.content);
						if (this.removeSpace(this.currentTitle) == '语法透析') {
							let content = resData.content;
							if (content) {
								let targetBsaeWords = content.match(/{(.+?)}/g) || '';
								for (let i = 0; i < targetBsaeWords.length; i++) {
									let reg = new RegExp(targetBsaeWords[i], 'g');
									let word = targetBsaeWords[i].replace(new RegExp(/({|})/, 'g'), '');
									content = content.replace(
										reg,
										`<span style="position: relative;
    display: inline-block;text-indent:0; border-bottom:1px #333 solid;margin-right:.05rem;"><span class="inputSpan" style="text-indent:0">${word}</span><input class="zihao inputDom" style="position: absolute;
    left: 0;
    right: 0; width:100%; border:0; line-height:1.6; display: inline-block;" data-val="${word}"></span>`
									);
								}
							}

							resData.content = content;
						}

						if (this.removeSpace(this.currentTitle) == '文章结构') {
							let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
							let srcReg = /src=['"]?([^'"]*)['"]?/i; // 匹配图片中的src
							let arr = resData.content.match(imgReg); //筛选出所有的img
							if (arr) {
								for (let i = 0; i < arr.length; i++) {
									let src = arr[i].match(srcReg)[1];
									this.$set(this.imageData, i, src);
								}
								resData.content = resData.content.replace(imgReg, '');
							}
						}
						resData.content = this.formatSymbol(resData.content);
						resData.content = this.middleImg(resData.content);

						this.contentData = resData;
					}

					this.isLoading = false;
					this.$nextTick(() => {
						this.initGrammarEvent();
						this.initSymbolEvent();
						this.audioFile = this.$refs['audioFile'];
						if (this.audioFileSrc) {
							this.audioFileSrc = this.setSourceUrl(resData.audiofiles);
							setTimeout(() => {
								this.audioFile.play();
							});
						}
					});
				});
		},
		formatContent(str) {
			// 格式化 “原文解析” 文章内容
			let content = str || '';
			let content1 = content.replace(
				/&lt;段析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle duanxi">段</span><span class="jiexi">'
			);
			let content2 = content1.replace(/--段析&gt;/g, '</span></span>');
			let content3 = content2.replace(
				/&lt;句析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle juxi">句</span><span class="jiexi">'
			);
			let content4 = content3.replace(/--句析&gt;/g, '</span></span>');
			let content5 = content4.replace(
				/&lt;词析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle cixi">词</span><span class="jiexi">'
			);
			let content6 = content5.replace(/--词析&gt;/g, '</span></span>');

			let content7 = content6.replace(
				/&lt;译文--/g,
				'<span class="jixiebtn"><span class="jiexi-handle yiwen">译</span><span class="jiexi">'
			);
			let content8 = content7.replace(/--译文&gt;/g, '</span></span>');
			content8 = content8.replace(new RegExp('&lt;', 'g'), '<');
			content8 = content8.replace(new RegExp('&gt;', 'g'), '>');
			return content8;
		},
		initEvent() {
			let that = this;
			let content = document.getElementById('classContent');
			let jiexibtn = content?.getElementsByClassName('jixiebtn');
			if (jiexibtn) {
				for (var i = 0; i < jiexibtn.length; i++) {
					(function () {
						let item = jiexibtn[i];
						item.onclick = function () {
							let jiexi = item.getElementsByClassName('jiexi')[0].innerHTML;
							console.log(jiexi);
							that.$eventBus.$emit('showAlert', jiexi);
						};
					})(i);
				}
			}
		},

		formatSymbol(str) {
			// 格式化 “动画语音” 内容
			let content = String(str || '');
			let content1 = content.replace(/&lt;--/g, '<div class="symbol">');
			let content2 = content1.replace(/--&gt;/g, '</div>');

			return content2;
		},
		initSymbolEvent() {
			//初始化动画音标发音事件
			let that = this;
			let content = document.getElementById('imgTextContent');
			let symbol = content?.getElementsByClassName('symbol');
			if (symbol) {
				for (var i = 0; i < symbol.length; i++) {
					(function () {
						let item = symbol[i];
						item.onclick = function () {
							for (let j = 0; j < symbol.length; j++) {
								symbol[j].classList.remove('active');
							}
							that.getAudioSource(that.html2string(this.innerHTML).replace(new RegExp("'", 'g'), ''));
							this.classList.add('active');
						};
					})(i);
				}
			}
		},

		initGrammarEvent() {
			let that = this;
			let content = document.getElementById('imgTextContent');
			let inputDom = content?.getElementsByClassName('inputDom');
			let inputSpan = content?.getElementsByClassName('inputSpan');
			if (inputDom) {
				for (var i = 0; i < inputDom.length; i++) {
					(function () {
						let item = inputDom[i];
						let spanDom = inputSpan[i];
						item.onfocus = function () {
							this.classList.remove('proper');
							this.classList.remove('error');
						};
						item.onblur = function () {
							let val = item.value;
							if (val) {
								let target = item.dataset.val;
								that.targetWords = target;
								if (val == target) {
									this.classList.add('proper');
									that.answerYuFaStatus = 1;
								} else {
									this.classList.add('error');
									that.answerYuFaStatus = 2;
								}
							}
						};
						item.oninput = function () {
							if (item.value) {
								spanDom.textContent = item.value;
							} else {
								spanDom.textContent = item.dataset.val;
							}
						};
					})(i);
				}
			}
		}
	}
};
</script>
<style lang="scss">
.symbol {
	display: inline-block;
	transform-origin: center;
	font-style: normal;
	transition: all 0.1s ease-in-out;

	margin-left: 0.1rem;
	span {
		font-size: 0.24rem !important;
		font-family: initial !important;
	}
}
.symbol.active {
	transform: scale(1.2);
}
.inputDom.proper {
	color: #1e9e85;
}
.inputDom.error {
	color: #ff3939;
}
</style>
<style lang="scss" scoped>
.status-vioce {
	position: fixed;
	left: 0;
	top: 0;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	width: 0;
	height: 0;
	overflow: hidden;
}
.flash-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	.notify {
		font-size: 0.24rem;
	}
}
.video-box {
	font-size: 12px;
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 3.9rem;
	::v-deep .dplayer-menu {
		display: none !important;
	}
	::v-deep .dplayer-video {
		background: #fff !important;
	}
	.dplayer {
		width: 100%;
	}
}
.class-list {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.28rem;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	background: #fff;
	font-size: 0.26rem;
	border-bottom: 0.02rem #dcdcdc solid;
	li {
		flex: 1;
		height: 0.72rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.active {
		color: #28b295;
		span {
			position: relative;
			height: 100%;
			line-height: 0.72rem;
			&::after {
				position: absolute;
				left: 0;
				right: 0;
				bottom: -0.01rem;
				content: '';
				height: 0.01rem;
				background: #28b295;
			}
		}
	}
}
.body-content {
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding-right: 0.3rem;
	.class-image {
		width: 100%;
	}
	.class-title {
		text-align: center;
		font-size: 0.3rem;
		font-weight: 500;
	}
	.class-auther {
		font-size: 0.26rem;
		text-align: center;
		margin-bottom: 0.2rem;
	}
	.class-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		font-size: 0.24rem;
		line-height: 2em;
		.con {
			width: 100%;
			word-break: break-all;
			// ::v-deep p{
			//   text-indent: 2em;
			// }
			::v-deep img {
				display: inline-flex;
				max-width: 100% !important;
			}
			// ::v-deep p>img{
			//   transform: translateX(-2em);
			// }
			::v-deep {
				&:img {
					display: inline-flex;
					max-width: 100% !important;
				}
			}
		}
		.center {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		::v-deep .jiexi-handle {
			display: inline-block;
			width: 0.4rem;
			height: 0.4rem;
			text-align: center;
			border-radius: 50%;
			color: #fff !important;
			font-size: 0.2rem !important;
			line-height: 0.4rem !important;
			text-indent: 0 !important;
		}
		::v-deep .duanxi {
			background: #ff3939;
		}
		::v-deep .juxi {
			background: #28b295;
		}
		::v-deep .cixi {
			background: #ff9600;
		}
		::v-deep .yiwen {
			background: #007eff;
		}
		::v-deep .jiexi {
			display: none !important;
		}
	}
}
.hasMore {
	padding-top: 0.72rem;
}
.text-content {
	width: 100vw;
	height: 100vh;
	background: #fafafa;
	overflow: hidden;
}
.content-body,
.empty-box {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.28rem;
	bottom: 0;
	padding: 0.3rem 0 0.3rem 0.3rem;
	background: #fff;
}
.empty-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.3rem;
}
</style>
