/*
<customTabNav :tabList="tabList" :selectTab="currentTab" @change="tabChange"></customTabNav>
*/

<template>
  <div class="custom-tab-nav">
  
    <van-tabs v-model="innerSelect" @change="change"
        :swipe-threshold="scrollNum">
      <van-tab
        :style="`${itemStyle}`"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :title="item.text"
        :name="item.name"
      ></van-tab>
    </van-tabs>
  </div>
  
</template>

<script>
export default {
  name: "customTabNav",
  props: {
    tabList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectTab: {
      type: [String, Number],
      default: "",
    },
    scrollNum: {
      type: [String, Number],
      default: 3,
    },
    itemStyle:{
      type:String,
      default:''
    }
    
  },
  watch:{
    selectTab:function(val){
      this.innerSelect = val
    }
  },
  data() {
    return {
      innerSelect: "",
    };
  },
  created() {
    this.innerSelect = this.selectTab;
  },
  methods:{
    change(val){
      this.$emit('change',val)
    }
  }
};
</script>

<style lang="scss">
.custom-tab-nav {
  width: 100%;
  .van-tabs {
    width: 100%;
    .van-tabs__line {
      display: none;
    }
    .van-tab {
      margin-right: .2rem;
      height: 0.64rem;
      min-width: 2.1rem;
      background: #e2f5f1;
      border-radius: 0.32rem;
      .van-tab__text {
        font-size: 0.26rem;
        color: #333;
        font-weight: 400;
      }
    }
    .van-tab--active {
      position: relative;
      background: #28b295;
      &::after {
        position: absolute;
        left: 50%;
        top: 0.4rem;
        transform: translateX(-50%);
        font-family: "iconfont" !important;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e65f";
        font-size: 0.8rem;
        color: #28b295;
      }
      .van-tab__text {
        color: #fff;
      }
    }
  }
}
</style>

