const state = {
  courseOutline: [],
  classData: [],
  classNav:[],
  courseId:''
}
const getters = {
  getCourseOutline(state) {
    return state.courseOutline
  },
  getClassNav(state){
    return state.classNav
  },
  getClassData(state){
    return state.classData
  },
  getCourseId(state){
    return state.courseId
  }
}
const actions = {

}
const mutations = {
  setCourseOutline(state, data) {
    state.courseOutline = data
  },
  setClassNav(state,data){
    state.classNav = data
  },
  setClassData(state,data){
    state.classData = data
  },
  setCourseId(state,data){
    state.courseId = data
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}