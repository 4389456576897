<template>
  <div class="change-phone-page">
    <navBar>
      <span class="title">修改手机号</span>
    </navBar>
    <div class="page-content">
      <van-field
        v-model="phone"
        type="tel"
        label="手机号码"
        placeholder="请填写手机号码"
        :clearable="true"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            :disabled="sending"
            @click="sendSms"
            >{{btnText}}</van-button
          >
        </template>
      </van-field>
      <div class="submit-btn" @click="onSubmit">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "changePhonePage",
  data() {
    return {
      btnText: "发送验证码",
      sending: false,
      sms: "",
      phone: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {},
    sendSms() {
      if (this.sending) {
        return;
      }
      if (!this.phone) {
        this.$toast({
          message: "请输入手机号码！",
        });
        return;
      }
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.phone)) {
        this.$toast({
          message: "请输入正确的手机号码！",
        });
        return;
      }
      this.accountTIme()
    },
    accountTIme() {
      let that=this;
      that.sending = true;
      let num = 60;
      let _timer = setInterval(() => {
        if (0<num ) {
          that.btnText = `(${num}s)后重新发送`;
          num--;
        }else{
          clearInterval(_timer);
          that.btnText="发送验证码";
          that.sending = false;
        }
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  margin: 2rem 0.28rem 0 0.28rem;
  text-align: center;
  line-height: 0.88rem;
  height: 0.88rem;
  background: #2db79a;
  box-shadow: 0px 3px 7px 0px rgba(45, 183, 154, 0.35);
  border-radius: 44px;
  font-size: 0.3rem;
  font-weight: 500;
  color: #fff;
  &:active {
    background: #90d9ca;
  }
}
.notic {
  padding-top: 0.4rem;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #909090;
}
</style>