const state = {
  famousReadData: {},
  currentFamousRead:{}
}
const getters = {
  getFamousReadData(state) {
      return state.famousReadData
  },
  getCurrentFamousRead(state) {
    return state.currentFamousRead
},

}
const actions = {

}
const mutations = {
  setFamousReadData(state,data) {
    state.famousReadData=data
  },
  setCurrentFamousRead(state,data) {
    state.currentFamousRead=data
},
  
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}