import { render, staticRenderFns } from "./type8.vue?vue&type=template&id=0aee4efd&scoped=true&"
import script from "./type8.vue?vue&type=script&lang=js&"
export * from "./type8.vue?vue&type=script&lang=js&"
import style0 from "./type8.vue?vue&type=style&index=0&id=0aee4efd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aee4efd",
  null
  
)

export default component.exports