<template>
  <div class="picker-box" :class="{ show: innerShow }" @click="closePicker(1)">
    <div class="picker-main" @click.stop="closePicker(2)">
      <div class="picker-header">
        <span class="title">
          <slot name="title"></slot>
        </span>
        <span class="iconfont icon-cuowu" @click="closePicker(1)"></span>
      </div>
      <div class="picker-body">
        <ul class="picker-list">
          <li
            v-for="(item, index) in pickerData"
            :class="{ active: innerId == item[id] }"
            :key="index"
            @click="selected(item[id])"
          >
            {{ item[name] }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
let pickerTimer = null;
export default {
  name: "customPicker",
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    pickerData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    name: {
      type: String,
      default: "name",
    },
    id: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      innerShow: false,
      innerId: "",
    };
  },
  created() {
    this.innerId = this.value;
  },
  mounted() {
    pickerTimer = setTimeout(() => {
      this.innerShow = true;
    }, 0);
  },
  destroyed(){
      clearTimeout(pickerTimer)
  },
  methods: {
    selected(id) {
      this.innerId = id;
      this.$emit("selected", id);
      let timmer = setTimeout(() => {
        this.innerShow = false;
        this.$emit("close");
        clearTimeout(timmer);
      }, 500);
    },
    closePicker(n) {
      if (n == 1) {
        this.innerShow = false;
        this.$emit("close");
      } else {
        this.innerShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.5);
  .picker-main {
    width: 100%;
    background: #fff;
    border-radius: 0.1rem 0.1rem 0 0;
    transition: all 0.2s ease-out;
    transform: translateY(100%);
    opacity: 0.8;
    .picker-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.8rem;
      padding: 0.2rem;
      border-bottom: 0.01rem #f5f5f5 solid;
      .iconfont {
        padding: 0.2rem 0 0.2rem 0.2rem;
        font-size: 0.5rem;
      }
    }
    .picker-body {
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
scroll-behavior:smooth;
      .picker-list {
        width: 100%;
        li {
          line-height: 0.92rem;
          text-align: center;
          font-size: 0.26rem;
          font-weight: 400;
          color: #333;
          &.active {
            color: #fff;
            background: #2db79a;
          }
        }
      }
    }
  }
}
.show {
  .picker-main {
    transform: translate(0);
    opacity: 1;
  }
}
</style>