<template>
	<div class="word-shorthand" @click="showKeyboard = false">
		<navBar>
			<span>{{ currentTitle }}</span>
			<template v-slot:right v-if="dataList.length">
				<div class="common-step">
					<span class="current">{{ currentTurn + 1 }}</span>
					<span class="total">/{{ dataList.length }}</span>
				</div>
			</template>
		</navBar>
		<div class="common-progress" v-if="dataList.length">
			<div class="con" :style="`width:${((currentTurn + 1) / dataList.length) * 100}%`"></div>
		</div>
		<div class="page-content">
			<div class="scroll-box">
				<div class="short-hand" v-show="currentType == 4">
					<div class="area-box">
						<div class="area-box-main">
							<div class="word">
								<span class="word-box">
									<span class="word-txt">{{ dataList[currentTurn].word }}</span>
								</span>
							</div>
							<div class="voice">
								<div
									class="voice-item"
									v-if="dataList[currentTurn].yinbiao"
									@click="playVoice(dataList[currentTurn].fayinfile, currentTurn)"
								>
									<span>{{ dataList[currentTurn].yinbiao }}</span>
									<svg-icon
										icon-class="voice"
										class="icon"
										:class="{ 'icon-active': dataList[currentTurn].play }"
										v-if="dataList[currentTurn].fayinfile"
									></svg-icon>
								</div>
							</div>
							<div class="des">
								<template v-if="dataList[currentTurn].showResult">
									<p>adj. 先进的；高级的，高等的。</p>
									<p>v. 行进；发展，进步。</p>
								</template>

								<img :src="chatu" />
								<div class="example">
									<span class="tit">eg.例子</span>
								</div>
								<div class="prase">
									<p>
										Oil shares
										<span style="color: #ff0000">advanced</span>
										amid economic recovery hopes.
									</p>
									<p v-if="dataList[currentTurn].showResult">
										在一片经济复苏的希望中石油股票价格上涨。
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="btns">
						<div
							class="btn btn-right"
							@click="changeQuestion(1)"
							v-if="nextBtn || acountQuestion == dataList.length"
						>
							下一题
						</div>
						<div class="btn btn-right" @click="showResult" v-else>我认识</div>
						<div class="btn btn-error" @click="changeQuestion(2)" v-if="acountQuestion < dataList.length">
							不认识
						</div>
						<div class="btn btn-right" @click="changeQuestion(3)" v-else>上一题</div>
					</div>
				</div>
				<div class="short-hand" v-show="currentType == 5">
					<div class="area-box" :class="`area-box-${currentType}`">
						<div class="area-box-main">
							<div class="word">
								<div class="input-word" @click.stop="showKeyboardFn">
								<!-- <div class="input-word" @click.stop="onFoustu"> -->
									<span
										class="letter-input"
										:class="{
											'letter-input-right': letter == inputWord[index],
											'letter-input-error':
												letter != inputWord[index] && inputWord[index]
										}"
										v-for="(letter, index) in dataList[currentTurn].word.toLowerCase()"
										:key="`letter${index}`"
									>
										{{ inputWord[index] }}
									</span>
								</div>
							</div>
							<div class="voice">
								<div
									class="voice-item"
									v-if="dataList[currentTurn].yinbiao"
									@click="playVoice(dataList[currentTurn].fayinfile, currentTurn)"
								>
									<span>{{ dataList[currentTurn].yinbiao }}</span>
									<svg-icon
										icon-class="voice"
										class="icon"
										:class="{ 'icon-active': dataList[currentTurn].play }"
										v-if="dataList[currentTurn].fayinfile"
									></svg-icon>
								</div>
							</div>
							<div class="des">
								<p>adj. 先进的；高级的，高等的。</p>
								<p>v. 行进；发展，进步。</p>
								<img :src="chatu" />
								<div class="example">
									<span class="tit">eg.例子</span>
								</div>
								<div class="prase">
									<p>
										Oil shares
										<span style="color: #ff0000">advanced</span>
										amid economic recovery hopes.
									</p>
									<p>在一片经济复苏的希望中石油股票价格上涨。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="audio-box">
			<audio id="audio" ref="audio" v-if="audioPlay" :src="setSourceUrl(audioSrc)" @ended="played">
				您的浏览器不支持 audio 标签。
			</audio>
		</div>

		<!-- <div class="keyboard" v-show="showKeyboard" @click.stop="showKeyboard = true">
			<ul class="letter">
				<li v-for="(w, index) in keyWords" :key="`w${index}`" @click.stop="changeWrod(w)">
					{{ w }}
				</li>
			</ul>
		</div> -->
		<keyBoard v-model="keyBoardData" :showKeyboard="showKeyboard" :keyMax="keyMax"></keyBoard>
		<input
			style="width: 0px; height: 0px; position: absolute; z-index: -1; font-size: 14px"
			@keydown="inpevent"
			@input="inpevent"
			type="text"
			ref="inp"
			v-model="inpData"
		/>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
export default {
	name: 'wordShorthand',
	watch: {
		keyBoardData: {
			handler: function (val) {
				let _this = this;
				_this.inputWord = val;
				// let targetWord = this.dataObj.testpapecontent.toLowerCase();
				// let _val = val.join('');
				// if (_val == targetWord) {
				// 	setTimeout(() => {
				// 		this.showKeyboard = false;
				// 		_this.changeQuestion();
				// 	}, 1000);
				// }
			},
			immediate: true
		},
		inputWord: function (val) {
			let targetWord = this.dataList[this.currentTurn].word.toLowerCase();
			let _val = val.join('');
			if (_val == targetWord) {
				this.showKeyboard = false;
				if (this.currentTurn < this.dataList.length - 1) {
					this.currentTurn = ++this.currentTurn;
					this.inputWord = [];
					this.keyBoardData = [];
					this.inpData = '';
					this.inputIndex = 0;
					// this.$refs.inp.blur();
				}
			}
		}
	},
	data() {
		return {
			keyBoardData: [],
			keyMax: 1,
			chatu: require('@/assets/img/chatu.png'),
			currentType: 0,
			currentTurn: 0,
			showKeyboard: false,
			inputIndex: 0,
			audioPlay: false,
			audioSrc: '',
			nextBtn: false,
			acountQuestion: 0,
			inpData: '',
			keyWords: [
				'q',
				'w',
				'e',
				'r',
				't',
				'y',
				'u',
				'i',
				'o',
				'p',
				'a',
				's',
				'd',
				'f',
				'g',
				'h',
				'j',
				'k',
				'l',
				'z',
				'x',
				'c',
				'v',
				'b',
				'n',
				'm'
			],
			dataList: [
				{
					word: 'Advanced',
					yinbiao: '/ ədˈvɑːnst /',
					fayinfile: '/uploads/20220509/73714bd2a86a141a7695931dbf3f702a.mp3',
					play: false,
					content: '',
					des: '<p>adj.   先进的；高级的，高等的。</p><p>v.    行进；发展，进步。</p>',
					showResult: false
				},
				{
					word: 'Hello',
					yinbiao: 'həˈlō',
					fayinfile: '/uploads/20220509/73714bd2a86a141a7695931dbf3f702a.mp3',
					play: false,
					content: '',
					des: '<p>adj.   先进的；高级的，高等的。</p><p>v.    行进；发展，进步。</p>',
					showResult: false
				}
			],
			shortHandData: [],
			wordWriteData: [],
			practiseData: [],
			inputWord: []
		};
	},
	created() {
		this.currentType = this.$route.query.type;
		this.initData();
	},
	methods: {
		setSourceUrl,
		onFoustu() {
			let that = this;
			let device = window.localStorage.getItem('device');
			if (device == 'android') {
				this.$toast('请切换至26键输入');
				setTimeout(() => {
					that.$refs.inp.focus();
				}, 2000);
			} else {
				that.$refs.inp.focus();
			}
		},
		inpevent(event) {
			let dnumber1 = event.target.value;
			dnumber1 = dnumber1.toLowerCase();
			if (dnumber1.length > this.dataList[this.currentTurn].word.toLowerCase().length) {
				this.inpData = dnumber1.slice(0, this.dataList[this.currentTurn].word.toLowerCase().length);
				dnumber1 = dnumber1.slice(0, this.dataList[this.currentTurn].word.toLowerCase().length);
			}
			let wordData = [];
			for (let i = 0; i < dnumber1.length; i++) {
				wordData.push(dnumber1[i]);
			}
			this.inputWord = wordData;
		},
		showResult() {
			this.$set(this.dataList[this.currentTurn], 'showResult', true);
			this.nextBtn = true;
			this.acountQuestion = ++this.acountQuestion;
			this.playVoice(this.dataList[this.currentTurn].fayinfile, this.currentTurn);
		},
		changeQuestion(n) {
			if (n == 1) {
				if (this.currentTurn < this.dataList.length - 1) {
					this.currentTurn = ++this.currentTurn;
					this.nextBtn = false;
					this.formatData();
					this.$refs.audio.pause();
				}
			}
			if (n == 2) {
				let tdata = this.testData;
				let cw = tdata[this.testCurrent];
				cw.num++;
				tdata.splice(this.testCurrent, 1);
				tdata.splice(this.testCurrent + cw.num, 0, cw);
				console.log(this.testCurrent);
				console.log(cw);
				console.log(tdata);
			}
			if (n == 3) {
				if (0 < this.currentTurn) {
					this.currentTurn = --this.currentTurn;
					this.nextBtn = false;
					this.formatData();
					this.$refs.audio.pause();
				}
			}
		},
		changeWrod(val) {
			let targetWord = this.dataList[this.currentTurn].word.toLowerCase();
			if (val == targetWord[this.inputIndex]) {
				this.$set(this.inputWord, this.inputIndex, val);
				this.inputIndex = ++this.inputIndex;
			} else {
				this.$set(this.inputWord, this.inputIndex, val);
			}
		},
		showKeyboardFn() {
			this.showKeyboard = true;
			this.keyMax = this.dataList[this.currentTurn].word.toLowerCase().length;
		},
		initData() {},
		playVoice(src, index) {
			if (src) {
				this.formatData();
				this.audioPlay = true;
				this.audioSrc = src;
				this.$set(this.dataList[index], 'play', true);
				setTimeout(() => {
					this.$refs.audio.play();
				}, 10);
			}
		},
		played() {
			this.audioPlay = false;
			this.formatData();
		},
		formatData() {
			this.dataList.forEach((item) => {
				item.play = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.audio-box {
	width: 0;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}
.keyboard {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
	box-shadow: 0 0 0.1rem #ccc;
	.letter {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		background: #dcdcdc;
		padding: 0.15rem 0;
		li {
			text-align: center;
			line-height: 0.8rem;
			font-size: 0.54rem;
			width: 0.6rem;
			background: #fff;
			margin: 0.15rem 0.2rem;
			border-radius: 0.08rem;
			color: #323233;
			&:active {
				box-shadow: 0 0 0.1rem #ccc;
				background: #27b295;
				color: #fff;
			}
		}
	}
}
.input-word {
	position: relative;
	display: flex;
	justify-content: center;
	overflow: hidden;
	.words-input {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
	}
}
.letter-input {
	width: 0.54rem;
	height: 0.66rem;
	font-size: 0.6rem;
	line-height: 1;
	text-align: center;
	background: #e4e4e4;
	margin-left: 0.06rem;
	&-right {
		background: #27b295;
		color: #fff;
	}
	&-error {
		background: #ff545c;
		color: #fff;
	}
}
.common-progress {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.08rem;
	height: 0.18rem;
	z-index: 100;
	background: #fafafa;
	.con {
		height: 0.18rem;
		background: #36ba9e;
	}
}
.common-step {
	display: flex;
	align-items: flex-end;
	background: #fff;
	line-height: 1;
	.current {
		font-size: 0.52rem;
		color: #36ba9e;
		transform: translateY(0.03rem);
	}
	.total {
		font-size: 0.3rem;
		color: #969799;
	}
}
.page-content {
	padding-top: 1.23rem;
	.scroll-box {
		padding: 0.28rem;
		height: calc(100vh - 1.23rem);
		background: #fff;
	}

	.area-box {
		display: flex;
		align-items: center;

		padding: 0.3rem 0;
		height: calc(100vh - 2.9rem);
		border: 0.01rem #dcdcdc solid;
		border-radius: 0.08rem;

		&-main {
			width: 100%;

			padding: 0 0.3rem;
			max-height: 100%;
			overflow-y: auto;
			.example {
				display: block;
				padding: 0.32rem 0;
				.tit {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 1.36rem;
					height: 0.44rem;
					background: #2db79a;
					border-radius: 0.22rem;
					font-size: 0.24rem;
					color: #fff;
				}
			}
			.des {
				font-size: 0.28rem;
				p {
					margin-bottom: 0.16rem;
				}
			}
			.voice {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 0.3rem;
				.voice-item {
					display: flex;
					align-items: center;
					height: 0.64rem;
					margin: 0 0.15rem;
					border-radius: 0.32rem;
					padding: 0.15rem 0.2rem;
					font-size: 0.28rem;
					color: #323233;
					background: #f4f5f7;
					span {
						color: #909090;
					}
					.icon {
						margin-left: 0.1rem;
						&-active {
							color: #27b295;
						}
					}
				}
			}
			.word {
				display: flex;
				justify-content: center;
				margin-bottom: 0.2rem;
				.word-box {
					position: relative;
					font-size: 0.6rem;
					color: #323233;
					&::before {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0.16rem;
						content: '';
						height: 0.16rem;
						background: #e1f5f1;
					}
					.word-txt {
						position: relative;
						z-index: 2;
					}
				}
			}
			.des {
				img {
					width: 100%;
				}
			}
		}
	}
	.area-box-5 {
		height: calc(100vh - 1.8rem);
	}
	.btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3.24rem;
			height: 0.8rem;
			border-radius: 0.4rem;
			font-size: 0.3rem;
			color: #fff;
			margin-top: 0.3rem;
			&-right {
				background: #27b295;
			}
			&-error {
				background: #ff545c;
			}
			&:active {
				opacity: 0.8;
			}
		}
	}
}
</style>
