<template>
  <div class="answer-parse">
    <navBar
      ><span>查看解析</span>
      <template v-slot:right v-if="answerStatus.length"
        ><div class="common-step">
          <span class="current">{{
            questionObject[activeQuestion].parentNum + 1
          }}</span>
          <span class="total"
            >/{{
              questionObject[questionObject.length - 1].parentNum + 1
            }}</span
          >
        </div></template
      >
    </navBar>
    <div class="proress">
      <div
        class="con"
        :style="`width:${((activeQuestion + 1) / answerStatus.length) * 100}%`"
      ></div>
    </div>
    <van-tabs
      v-model="activeQuestion"
      :swipeable="false"
      v-if="answerStatus.length"
    >
      <van-tab
        v-for="(item, index) in answerStatus"
        :key="index"
        :name="item.num - 1"
        :title-class="{
          empty: item.status == 0,
          success: item.status == 1,
          error: item.status == 2,
          subject: item.status == 3,
        }"
      >
        <!-- <div class="item-status">
          <div class="status-icon">
            <van-image
              fit="cover"
              :src="weida"
              v-if="item.status == 0"
            ></van-image>
            <van-image
              fit="cover"
              :src="zhengque"
              v-if="item.status == 1"
            ></van-image>
            <van-image
              fit="cover"
              :src="cuowu"
              v-if="item.status == 2"
            ></van-image>
            <van-image
              fit="cover"
              :src="zhuguan"
              v-if="item.status == 3"
            ></van-image>
          </div>
          <div class="status-txt">
            <span class="num">第{{ activeQuestion + 1 }}题</span>
            <span
              class="txt"
              :class="{
                empty: item.status == 0,
                success: item.status == 1,
                error: item.status == 2,
                subject: item.status == 3,
              }"
              >{{ computeStatus(item.status) }}</span
            >
          </div>
        </div> -->

        <div class="item-question">
          <div class="item-content">
            <p v-html="questionBody.content"></p>
          </div>
          <div class="item-qa">
            <div class="item-q" v-html="testpapeContent"></div>
            <div class="item-a">
              <ul class="options" v-if="questionType < 3">
                <li
                  v-for="(opt, index) in qusetionOptionjson"
                  :key="`a${index}`"
                  :class="{
                    success:
                      String(questionBody.answerIdx).indexOf(index) != -1 &&
                      answerStatus[activeQuestion].status == 1,
                    error:
                      String(questionBody.answerIdx).indexOf(index) != -1 &&
                      answerStatus[activeQuestion].status == 2,
                  }"
                >
                  <span class="tag">{{ opt.value }}</span>
                  <span class="txt" v-html="opt.text"></span>
                </li>
              </ul>
              <div class="a-box" v-else>
                <van-field
                  type="textarea"
                  v-model="qusetionAnswer"
                  :disabled="true"
                ></van-field>
              </div>
            </div>
          </div>
          <div class="item-p">
            <!-- <div class="tit">
              <svg-icon icon-class="jiexi" class="icon"></svg-icon
              ><span>题目解析</span>
            </div> -->
            <div class="answer">
              <span class="txt">【您的答案】</span>
              <span
                class="con"
                v-html="questionBody.answerVal"
                v-if="questionBody.answerVal"
              ></span>
              <span v-else>未作答</span>
            </div>
            <div class="answer">
              <span class="txt">【正确答案】</span>
              <span class="con" v-html="questionBody.rightkey"></span>
            </div>
            <div class="answer">
              <span class="txt">【答案解析】</span>
              <span
                class="con"
                v-html="questionBody.analysiscontent"
                v-if="questionBody.analysiscontent"
              ></span>
              <span v-else>略</span>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="question-handle">
      <div class="btn" @click="changeQuestion(1)">上一题</div>
      <div class="btn" @click="changeQuestion(0)">下一题</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "answerParse",
  computed: {
    questionBody: function () {
      let questionBody = this.questionObject[this.activeQuestion] || "";
      return questionBody;
    },
    testpapeContent: function () {
      let testpapecontent =
        this.questionObject[this.activeQuestion].testpapecontent;
      return testpapecontent;
    },
    questionType: function () {
      let type = this.questionObject[this.activeQuestion].type;
      return type;
    },
    qusetionOptionjson: function () {
      let optionjson = this.questionObject[this.activeQuestion].optionjson;
      return optionjson;
    },
    qusetionAnswer: function () {
      let answerVal = this.questionObject[this.activeQuestion].answerVal;
      return answerVal;
    },
  },
  watch: {
    activeQuestion: {
      handler: function () {
        this.$nextTick(() => {
          this.setImgStyle();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      zhengque: require("@/assets/img/zhengque.png"),
      cuowu: require("@/assets/img/cuowu.png"),
      weida: require("@/assets/img/weida.png"),
      zhuguan: require("@/assets/img/zhuguan.png"),
      activeQuestion: 0,
      answerStatus: [],
      questionObject: [],
      pushErrorStatus: [],
    };
  },
  created() {
    this.answerStatus = this.$store.getters["common/getAnswerStatus"];
    this.questionObject = this.$store.getters["common/getQuestionObject"];
    for (let i = 0; i < this.questionObject.length; i++) {
      if (this.questionObject[i].type < 3) {
        this.$set(this.pushErrorStatus, i, false);
      } else {
        this.$set(this.pushErrorStatus, i, true);
      }
    }
    this.checkAndPushError();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.setImgStyle();
    // });
  },
  methods: {
    changeQuestion(n) {
      // this.$refs["testBox"].scrollTop = 0;
      if (!this.pushErrorStatus[this.activeQuestion]) {
        this.checkAndPushError();
      }
      switch (n) {
        case 0: {
          if (this.activeQuestion < this.answerStatus.length - 1) {
            this.activeQuestion = ++this.activeQuestion;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是最后一题了！",
            });
          }
          break;
        }
        case 1: {
          if (this.activeQuestion) {
            this.activeQuestion = --this.activeQuestion;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是第一题了！",
            });
          }
          break;
        }
        default: {
          break;
        }
      }
      this.$nextTick(() => {
        this.setImgStyle();
      });
    },
    setImgStyle() {
      this.MathJax.MathQueue("math-formula"); //传入组件id，让组件被MathJax渲染
    },
    computeStatus(n) {
      let txt = "";
      switch (n) {
        case 0: {
          txt = "未做";
          break;
        }
        case 1: {
          txt = "正确";
          break;
        }
        case 2: {
          txt = "错误";
          break;
        }
        case 3: {
          txt = "主观题";
          break;
        }
        default: {
          txt = "未做";
        }
      }
      return txt;
    },
    checkAndPushError() {
      let currentQuestion = this.questionObject[this.activeQuestion];
      let status = currentQuestion.status;
      if (status != 2) {
        let questionId = currentQuestion.id;
        this.$api.addErrorQuestion({ testpapeid: questionId }).then(() => {
          this.$set(this.pushErrorStatus, this.currentQuestion, true);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.answer-parse {
  ::v-deep {
    .mubiaozi {
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: -0.07rem;
        transform: translateX(-50%);
        content: "";
        width: 0.07rem;
        height: 0.07rem;
        border-radius: 50%;
        background: #333;
      }
    }
  }
}

.common-step {
  display: flex;
  align-items: flex-end;
  background: #fff;
  line-height: 1;
  .current {
    font-size: 0.52rem;
    color: #36ba9e;
    transform: translateY(0.03rem);
  }
  .total {
    font-size: 0.3rem;
    color: #969799;
  }
}
.question-handle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.28rem;
  background: #fff;
  border-top: 1px #fafafa solid;
  .btn {
    width: 2.1rem;
    height: 0.68rem;
    border: 0.02rem solid #28b295;
    border-radius: 0.34rem;
    text-align: center;
    line-height: 0.68rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333;
    background: #fff;
    &:active {
      background: rgba(40, 178, 149, 0.1);
    }
    &.disable {
      border-color: #e1e1e1;
      background: #f1f1f1;
    }
  }
  .start-btn {
    width: 100%;
    background: #28b295;
    color: #fff;
  }
}
.proress {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.08rem;
  z-index: 3;
  height: 0.15rem;
  background: #f7f8fa;
  .con {
    height: 100%;
    background: #28b295;
    width: 50%;
  }
}
.answer-parse {
  padding-top: 1.08rem;
  background: #fafafa;
  ::v-deep img{
    height: auto !important;
  }
  .item-question {
    width: 100%;
    padding: 0.28rem;
    height: calc(100vh - 3rem);
    overflow-x: hidden;
    overflow-y: auto;
    .item-content {
      margin-bottom: 0.3rem;
      p {
        font-size: 0.3rem;
        line-height: 1.6;
      }
    }
    .item-p {
      font-size: 0.3rem;
      .answer {
        display: flex;
        .txt {
          min-width: 1.8rem;
          margin-bottom: 0.1rem;
        }
        .con {
          flex: 1;
          word-break: break-all;
          overflow: auto;
          ::v-deep {
            * {
              img {
                max-width: 100% !important;
                height: auto !important;
              }
            }
          }
        }
      }
      * {
        font-weight: normal;
      }
      .tit {
        display: flex;
        align-items: center;
        margin-bottom: 0.15rem;
        font-size: 0.3rem;
        color: #909090;
      }
      .icon {
        font-size: 0.4rem;
        margin-right: 0.15rem;
      }
    }
    .item-a {
      padding-top: 0.48rem;
    }
    .item-a {
      padding-bottom: 0.4rem;
    }
    .options {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.32rem;
        margin-bottom: 0.32rem;
        background: #f8f8f8;
        border-radius: 0.08rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: #3a3a3a;
        line-height: 1.6;
        &.success {
          background: #28b396;
          color: #fff;
        }
        &.error {
          background: #ff1e1e;
          color: #fff;
        }
        .tag {
          position: relative;
          margin-right: 0.26rem;
          padding-right: 0.26rem;
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 0.02rem;
            height: 0.24rem;
            background: #d8d8d8;
          }
        }
        ::v-deep .mubiaozi {
          position: relative;
          &::after {
            position: absolute;
            left: 50%;
            bottom: -0.07rem;
            transform: translateX(-50%);
            content: "";
            width: 0.07rem;
            height: 0.07rem;
            border-radius: 50%;
            background: #333;
          }
        }
      }
    }
    .a-box {
      border: 0.01rem #28b396 solid;
      border-radius: 0.2rem;
      margin-bottom: 0.32rem;
      overflow: hidden;
    }
  }
  .item-status {
    margin: 0 0.28rem;
    padding-bottom: 0.35rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.01rem #b8b8b8 dashed;
    .status-icon {
      display: flex;
      width: 0.72rem;
      height: 0.72rem;
      margin-right: 0.2rem;
      .van-image {
        width: 100%;
        height: 100%;
      }
    }
    .status-txt {
      padding: 0.03rem 0;
      height: 0.72rem;
      display: flex;
      align-items: center;
      line-height: 1;
      .num {
        font-size: 0.24rem;
        color: #333;
      }
      .txt {
        font-size: 0.24rem;
        &.success {
          color: #28b396;
        }
        &.error {
          color: #ff1e1e;
        }
        &.empty {
          color: #ff9700;
        }
        &.subject {
          color: #1180db;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.answer-parse {
  .item-p {
    * {
      font-weight: normal;
      line-height: 1.6;
    }
  }
  .item-q {
    font-size: .3rem;
    line-height: 1.6;
    &::after {
      display: block;
      visibility: hidden;
      content: "";
      clear: both;
    }
    * {
      font-size: 0.3rem;
      line-height: 1.6;
      font-weight: normal;
    }
  }
  .van-tabs {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .van-tabs__wrap {
    height: 0.2rem !important;
    background: #fafafa;
    .van-tabs__line {
      width: 0;
    }
  }
  .van-tabs__nav {
    height: 0;
  }
  .van-tabs__nav--line {
    padding: 0;
  }
  .van-tab {
    height: 0;
  }
  .van-tabs__content {
    background: #fff;
  }
}
</style>
