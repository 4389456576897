<template>
  <div class="component nav-bar" :class="{ whiteBg: whiteBg }">
    <div class="left">
      <slot name="left">
        <i v-if="showBack" class="iconfont icon-fanhui" @click="goBack"></i>
      </slot>
    </div>
    <div class="center">
      <slot></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
    whiteBg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$eventBus.$emit("goBack");
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.08rem;
  padding: 0 0.28rem;
  background: #2db79a;
  overflow: hidden;
  .left {
    min-width: 0.6rem;
    transform: translateX(-0.12rem);
    i {
      font-weight: 500;
      font-size: 0.48rem;
      color: #fff;
    }
  }
  .left,
  .center,
  .right {
    font-size: 0.34rem;
    font-weight: 500;
    color: #333;
  }
  .center .title {
    font-size: 0.34rem;
    color: #333;
  }
  .center {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .menu-icon {
    color: #fff;
    font-size: 0.42rem;
    &.active {
      color: #fff;
    }
  }
  .center {
    position: absolute;
    left: 1rem;
    right: 1rem;
    text-align: center;
  }
}
.whiteBg {
  background: #fff;
  .left {
    i {
      color: #333;
    }
  }
}
</style>