import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import { getToken } from '@/utils/token'
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
function getComplatedStatus(){
  return window.localStorage.getItem('complatedStatus')
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children:[
      {
        path:'/',
        name:'mainIndex',
        component:()=>import('@/views/index/index.vue')
      },
      {
        path:'library',
        name:'library',
        component:()=>import('@/views/library/index.vue')
      },
      {
        path:'user',
        name:'user',
        component:()=>import('@/views/user/index.vue')
      }
    ]
  },


  {
    path: '/moreHistory',
    name: 'moreHistory',
    component: () => import('@/views/index/moreHistory.vue')
  },
  {
    path: '/notifyDetail',
    name: 'notifyDetail',
    component: () => import('@/views/index/notifyDetail.vue')
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('@/views/class/index.vue')
  },

  {
    path: '/zhishiquanjie',
    name: 'zhishiquanjie',
    component: () => import('@/views/class/zhishiquanjie.vue')
  },

  {
    path: '/unitVideo',
    name: 'unitVideo',
    component: () => import('@/views/class/unitVideo.vue')
  },


  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/class/detail.vue')
  },
  {
    path: '/bookContent',
    name: 'bookContent',
    component: () => import('@/views/class/bookContent.vue')
  },

  {
    path: '/author',
    name: 'author',
    component: () => import('@/views/class/author.vue')
  },
  {
    path: '/famousRead',
    name: 'famousRead',
    component: () => import('@/views/class/famousRead.vue'),
    children:[
      {
        path:'/',
        name:'readByTurn',
        component:()=>import('@/views/class/readByTurn.vue')
      },
      {
        path:'/readBySigin',
        name:'readBySigin',
        component:()=>import('@/views/class/readBySigin.vue')
      },
      {
        path:'/reciteAll',
        name:'reciteAll',
        component:()=>import('@/views/class/reciteAll.vue')
      },
    ]
  },

  {
    path: '/zhenTi',
    name: 'zhenTi',
    component: () => import('@/views/class/zhenTi.vue')
  },

  {
    path: '/wordsBreakThrough',
    name: 'wordsBreakThrough',
    component: () => import('@/views/commonContent/wordsBreakThrough.vue')
  },
  {
    path: '/wordsBreakThroughUnit',
    name: 'wordsBreakThroughUnit',
    component: () => import('@/views/commonContent/wordsBreakThroughUnit.vue')
  },

 
  




  {
    path: '/userTest',
    name: 'userTest',
    component: () => import('@/views/userTest/index.vue')
  },
  {
    path: '/answerParse',
    name: 'answerParse',
    component: () => import('@/views/userTest/answerParse.vue')
  },


  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('@/views/user/index.vue')
  },
  {
    path: '/errorBook',
    name: 'errorBook',
    component: () => import('@/views/user/errorBook.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/user/history.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/user/changePassword.vue')
  },
  {
    path: '/changePhone',
    name: 'changePhone',
    component: () => import('@/views/user/changePhone.vue')
  },
  {
    path: '/changeNickName',
    name: 'changeNickName',
    component: () => import('@/views/user/changeNickName.vue')
  },



  {
    path: '/libraryList',
    name: 'libraryList',
    component: () => import('@/views/library/libraryList.vue')
  },
  {
    path: '/bookDetail',
    name: 'bookDetail',
    component: () => import('@/views/library/bookDetail.vue')
  },


  {
    path: '/version',
    name: 'version',
    component: () => import('@/views/user/version.vue')
  },
  {
    path: '/editUser',
    name: 'editUser',
    component: () => import('@/views/user/editUser.vue')
  },



  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/sigin',
    name: 'sigin',
    component: () => import('@/views/sigin.vue')
  },
  {
    path: '/complateInfs',
    name: 'complateInfs',
    component: () => import('@/views/complateInfs.vue')
  },
  {
    path: '/webView',
    name: 'webView',
    component: () => import('@/views/webView.vue')
  },
  {
    path: '/commonContent',
    name: 'commonContent',
    component: () => import('@/views/commonContent/index.vue')
  },

  {
    path: '/textContent',
    name: 'textContent',
    component: () => import('@/views/commonContent/textContent.vue')
  },
  {
    path: '/questionContent',
    name: 'questionContent',
    component: () => import('@/views/commonContent/questionContent.vue')
  },
  
  

  {
    path: '/textTeading',
    name: 'textTeading',
    component: () => import('@/views/english/textTeading.vue')
  },
  {
    path: '/coreWords',
    name: 'coreWords',
    component: () => import('@/views/english/coreWords.vue')
  },
  {
    path: '/wordShorthand',
    name: 'wordShorthand',
    component: () => import('@/views/english/wordShorthand.vue')
  },


  
  {

    path: '*',
    name: 'errorPage',
    component: () => import('@/views/errorPage.vue')
  },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  }
})

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = getToken()
  if (to.path != '/login' && to.path != '/sigin') {
    //通过查看token判断是否登录
    if (token && token != '') {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login"
      });
    }
    if(to.path != '/complateInfs'){
      let isComplated = getComplatedStatus();
      if (isComplated && isComplated != '' && isComplated!=0) {
        next(); //表示已经完成完善信息
      } else {
        //next可以传递一个路由对象作为参数 表示需要跳转到的页面
        next({
          name: "complateInfs"
        });
        
      }
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

export default router
