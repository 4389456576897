import "babel-polyfill";


import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import api from "@/api/index.js";
import Vant from "vant";
import vueg from "vueg";
import "vant/lib/index.css";
import "./icons/index.js";
import navBar from "@/components/navBar";
import customAlert from "@/components/customAlert";
import custmEmpty from "@/components/custmEmpty.vue";

import MathJax from "@/utils/MathJax.js";

Vue.prototype.MathJax = MathJax;
Vue.use(VueAxios, axios);
Vue.use(Vant);
Vue.use(vueg, router, { shadow: false });
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

Vue.component("navBar", navBar);
Vue.component("customAlert", customAlert);

Vue.component("custmEmpty", custmEmpty);
import device from "current-device";
import "@/icons";
if (device.mobile()) {
  console.log("移动手机");
} else if (device.ipad()) {
  window.location.href = "http://cz.jingdian985.com/#/?active=0";
} else if (device.desktop()) {
  window.location.href = "http://cz.jingdian985.com/#/?active=0";
}
if (device.ios()) {
  window.localStorage.setItem("device", "ios");
}
if (device.android()) {
  window.localStorage.setItem("device", "android");
}
import Mixin from "./mixins";
Vue.mixin(Mixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
