var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-content"},[_c('navBar',{scopedSlots:_vm._u([(_vm.computeTotal)?{key:"right",fn:function(){return [(
          !_vm.isLoading &&
          _vm.questionData.list.length &&
          !['8', '9', '10', '11', '12', '13', '14'].includes(
            _vm.questionData.list[_vm.currentQuestion].type
          )
        )?[(0 < _vm.currentType && _vm.computeTotal && 1 < _vm.computeTotal)?_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentQuestionModle + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.accountRequest))])]):_vm._e()]:[(0 < _vm.currentType && _vm.computeTotal && 1 < _vm.computeTotal)?_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentQuestion + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.computeTotal))])]):_vm._e()]]},proxy:true}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.currentTitle))])]),(!_vm.isLoading)?[(!_vm.isEmpty)?[(0 < _vm.currentType && 1 < _vm.computeTotal)?_c('div',{staticClass:"common-progress"},[_c('div',{staticClass:"con",style:(("width:" + (((_vm.currentQuestion + 1) / _vm.computeTotal) * 100) + "%"))})]):_vm._e(),_c('div',{staticClass:"image-text-content",class:{
          isContent: _vm.currentType == 0 && _vm.contentData.content,
          isQuestion: 0 < _vm.currentType && _vm.questionData.list,
          isWordsQuestion: _vm.isWordsQuestion,
          more: 1 < _vm.jiexiData.length,
        }},[(_vm.currentType == 0)?[(_vm.currentTitle == '原文解析')?[(_vm.jiexiData.length)?[_c('van-image',{staticClass:"class-image",attrs:{"fit":"cover","src":_vm.setSourceUrl(_vm.jiexiData[_vm.currentClass].img)}}),_c('div',{staticClass:"class-title"},[_vm._v(" "+_vm._s(_vm.jiexiData[_vm.currentClass].title)+" ")]),_c('div',{staticClass:"class-auther"},[_vm._v(" "+_vm._s(_vm.jiexiData[_vm.currentClass].auther)+" ")]),_c('div',{staticClass:"class-content",attrs:{"id":"classContent"}},[_c('div',{staticClass:"con"},_vm._l((_vm.jiexiData[_vm.currentClass]
                      .content),function(paragraph,index){return _c('p',{key:("paragraph" + index),domProps:{"innerHTML":_vm._s(paragraph)}})}),0)])]:_vm._e()]:[(_vm.contentData.content || _vm.contentData.videourl)?[(_vm.contentData.videourl)?_c('div',{staticClass:"isContent",attrs:{"id":"content"}},[_c('div',{staticClass:"video-box",staticStyle:{"margin-bottom":"0.3rem"}},[_c('video-player',{attrs:{"isPlayed":_vm.isPlayed,"videoUrl":_vm.setSourceUrl(_vm.contentData.videourl)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlayed),expression:"!isPlayed"}],staticClass:"video-mask",on:{"click":function($event){$event.stopPropagation();return _vm.playAction.apply(null, arguments)}}},[_c('van-image',{staticClass:"post-img",attrs:{"fit":"cover","src":_vm.setSourceUrl(_vm.contentData.img)}}),_vm._m(0)],1)],1)]):_vm._e(),_c('div',{staticClass:"video-des",attrs:{"id":"content"}},[(_vm.contentData.title)?_c('div',{staticClass:"title-box"},[_vm._v(" 《"+_vm._s(_vm.html2string(_vm.contentData.title))+"》 ")]):_vm._e(),_c('div',{staticClass:"img-text-box"},[_c('van-swipe',{staticClass:"my-swipe",class:{ textBody: _vm.currentTitle == '文章结构' },attrs:{"indicator-color":"#20a287"}},_vm._l((_vm.imageData),function(img,index){return _c('van-swipe-item',{key:index},[_c('div',{staticClass:"image-box",class:{ textBody: _vm.currentTitle == '文章结构' }},[_c('van-image',{attrs:{"fit":"scale-down","src":img},on:{"click":function($event){return _vm.previewImg(index)}}})],1)])}),1),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.contentData.content)}})],1)])]:_vm._e()]]:(0 < _vm.currentType)?_c('div',{staticClass:"isquestion"},[(
              !['8', '9', '10', '11', '12', '13', '14'].includes(
                _vm.questionData.list[_vm.currentQuestion].type
              )
            )?_c('div',{staticClass:"question-content",domProps:{"innerHTML":_vm._s(_vm.questionData.list[_vm.currentQuestion].content)}}):_vm._e(),(_vm.questionData.list ? _vm.questionData.list.length : false)?[_c('questionType',{attrs:{"questionData":_vm.questionData.list[_vm.currentQuestion]}}),(_vm.questionData.list[_vm.currentQuestion].showParse)?_c('div',{staticClass:"qusetion-parse"},[(_vm.questionData.list[_vm.currentQuestion].rightkey)?_c('div',{staticClass:"question-proper"},[_c('span',{staticClass:"tit"},[_vm._v("答案：")]),_c('span',{domProps:{"innerHTML":_vm._s(
                    ("" + (_vm.html2string(
                      _vm.questionData.list[_vm.currentQuestion].rightkey
                    )))
                  )}})]):_vm._e(),(!['文言文虚词'].includes(_vm.moudleName))?_c('div',{staticClass:"question-proper"},[_c('span',{staticClass:"tit"},[_vm._v("解析：")]),_c('span',{domProps:{"innerHTML":_vm._s(
                    ("" + (_vm.html2string(
                        _vm.questionData.list[_vm.currentQuestion].analysiscontent
                      ) || '略'))
                  )}})]):_vm._e()]):_vm._e()]:_vm._e()],2):_vm._e()],2),(!_vm.isWordsQuestion)?[(0 < _vm.currentType && _vm.computeTotal)?_c('div',{staticClass:"question-handle"},[(1 < _vm.computeTotal)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(1)}}},[_vm._v(" 上一题 ")]):_c('span'),(1 < _vm.computeTotal)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(0)}}},[_vm._v(" 下一题 ")]):_vm._e(),(
              !_vm.exceptParse.includes(_vm.questionData.list[_vm.currentQuestion].type)
            )?_c('div',{staticClass:"btn jiexi-btn",on:{"click":_vm.showParseAction}},[_vm._v(" 查看解析 ")]):_vm._e()]):_vm._e()]:_vm._e()]:_vm._e()]:_vm._e(),_c('audio',{ref:"errorAudio",staticClass:"status-vioce",attrs:{"src":_vm.errorVoice,"controlslist":"nodownload","controls":"controls"}}),_c('audio',{ref:"properAudio",staticClass:"status-vioce",attrs:{"src":_vm.properVoice,"controlslist":"nodownload","controls":"controls"}}),(1 < _vm.jiexiData.length)?_c('ul',{staticClass:"class-list"},_vm._l((_vm.jiexiData),function(item,index){return _c('li',{key:index,class:{ active: _vm.currentClass == index },on:{"click":function($event){return _vm.changeClass(index)}}},[_c('span',[_vm._v(" 文章"+_vm._s(Number(index) + 1))])])}),0):_vm._e(),(_vm.currentSuject.name == '英语' && _vm.currentTitle == '练习')?_c('div',{staticClass:"select-transelate-mudule",on:{"click":_vm.changeTranselateModule}},[_vm._v(" "+_vm._s(_vm.translateModule == 1 ? "汉/英" : "英/汉")+" ")]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-play"},[_c('i',{staticClass:"iconfont icon-bofang"})])}]

export { render, staticRenderFns }