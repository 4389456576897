<template>
  <div class="error-book">
    <navBar :showBack="false">
      <span class="title">学习记录</span>
    </navBar>
    <div class="page-content">
      <ul v-if="dataList.length" class="history-list">
        <li v-for="(item, index) in dataList" :key="index" @click="goNext(item)">
          <van-image
            class="icon"
            fit="cover"
            :src="setSourceUrl(item.curseimg)"
          ></van-image>
          <div class="course-infs">
            <div class="course-name">
              {{ item.maxtypename }}
              <span v-if="item.cursetitle">/{{ item.cursetitle }}</span>
              <span v-if="item.danyuanname">/{{ item.danyuanname }}</span>
            </div>
            <div class="course-author">
              <span>{{ item.coursechapters_title }}</span>
              <span>{{ item.content }}</span>
            </div>
            <div class="time-status">
              <div class="time">
                <i class="iconfont icon-home_shijian"></i>{{ item.updatetime }}
              </div>
              <!-- <div class="status">
                <i class="iconfont icon-home_wancheng"></i>完成{{
                  item.rateoflearning || "0%"
                }}
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
export default {
  name: "learnHistory",
  data() {
    return {
      dataList: [],
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    setSourceUrl,
    init() {
      this.$api.getLastLearn().then((res) => {
        let resData = res.data || [];

        // 转换后的二维数组
        let newData = Object.values(
          resData.reduce((res, item) => {
            res[item.maxtypename]
              ? res[item.maxtypename].push(item)
              : (res[item.maxtypename] = [item]);
            return res;
          }, {})
        );
        this.dataList = newData.flat(1);
      });
    },
    goNext(item) {
      this.$store.commit('common/setBookContent',[])
      this.$router.push({
        path: "/bookContent",
        query: {
          id: item.course_id,
          name: item.cursetitle,
          navType: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.history-list {
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.15rem 0.28rem;
    &:active {
      background: rgba(0, 0, 0, 0.01);
    }
    .icon {
      width: 1.64rem;
      height: 1.84rem;
      min-width: 1.64rem;
      margin-right: 0.28rem;
    }
    .course-infs {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    .course-name {
      font-size: 0.28rem;
      font-weight: 500;
      color: #333;
      margin-bottom: 0.15rem;
    }
    .course-author {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0.3rem;
      span {
        color: #909090;
        font-weight: 400;
        font-size: 0.24rem;
      }
    }
    .time-status {
      display: flex;
      justify-content: flex-start;
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0.45rem;
        font-weight: 400;
        color: #909090;
        font-size: 0.24rem;
        i {
          font-weight: 400;
          color: #909090;
          font-size: 0.24rem;
          margin-right: 0.17rem;
        }
      }
    }
  }
}
</style>