<template>
  <div class="question-box">
    <div class="question-test" v-html="privateData.testpapecontent"></div>

    <ul class="question-option">
      <li
        v-for="(item, index) in privateData.optionjson"
        :key="index"
        :class="{
          select: item.status == 1,
          proper: item.status == 2,
          error: item.status == 3,
        }"
        @click="selectOpt(item, index)"
      >
        <span class="value">{{ item.value }}</span>
        <span class="text" v-html="item.text"></span>
      </li>
    </ul>
    <!-- <audioPlayer
      style="margin-bottom: 0.5rem"
      :audioSrc="audioSrc"
      @click.native="startPlay"
    ></audioPlayer> -->
    <audio
      :src="errorVoice"
      controlslist="nodownload"
      controls="controls"
      ref="errorAudio"
      class="status-vioce"
    ></audio>
    <audio
      :src="properVoice"
      controlslist="nodownload"
      controls="controls"
      ref="properAudio"
      class="status-vioce"
    ></audio>
  </div>
</template>

<script>
//import audioPlayer from "@/components/audioPlayer.vue";
import {html2string } from '@/utils/public'
export default {
  name: "questionType14",
  //components: { audioPlayer },
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      errorVoice: require("@/assets/audio/error.mp3"),
      properVoice: require("@/assets/audio/proper.mp3"),
      errorAudio: null,
      properAudio: null,
      privateData: {},
      audioSrc: "",
    };
  },
  mounted() {
    this.properAudio = this.$refs["properAudio"];
    this.errorAudio = this.$refs["errorAudio"];
  },
  methods: {
    html2string,
    startPlay() {
      this.audioSrc = this.privateData.hearing;
    },
    selectOpt(item, index) {
      this.properAudio.currentTime = 0;
      this.errorAudio.currentTime = 0;
      this.properAudio.pause();
      this.errorAudio.pause();
      let optionjson = this.privateData.optionjson;
      optionjson.forEach((item) => {
        item.status = 0;
      });
      this.$set(this.privateData, "optionjson", optionjson);
      let rightkey = this.html2string(this.privateData.rightkey);
      if (item.value == rightkey) {
        this.properAudio.play();
        this.$set(this.privateData.optionjson[index], "status", 2);
      } else {
        this.errorAudio.play();
        this.$set(this.privateData.optionjson[index], "status", 3);
      }

      this.$set(this.privateData, "answerVal", item.value);
      this.$set(this.privateData, "answerIdx", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.status-vioce {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.question-content {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px #dcdcdc dashed;
  ::v-deep img {
    max-width: 100%;
  }
}
.question-test {
  line-height: 1.6;
  margin-bottom: 0.5rem;
  ::v-deep {
    font-size: 0.32rem !important;
  }
}
.question-option {
  li {
    display: flex;
    align-items: center;
    padding: 0.32rem;
    margin-bottom: 0.32rem;
    background: #f8f8f8;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    color: #3a3a3a;
    line-height: 1.6;
      overflow: auto;
    .value {
      position: relative;
      padding-right: 0.26rem;
      margin-right: 0.26rem;
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 0.02rem;
        height: 0.24rem;
        background: #d8d8d8;
      }
    }
  }
  .select {
    background: #e1f5f1;
  }
  .proper {
    color: #fff;
    background: #27b295;
  }
  .error {
    color: #fff;
    background: #ff494a;
  }
}
</style>