const state = {
    libraryData:{}
}
const getters = {
    getLibrary (state) {
        return state.libraryData
    }
}
const actions = {
    
}
const mutations = {
    setLibrary (state, data) {
        state.libraryData = data
    }
}
export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
}