<template>
  <div class="book-content">
    <navBar>{{ currentTitle }}</navBar>

    <div class="content-box">
      <menuPage
        :menuData="menuData"
        :navType="navType"
        v-if="menuData.length"
      ></menuPage>
    </div>
  </div>
</template>

<script>
import menuPage from "@/components/treeMenu/menuPage.vue";
export default {
  name: "bookContent",
  components: { menuPage },
  watch: {
    acount: {
      handler: function (val) {
        if (this.navType != 1) {
          return;
        }
        if (val == this.allRequest) {
          this.dataList.forEach((item) => {
            let idx = 0;
            item.index = idx;
            item.showChild = true;
            this.forEachData(item, idx);
          });
          this.menuData = this.dataList;
          this.$store.commit("common/setBookContent", this.menuData);
        }
      },
    },
  },
  data() {
    return {
      menuData: [],
      allRequest: 0,
      acount: 0,
      navType: 0,
      dataList: [],
      // moudleName:''
    };
  },
  created() {
    //window.localStorage.removeItem("courseId");
    // this.$store.commit('common/setUnitNav',[])
    if (Number(this.$route.query.binddata)) {
      this.currentId = this.$route.query.binddata;
    } else {
      this.currentId = this.$route.query.id;
    }
    this.navType = this.$route.query.navType;
    //this.moudleName = this.$route.query.moudleName;
    this.menuData = this.$store.getters["common/getBookContent"];
    if (!this.menuData.length) {
      if (this.navType == 0) {
        this.getCourseUnit();
      }
      if (this.navType == 1) {
        this.getNavClass();
      }
    }
  },
  methods: {
    getNavClass() {
      this.$api
        .getNavClass({
          navid: this.currentId,
        })
        .then((res) => {
          let resData = res.data?.data || [];
          this.allRequest = resData.length;

          for (let i = 0; i < resData.length; i++) {
            this.$api
              .getCourseTypeContall({
                coursetypeid: resData[i].id,
              })
              .then((res) => {
                this.acount = ++this.acount;
                resData[i].children = res.data || [];
              });
          }

          this.dataList = resData;
        });
    },
    getCourseUnit() {
      this.$api
        .getCourseUnit({
          courseid: this.currentId,
        })
        .then((res) => {
          let resData = res.data || [];

          resData.forEach((item) => {
            let idx = 0;
            item.index = idx;
            item.showChild = true;
            this.forEachData(item, idx);
          });
          this.menuData = resData;
          this.$store.commit("common/setBookContent", resData);
        });
    },
    forEachData(arr, idx) {
      if (arr.children.length) {
        ++idx;
        arr.children.forEach((item1) => {
          item1.index = idx;
          item1.showChild = false;
          if (item1.children.length) {
            this.forEachData(item1, idx);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  padding-top: 1.08rem;
  min-height: 100vh;
  background: #fafafa;
}
</style>