import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import userCenter from './modules/userCenter'
import library from './modules/library'
import mainIndex from './modules/mainIndex'
import course from './modules/course'
import famousRead from './modules/famousRead'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer(val){
    return {
      common:{
        subNav:val.common.subNav,
        currentSuject:val.common.currentSuject,
        answerStatus:val.common.answerStatus,
        questionObject:val.common.questionObject,
        wordsList:val.common.wordsList,
        lianxiData1:val.common.lianxiData1,
        lianxiData2:val.common.lianxiData2
      },
      course:{
        classNav:val.course.classNav
      },
      userCenter:{
        userInfs:val.userCenter.userInfs
      },
      famousRead:{
        famousReadData:val.famousRead.famousReadData,
        currentFamousRead:val.famousRead.currentFamousRead,
      }
      
    }
  }
})

const store = new Vuex.Store({
  strict: false,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    userCenter,
    mainIndex,
    library,
    course,
    famousRead
  },
  plugins: [vuexLocal.plugin]

})

export default store;
