<template>
  <div class="change-nickname-page">
    <navBar>
      <span class="title">修改昵称</span>
    </navBar>
    <div class="page-content" style="padding-top:1.18rem">
      <van-field
        v-model="nickName"
        type="text"
        label="昵称"
        placeholder="请填写昵称"
        :clearable="true"
      />

      <div class="submit-btn" @click="onSubmit">提交</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "changePhonePage",
  data() {
    return {
      nickName: "",
    };
  },
  computed: {
    ...mapGetters({
      userInfs: "userCenter/getUserInfs",
    }),
    userInfs: {
      get: function () {
        return this.$store.getters["userCenter/getUserInfs"];
      },
      set: function (data) {
        this.$store.commit("userCenter/setUserInfs", data);
      },
    },
  },
  created() {
    this.nickName = this.userInfs.nickname;
  },
  mounted() {},
  methods: {
    onSubmit() {
      if (!this.nickName) {
        this.$toast({
          message: "请输入昵称！",
        });
        return;
      }
      if (this.nickName == this.userInfs.nickname) {
        this.$toast({
          message: "请先进行修改再提交！",
        });
        return;
      }
      this.userInfs.nickname = this.nickName;
      this.$api
        .changeUserInfs({
          avatar: this.userInfs.avatar,
          nickname: this.nickName,
          gender: this.userInfs.gender,
        })
        .then((res) => {
          this.$toast({
            message: res.msg,
          });
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  margin: 2rem 0.28rem 0 0.28rem;
  text-align: center;
  line-height: 0.88rem;
  height: 0.88rem;
  background: #2db79a;
  box-shadow: 0px 3px 7px 0px rgba(45, 183, 154, 0.35);
  border-radius: 44px;
  font-size: 0.3rem;
  font-weight: 500;
  color: #fff;
  &:active {
    background: #90d9ca;
  }
}
.notic {
  padding-top: 0.4rem;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #909090;
}
</style>