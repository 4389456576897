<template>
  <div class="page page-index">
    <div class="tab-page" ref="tabPage">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>

    <ul class="component tabbar">
      <li
        v-for="(item, index) in tabBar"
        :key="index"
        :class="{ active: index == active }"
        @click="changeTab(item, index)"
      >
        <van-image
          class="icon-image"
          :src="index == active ? item.activeIcon : item.icon"
        ></van-image>
        <div class="bar-text">{{ item.text }}</div>
      </li>
    </ul>

    <div class="server-dialog" v-if="showServer" @click="closeServer">
      <div class="server-main" :style="`backgroundImage:url(${serverBg})`">
        <p class="text">请拨打客服电话售后服务</p>
        <p class="phone">400-801-8691</p>
        <a class="call-btn" href="tel:400-801-8691">立即拨打</a>
        <div class="cancle-call" @click="closeServer">取消</div>
      </div>
    </div>

    <van-image-preview v-model="showPreview" :images="imgList" />
  </div>
</template>

<script>
export default {
  name: "indexPage",
  data() {
    return {
      serverBg: require("../assets/img/serverBg.png"),
      showPreview: false,
      showServer: false,
      imgList: [],
      active:0,
      tabBar: [
        {
          icon: require("@/assets/icon/home0.png"),
          activeIcon: require("@/assets/icon/home1.png"),
          text: "首页",
          url: "/",
        },
        {
          icon: require("@/assets/icon/library0.png"),
          activeIcon: require("@/assets/icon/library1.png"),
          text: "图书馆",
          url: "/library",
        },
        {
          icon: require("@/assets/icon/user0.png"),
          activeIcon: require("@/assets/icon/user1.png"),
          text: "我的",
          url: "/user",
        },
      ],
    };
  },
  watch:{
    $route:function(val){
      let active = val.query.active || 0 ;
      this.active=active;
    }
  },
  created() {
    this.active = this.$route.query.active||0
  },
  mounted() {
    this.$refs.tabPage.addEventListener("scroll", (e) => {
      this.st = e.target.scrollTop;
    });
    this.$eventBus.$on("showServer", (val) => {
      this.showServer = val;
    });
    this.$eventBus.$on("showPreview", (params) => {
      this.showPreview = params.handle;
      this.imgList = params.imgList;
    });
  },
  methods: {
    changeTab(item, idx) {
      this.active =idx;
      this.$router.push({
        path: item.url,
        query: {
          active: idx,
        },
      });
    },
    closeServer() {
      this.showServer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-image{
  width: .31rem;
  height: .31rem;
}
.tabbar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 0.98rem;
  background: #fff;
  border-top: 1px #dcdcdc solid;
  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
      line-height: 1;
      font-size: 0.4rem;
    }
    .bar-text {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333;
      line-height: 0.34rem;
    }
  }
  .active {
    i,
    .bar-text {
      color: #28b396;
    }
  }
}
.server-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .server-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 5.63rem;
    height: 6.46rem;
    background-size: cover;
    animation: showServer 0.3s ease-in;
    .cancle-call {
      padding-bottom: 0.34rem;
      font-weight: 400;
      color: #2db79a;
      font-size: 0.26rem;
    }
    .call-btn {
      margin-bottom: 0.25rem;
      width: 4.48rem;
      height: 0.8rem;
      background: linear-gradient(182deg, #29bc9d, #179d81);
      border-radius: 0.4rem;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.3rem;
      color: #fff;
      font-weight: 500;
      &:active {
        background: linear-gradient(182deg, #179d81, #29bc9d);
      }
    }
    .phone {
      margin-bottom: 0.34rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #333;
    }
    .text {
      margin-bottom: 0.34rem;
      font-size: 0.36rem;
      font-weight: 400;
      color: #333;
    }
  }
}
.checkin {
  width: 1rem;
  height: 0.5rem;
  background: linear-gradient(90deg, #3cbea5 0%, #06bda8 100%);
  border-radius: 0.25rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.index-title {
  color: #fff;
  font-size: 0.34rem;
}
.title {
  font-size: 0.24rem;
}
.tab-page {
  padding-bottom: 0.98rem;
}
@keyframes showServer {
  0% {
    transform: translate3d(0, 3rem, 0);
    opacity: 0.6s;
  }
  50% {
    transform: translate3d(0, -1rem, 0);
    opacity: 0.9;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
</style>
