import https from '../http';
const authorization = {
    /*会员登陆*/
    userLogin: function(params){
        return https.post('/user/login',params)
    },
    /*会员登出*/
    userSignout: function(params){
        return https.post('/user/logout',params)
    },
    /*token检测*/
    checkToken: function(params){
        return https.post('/token/check',params)
    },
    /*token刷新*/
    refreshToken: function(params){
        return https.post('/token/refresh',params)
    },
    /*站点信息*/
    webInfs: function(params){
        return https.get('/common/getsiteinfo',params)
    }
};

export default authorization
