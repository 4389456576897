<template>
  <div class="read-by-turn">
    <div class="name-author">
      <div class="name" v-html="currentClass.name"></div>
      <div class="author" v-html="currentClass.auther"></div>
    </div>

    <div class="class-content">
      <div class="content-box">
        <p
          v-for="(item, index) in currentClass.paragraph"
          :key="`a${index}`"
          :style="`text-indent:${
            currentClass.courseclass_id == 2 ? '0' : '2em'
          }`"
        >
          <span
            :class="{
              active: item1.status,
              recite: item1.recite,
              recited: !item1.isRecite && item1.recite,
            }"
            :key="`b${index1}`"
            v-for="(item1, index1) in item"
            v-html="replaceText(item1.text)"
            @click="selectWords(index, index1, item1)"
          >
          </span>
        </p>
      </div>
    </div>
    <div class="audio-box">
      <div class="audio-palyer">
        <div class="custom-audio">
          <div class="play-btn" @click="playAction">
            <i
              v-if="isLoading"
              class="iconfont icon-loading-three iconloading"
            ></i>
            <i
              v-else
              :class="`iconfont ${isplay ? 'icon-zanting' : 'icon-bofang'}`"
            ></i>
          </div>
          <div class="play-progress">
            <van-slider :max="timeMax" v-model="progress" />
          </div>
          <div class="play-tiem">{{ audioTime }}</div>
        </div>
      </div>
    </div>

    <audio
      class="audio-item"
      :src="setSourceUrl(audioSrc)"
      ref="audioItem"
      id="audioItem"
      controlslist="nodownload"
      controls="controls"
      muted
      autoplay
      @ended="playEnd"
    ></audio>
  </div>
</template>

<script>
import { html2string, setSourceUrl,deepClone } from "@/utils/public";
import { mapGetters } from "vuex";
export default {
  name: "readBySigin",
  computed: {
    ...mapGetters({
      famousReadData: "famousRead/getCurrentFamousRead",
    }),
  },
  watch: {
    famousReadData: function (val) {
      this.currentClass =this.deepClone(val);
    },
    audioSrc: function () {
      let audioItem = document.getElementById("audioItem");
      audioItem.pause();
      audioItem.addEventListener("canplay", () => {
        this.isplay = true;
        this.audioTime = this.getTime(Math.ceil(audioItem.duration));
        this.timeMax = audioItem.duration;
        audioItem.addEventListener("timeupdate", ()=>{
          this.progress = audioItem.currentTime;
        });
        audioItem.play();
        audioItem.muted=false;
      });

      //this.audioItem.play()
    },
  },
  data() {
    return {
      currentClass: {},
      audioSrc: "",
      isLoading: false,
      isplay: false,
      timeMax: 100,
      progress: 0,
      audioTime: "00:00:00",
      audioItem: null,
    };
  },
  created() {
    this.currentClass = this.deepClone(this.famousReadData);
  },
  mounted() {
    this.audioItem = this.$refs["audioItem"];
  },
  methods: {
    html2string,
    setSourceUrl,
    deepClone,
    playAction() {
      if(!this.audioSrc){
        this.$notify({
          type:'warning',
          message:'请先选择跟读语句！'
        })
      }else{
        if(this.isplay){
          this.audioItem.pause()
          this.isplay=false;
        }else{
          this.audioItem.play()
          this.isplay=true;
        }
      }
    },
    playEnd() {
      this.progress=0;
      this.isplay=false;
    },
    replaceText(str) {
      return str.replace(new RegExp("&lt;br/&gt;", "g"), "<br/>");
    },
    selectWords(idx1, idx2, item) {
      let paragraph = this.currentClass.paragraph;
      paragraph.forEach((item) => {
        item.forEach((opt) => {
          opt.status = false;
        });
      });

      this.$set(this.currentClass, "paragraph", paragraph);
      this.$set(this.currentClass.paragraph[idx1][idx2], "status", true);
      this.audioSrc = item.bdttsfile;
    },
    getTime(time) {
      // 转换为式分秒
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      let _tiem = `${h}:${m}:${s}`;
      return _tiem;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-item {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.custom-audio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1rem;
  padding: 0 0.36rem;
  border-radius: 0.5rem;
  background: #e2f5f1;
  ::v-deep .van-slider {
    background: #fff;
  }
  ::v-deep .van-slider__bar {
    background: #28b295;
  }
  ::v-deep .van-slider__button {
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    border: 0.02rem #28b295 solid;
  }
  .play-progress {
    flex: 1;
    padding: 0 0.15rem;
    .van-slider {
      width: 100%;
    }
  }
  .play-tiem {
    text-align: right;
    width: 1.2rem;
    font-size: 0.28rem;
    color: #28b295;
  }
  .play-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    width: 0.64rem;
    height: 0.64rem;
    .iconfont {
      font-size: 0.78rem;
      line-height: 1;
      color: #28b295;
    }
    .iconloading {
      font-size: 0.68rem;
      animation: circle 2s linear infinite;
    }
    @keyframes circle {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .disable {
    .iconfont {
      color: #909090;
    }
  }
}
.audio-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 0.3rem;
  background: #fff;
  .readall-btn {
    position: absolute;
    left: 0.28rem;
    top: 0.28rem;
    width: 1rem;
    height: 1rem;
    z-index: 100;
  }
}
.read-by-turn {
  padding: 0 0.3rem 1.8rem 0.3rem;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  .name-author {
    text-align: center;
    margin-bottom: 0.5rem;
    .name {
      display: flex;
      justify-content: center;
      font-size: 0.35rem;
      font-weight: bold;
      margin-bottom: 0.1rem;
    }
    .author {
      font-size: 0.24rem;
      font-weight: 400;
    }
  }
  .class-content {
    text-indent: 2em;
    display: flex;
    justify-content: center;
    font-size: 0.26rem;
    line-height: 1.6;
    color: #1c1c1c;
    .active {
      color: #ff1e1e;
    }
  }
}
</style>