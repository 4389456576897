<template>
  <div class="question-box question-common">
    <ul class="question-test-ul" v-if="!['文言文虚词'].includes(moudleName)">
      <li
        :style="`backgroundImage:url(${tzg})`"
        v-for="(letter, index) in privateData.testpapecontent"
        :key="`a${index}`"
      >
        <span :class="{ mubiaozi: letter == privateData.mubiaozi }">
          {{ letter }}</span
        >
      </li>
    </ul>
    <ul class="question-test-ul nobg" v-else>
      <li
        v-for="(letter, index) in privateData.testpapecontent"
        :key="`a${index}`"
      >
        <span :class="{ mubiaozi: letter == privateData.mubiaozi }">
          {{ letter }}</span
        >
      </li>
    </ul>
    <div class="chouchu" v-if="['文言文虚词'].includes(moudleName)">
      {{ html2string(privateData.analysiscontent) }}
    </div>
    <div class="answer-input">
      <van-field
        v-model="privateData.answerVal"
        type="textarea"
        rows="5"
        placeholder="请输入您的答案"
      />
    </div>

    <div class="result-box" v-if="privateData.showParse">
      <div class="box-item">
        <span class="tit">【正确答案】</span>
        <span class="con" v-html="privateData.rightkey"></span>
      </div>

      <div class="box-item">
        <span class="tit">【您的答案】</span>
        <span class="con">{{ privateData.answerVal || "未作答" }}</span>
      </div>

      <div class="box-item">
        <span class="tit">【答案解析】</span>
        <span
          class="con"
          v-html="privateData.analysiscontent"
          v-if="privateData.analysiscontent && moudleName!='文言文虚词'"
        ></span>
        <span v-else>暂无</span>
      </div>
    </div>
  </div>
</template>

<script>
import { html2string } from "@/utils/public";
export default {
  name: "questionType10",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        val.testpapecontent = val.testpapecontent
          .replace(/<\/?.+?>/g, "")
          .replace(/ /g, "");
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      tzg: require("@/assets/img/tzg.png"),
      privateData: {},
      moudleName: "",
    };
  },
  created() {
    this.moudleName = this.$route.query.name;
  },
  methods: {
    html2string,
    selectOpt(item, index) {
      let rightkey = this.html2string(this.privateData.rightkey);
      let optionjson = this.privateData.optionjson;
      optionjson.forEach((item) => {
        item.status = "";
      });
      if (rightkey == item.value) {
        item.status = 2;
      } else {
        item.status = 3;
      }

      this.$set(this.privateData, "answerVal", item.value);
      this.$set(this.privateData, "answerIdx", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.result-box {
  width: 100%;
  font-size: 0.26rem;
  line-height: 1.6;
  .box-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.1rem;
    .tit {
      min-width: 1.8rem;
    }
    .con {
      flex: 1;
      word-break: break-all;
      overflow: auto;
      ::v-deep * {
        color: initial !important;
        font-family: initial !important;
        .math-formula {
          line-height: 1 !important;

          font-family: MathJax_Main !important;
          * {
          font-family: MathJax_Main !important;
            line-height: 1 !important;
          }
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
  }
}
.chouchu {
  width: 100%;
  text-align: right;
  font-size: 0.32rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
.question-box {
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
  //flex-direction: column;
  width: 100%;
  height: calc(100vh - 7rem);
}
.question-test-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  li {
    width: 0.76rem;
    height: 0.76rem;
    background-size: 0.752rem;
    margin: 0.1rem;
    font-size: 0.48rem;
    text-align: center;
    line-height: 0.76rem;
    .mubiaozi {
      color: #28b295;
    }
  }
}
.question-test-ul.nobg {
  width: 100%;
  li {
    width: auto;
    height: auto;
    margin: 0;
  }
}
.answer-input {
  width: 100%;
  min-height: 3rem;
  border: 0.01rem #dcdcdc solid;
  border-radius: 0.1rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
</style>