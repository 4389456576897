<template>
	<ul class="menu-item" :class="`nav-type-${navType}`">
		<template v-for="(menu, index) in menuList">
			<li :key="index" :class="{ noborder: menu.index == 0 }" v-if="menu.title != '动画课件'">
				<div class="item-box">
					<div
						class="item-con"
						:class="{
							hasChild: menu.type == 2 && menu.index != 0,
							showChild: menu.showChild && menu.index != 0,
							garybg: menu.index == 0,
							hasIcon: html2string(menu.title) == '名师考点精讲'
						}"
						@click="selectMenu(menu, index)"
					>
						<div class="con">
							<van-image
								class="icon"
								fit="cover"
								:src="setSourceUrl(menu.iconimage || menu.image)"
								:class="`icon-${menu.index}`"
								v-if="menu.iconimage"
							></van-image>
							<span
								class="title"
								:class="{ strongText: menu.index == 0 }"
								v-if="html2string(menu.title) != '名师考点精讲'"
								v-html="menu.title"
							></span>
							<span v-else style="padding-right: 0.3rem">
								<van-image
									class="mingshijingjiang"
									fit="cover"
									:src="setSourceUrl(menu.navimage)"
								></van-image>
							</span>
						</div>
						<van-icon class="arrow" name="arrow" style="margin-right: 0.3rem" v-if="menu.index != 0" />
					</div>
					<menuItem
						:menuLayer="layerNum"
						:menuData="menu.children"
						:moudleIndex="index"
						:parentName="menu.title"
						:class="{ hideChild: !menu.showChild }"
						v-if="menu.children.length"
					></menuItem>
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import { setSourceUrl, setQuestion, removeSpace, html2string } from '@/utils/public.js';
export default {
	name: 'menuItem',
	props: {
		menuLayer: {
			type: [String, Number],
			default: 0
		},
		menuData: {
			type: [Object, Array],
			default: () => {
				return [];
			}
		},father: {
			type: [Object, Array],
			default: () => {
				return [];
			}
		},
		navType: {
			type: [String, Number],
			default: 0
		}
	},
	watch: {
		menuData: {
			handler: function (val) {
				this.menuList = val;
			},
			immediate: true
		}
	},
	data() {
		return {
			currentIdx: 0,
			menuList: [],
			setQuestion: setQuestion,
			moudleName: '',
			fatherMenu: {},
			layerNum: 0,
			isfather:false,
		};
	},
	created() {
		let _menuLayer = this.menuLayer;
		this.moudleName = this.$route.query.moudleName;
		this.layerNum = ++_menuLayer;
	},
	methods: {
		setSourceUrl,
		removeSpace,
		html2string,
		selectMenu(item, idx) {
			window.sessionStorage.setItem('parentName', this.$attrs.parentName);
			this.$store.commit('common/setUnitNav', this.$store.getters['common/getUnitNav'] || []);
			if (this.menuLayer == 1) {
				window.sessionStorage.setItem('moudleName', this.$parent.menuData[this.$attrs.moudleIndex].title);

				if (this.$route.query.moudleName == '知识全解') {
					window.localStorage.setItem('courseId', this.$route.query.id);
				} else {
					window.localStorage.setItem('courseId', item.id);
				}

				this.$store.commit('common/setUnitNav', item.children);
			}
			if (this.menuLayer == 2) {
				window.localStorage.setItem('activeModule', idx);
			}

			if (item.type == 2) {
				if (item.children.length) {
					this.forEachData(this.menuList, item.id);
				}
			} else {
				let _path = '';
				let _id = item.id;
				let title = this.removeSpace(item.title);
				if (item.type == 0) {
					_path = '/textContent';
				}
				if (item.type == 1 || item.type == 3) {
					if (item.title == '拼写') {
						for (let i = 0; i < this.menuList.length; i++) {
							if (this.menuList[i].title == '速记') {
								_id = this.menuList[i].id;
								break;
							}
						}
					}
					_path = '/questionContent';
				}
				if (['名家朗读', '课文朗读'].includes(title)) {
					_path = '/famousRead';
				}
				if (title == '作者简介') {
					_path = '/author';
				}
				if (['真题试卷', '历年真题精选'].includes(title)) {
					_path = '/zhenTi';
				}
				if (title == '单词集中突破') {
					if (this.moudleName == '知识全解') {
						_path = '/wordsBreakThroughUnit';
					} else {
						_path = '/wordsBreakThrough';
					}
				}
				if (this.setQuestion.includes(title)) {
					_path = '/userTest';
					_id = item.id;
				}
				this.facherData(this.$store.getters["common/getBookContent"],item.pid)
				this.$router.push({
					path: _path,
					query: {
						name: item.title,
						id: _id,
						type: item.type,
						class: this.$route.query.class,
						courseId: window.localStorage.getItem('courseId') || this.$route.query.id,
						active: window.localStorage.getItem('activeModule'),
						binddata: item.binddata,
						istong:	this.isfather?1:0
					}
				});
			}
		},
		forEachData(arr, id) {
			if (Array.isArray(arr)) {
				arr.forEach((item) => {
					if (item.id == id) {
						item.showChild = !item.showChild;
					} else {
						item.showChild = false;
					}

					if (item.children.length) {
						
						this.forEachData(item.children, id);
					}
				});
			}
		},
		facherData(arr, id) {
			if (Array.isArray(arr)) {
				arr.forEach((item) => {
					if (item.id == id&&item.title == '同步课堂') {
						this.isfather = true;
					}else if (item.children.length) {
						this.facherData(item.children, id);
					}
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.mingshijingjiang {
	width: 100%;
	padding: 0.1rem 0;
}
.menu-item {
	height: initial;
	font-size: 0.28rem;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	background: #fff;
	li {
		border-bottom: 0.01rem #dcdcdc solid;

		&:last-child {
			border: 0;
		}
	}
	.noborder {
		border: 0;
	}
	.garybg {
		margin: 0 -0.3rem;
		background: #fafafa;
		padding-left: 0.3rem;
	}
	.item-con {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 1.12rem;
		.con {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0 0.3rem;
		}
		.icon {
			width: 0.6rem;
			height: 0.6rem;
			border-radius: 0.08rem;
			overflow: hidden;
			margin-right: 0.34rem;
			&-1 {
				width: 0.96rem;
				height: 0.76rem;
			}
			&-2 {
				width: 0.72rem;
				height: 0.72rem;
			}
			&-3 {
				width: 0.6rem;
				height: 0.6rem;
			}
			&-4 {
				width: 0.48rem;
				height: 0.48rem;
			}
			&-5 {
				width: 0.38rem;
				height: 0.38rem;
			}
		}
		.navtype {
			width: 0.44rem;
			height: 0.44rem;
		}
		.arrow {
			opacity: 0.5;
			color: #333334;
			transition: all 0.3s ease-in-out;
		}
	}
	.hasIcon {
		height: auto;
	}
	.hasChild {
		.arrow {
			transform: rotate(90deg);
		}
	}
	.showChild {
		.arrow {
			transform: rotate(-90deg);
		}
	}
	// .hasChild + .menu-item {
	//   height: 0;
	//   overflow: hidden;
	// }
	.strongText {
		font-weight: 500;
		font-size: 0.3rem;

		color: #323233;
	}
}
.nav-type-1 {
	.garybg {
		border-top: 0.15rem #fafafa solid;
		background: #fff;
	}
	.item-con {
		.con {
			.icon {
				&-1 {
					width: 0.84rem;
					height: 0.84rem;
				}
				&-2 {
					width: 0.72rem;
					height: 0.72rem;
				}
			}
		}
	}
}
.hideChild {
	height: 0;
	overflow: hidden;
}
.showChild + .menu-item {
	background: #fafafa;
	margin: 0 0.3rem;
}
</style>
