const state = {
    userInfs:{}
}
const getters = {
    getUserInfs (state) {
        return state.userInfs
    }
}
const actions = {
    
}
const mutations = {
    setUserInfs (state, data) {
        state.userInfs = data
    }
}
export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
}