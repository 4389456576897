import https from '../http';
const userCenter = {
    /*会员中心*/
    getUserInfs: function (params) {
        return https.post('/user/index', params)
    },
    /*修改个人信息*/
    changeUserInfs: function (params) {
        return https.post('/user/profile', params)
    },
    /*重置密码*/
    changePassword: function (params) {
        return https.post('/user/resetpwd', params)
    },
    /*获取科目列表*/
    getClassNav: function (params) {
        return https.get('/index/getnav', params)
    },
    /*获取版本*/
    getVersion: function (params) {
        return https.get('/bookbanben/index', params)
    },

    /*保存所选版本*/
    saveCheckVersion: function (parmas) {
        return https.post('/bookbanben/add_select_bookversion', parmas)
    },

    /*获取用户选择版本*/
    getSelectVersion: function (params) {
        return https.get('/bookbanben/get_select_bookversion', params)
    },

    /*获取用户年级列表*/
    getUserGrade: function (params) {
        return https.get('/gradeselectlist/get_gradelist', params)
    },
    /*添加用户基础信息*/
    addUserBaseInfs: function (params) {
        return https.post('/user/add_userinfo', params)
    },
    /*获取用户信息*/
    getUserInfoNew: function (params) {
        return https.get('/user/userinfo', params)
    },

    /*发送验证码*/
    sendSms: function (params) {
        return https.post('/sms/send', params)
    },

    /*校验验证码*/
    checkSms: function (params) {
        return https.post('/sms/check', params)
    },

};

export default userCenter
