<template>
	<ul class="course-list" v-if="courseData.length">
		<li v-for="(item, index) in courseData" :key="index" @click="goDetail(item)">
			<div class="course-img">
				<van-image class="img" fit="cover" :src="setSourceUrl(item.curseimg)"></van-image>
			</div>
			<div class="course-infs">
				<div class="course-name">{{ item.updatetime }} {{ item.maxtypename }}</div>
				<div class="course-author-unit">
					<span v-if="item.cursetitle && item.cursetitle != ''">{{ item.cursetitle }}</span>
					<span v-if="item.danyuanname && item.danyuanname != ''">|{{ item.danyuanname }}</span>
					<!-- <span v-if="item.chappid_name && item.chappid_name != ''"
            > |{{ item.chappid_name }}
          </span> -->
					<span v-if="item.coursechapters_title && item.coursechapters_title != ''">
						|{{ item.coursechapters_title }}
					</span>
					<span v-if="item.pname && item.pname != ''">|{{ item.pname }}</span>
				</div>
				<!-- <div class="course-time-status">
          <div class="time">
            <span class="iconfont icon-naozhong"></span>
            {{ item.updatetime }}
          </div>
          <div class="status">
            <span class="iconfont icon-home_wancheng"></span> 完成{{
              item.rateoflearning
            }}
          </div>
        </div> -->
			</div>
		</li>
	</ul>
</template>

<script>
import { setSourceUrl, setQuestion, removeSpace } from '@/utils/public';
export default {
	name: 'courseList',
	props: {
		courseData: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			setQuestion: setQuestion
		};
	},
	created() {
		//console.log(this.courseData);
	},
	methods: {
		setSourceUrl,
		removeSpace,
		goDetail(item) {
			let menuObj = item.learning_info || [];
			//let childrenLen = menuObj[item.active]?.children?.length;
			let _type = item.chaptertype;
			let path = '/questionContent'; //textContent
			this.$store.commit('common/setUnitNav', menuObj);
			this.$store.commit('common/setSubNav', menuObj[item.active]?.children || []);
			if (['课文朗读', '名家朗读'].includes(this.removeSpace(item.coursechapters_title))) {
				path = '/famousRead';
			} else if (['作者简介'].includes(this.removeSpace(item.coursechapters_title))) {
				path = '/author';
			} else if (['单词集中突破'].includes(this.removeSpace(item.cursetitle))) {
				path = '/wordsBreakThrough';
			} else if (['单词集中突破'].includes(this.removeSpace(item.coursechapters_title))) {
				path = '/wordsBreakThroughUnit';
			} else if (this.setQuestion.includes(this.removeSpace(item.coursechapters_title))) {
				path = '/userTest';
			} else {
				if (_type == 0) {
					path = '/textContent';
				}
			}
			console.log(item);
			this.$router.push({
				path: path,
				query: {
					courseId: item.course_id,
					id: item.coursechaptersid,
					type: _type,
					active: item.active,
					subActive: item.subactive,
					title: item.coursechapters_title || item.chappid_name || item.danyuanname || item.cursetitle,
					from: 'index'
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.course-list {
	li {
		width: 100%;
		margin-bottom: 0.3rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.course-img {
			width: 0.9rem;
			margin-right: 0.26rem;
			overflow: hidden;
			.img {
				width: 100%;
				height: 100%;
			}
		}
		.course-infs {
			flex: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			.course-name {
				width: 100%;
				font-size: 0.28rem;
				font-weight: 500;
				color: #333;
				margin-bottom: 0.15rem;
			}
			.course-author-unit {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 0.3rem;
				font-weight: 400;
				color: #909090;
				span {
					font-size: 0.24rem;
				}
			}
			.course-time-status {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				div {
					display: flex;
					color: #909090;
					font-weight: 400;
					font-size: 0.24rem;
					.iconfont {
						margin-right: 0.15rem;
					}
				}
				.time {
					margin-right: 0.45rem;
				}
			}
		}
	}
}
</style>
