<template>
  <div class="course-page">
    <navBar>
      <span class="title">
        {{ currentTitle }}
      </span>
      <template v-slot:right>
        <span
          style="color: #333"
          class="menu-icon iconfont icon-mulu"
          :class="{ active: activeMenu }"
          @click="showMenu"
        ></span>
      </template>
    </navBar>
    <div class="page-content">
      <ul class="course-outline" v-if="bookList && bookList.length">
        <li
          v-for="(item, index) in bookList"
          :key="`a${index}`"
          @click="goNext(item.title, item.id, item.binddata)"
        >
          <van-image
            class="img"
            fit="cover"
            :src="setSourceUrl(item.iconimage)"
          ></van-image>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </div>
    <customPicker
      v-if="activeMenu"
      v-model="currentVersion"
      :pickerData="pickerData"
      name="bookversionname"
      @close="closePicker"
      @selected="selected"
    ></customPicker>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
import customPicker from "@/components/customPicker.vue";
export default {
  name: "zhiShiQuanJie",
  components: {
    customPicker,
  },
  computed: {},
  data() {
    return {
      isEmpty:false,
      activeMenu: false,
      currentVersion: window.localStorage.getItem(this.$route.query.class),
      pickerData: [],
      bookList: [],
    };
  },
  created() {
    this.pickerData = this.dataList.pickerData;
    this.bookList = [];
    this.initBook()
  },
  methods: {
    setSourceUrl,
    initBook() {
      this.getBookVersion();
    },
    getTypeCourse(params) {
      this.$api.getTypeCourse(params).then((res) => {
        let resData = res?.data || [];
        this.bookList = resData;
        this.setData({
          pickerData: this.pickerData,
          currentVersion: this.currentVersion,
          bookList: resData,
        });
      });
    },
    showMenu() {
      this.activeMenu = true;
    },
    selected(val) {
      this.currentVersion = val;
      this.getTypeCourse({
        coursetypeid: this.currentId,
        bookid: this.currentVersion,
      });
      window.localStorage.setItem(this.$route.query.class,val)
    },
    getBookVersion() {
      this.$api
        .getSelectVersion({
          coursetypeid: this.$store.getters["common/getCurrentSuject"].id,
        })
        .then((res) => {
          this.pickerData = res.data || [];
          this.currentVersion = window.localStorage.getItem(this.$route.query.class) || res.data[0].id;
          this.getTypeCourse({
            coursetypeid: this.currentId,
            bookid: this.currentVersion,
          });
        });
    },
    closePicker() {
      this.activeMenu = false;
    },
    goNext(name, id,binddata) {
      this.$store.commit("common/setBookContent", []);
      this.$router.push({
        path: "/bookContent",
        query: {
          id: id,
          name: name,
          navType: this.$route.query.navType,
          class:this.$route.query.class,
          moudleName: this.$route.query.name,
          binddata :binddata
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  min-height: 100vh;
}
.course-outline {
  padding: 0.42rem 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4rem;
    margin-left: 0.3rem;
    .img {
      width: 2.1rem;
      height: 2.86rem;
      border-radius: 0.1rem;
      margin-bottom: 0.27rem;
      overflow: hidden;
    }
    p {
      width: 2.1rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333;
    }
  }
}
</style>