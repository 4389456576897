<template>
  <div class="empty-box">
    <van-empty description="暂无数据" />
  </div>
</template>

<script>
export default {
  name:"emptyBox",
  data(){
    return {
     
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty-box{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-img{
      width: 10.1rem;
      height: 2.8rem;
    }
  }
</style>