import https from '../http';
const common = {
	/*文件上传*/
	uploadFile: function(params) {
		return https.post('/common/upload', params)
	},
	/*获得解密后的数据*/
	decodeData: function(params) {
		return https.post('/decode/data_decode', params)
	},
	/* 百度语音接口*/
	baiduVoice: function(params) {
		return https.post('/bdtts/index', params)
	},

	/*学习时长*/
	learnTime: function(params) {
		return https.post('/userstudyduration/add_studyduration_time', params)
	},

	/*发送验证码*/
	sendSms: function(params) {
		return https.post('/sms/send', params)
	},

	/*校验验证码*/
	checkSms: function(params) {
		return https.post('/sms/check', params)
	},

	/*注册会员*/
	siginUser: function(params) {
		return https.post('/user/register', params)
	},

	/*验证激活码*/
	checkActiveCode: function(params) {
		return https.post('/activationcode/activation', params)
	},
	/*手机号码登录*/
	loginPhone: function(params) {
		return https.post('/user/authenticationcodelogin', params)
	},

	/*获取课文朗读语音*/
	getCourseAudio: function(params) {
		return https.post('/bdtts/index', params)
	},

	/*单词集中突破*/
	getWordBreakThrough: function(params) {
		return https.get('/specialenglishtraining/get_wordsetall', params)
	},

	/*单词集中突破 练习*/
	getWordBreakThroughTest: function(params) {
		return https.get('/specialenglishtraining/get_practices', params)
	},
	/*单词集中突破 记录当前索引*/
	setwordcord: function(params) {
		return https.post('/wordrecord/setwordcord', params)
	},
	// 获取学习记录数
	getuserwordnum: function(params) {
		return https.get('/specialenglishtraining/getuserwordnum', params)
	},
	/*获取年份下的城市*/
	getPaperCitys: function(params) {
		return https.get('/papeyear/get_papecitys', params)
	},
	
	/*获取学习记录 new*/
	getUserLearnings: function(params) {
		return https.post('/userlearning/getUserLearnings', params)
	},
	
};

export default common