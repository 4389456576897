<template>
  <div class="question-box question-common">
    <div
      class="question-content"
      v-if="privateData.content"
      v-html="privateData.content"
    ></div>
    <div class="question-test" v-html="privateData.testpapecontent"></div>
    <ul class="question-option">
      <li
        v-for="(item, index) in privateData.optionjson"
        :key="index"
        :class="{
          select: item.status == 1,
          proper: item.status == 2,
          error: item.status == 3,
        }"
        @click="selectOpt(item, index)"
      >
        <span class="value">{{ item.value }}</span>
        <span class="text option-text" v-html="item.text"></span>
      </li>
    </ul>

    <div class="result-box" v-if="privateData.showParse">
      <div class="box-item">
        <span class="tit">【正确答案】</span>
        <span class="con" v-html="privateData.rightkey"></span>
      </div>

      <div class="box-item">
        <span class="tit">【您的答案】</span>
        <span class="con">{{ privateData.answerVal || "未作答" }}</span>
      </div>

      <div class="box-item">
        <span class="tit">【答案解析】</span>
        <span
          class="con"
          v-html="privateData.analysiscontent"
          v-if="privateData.analysiscontent"
        ></span>
        <span v-else>暂无</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionType0",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      privateData: {},
    };
  },
  methods: {
    selectOpt(item, index) {
      let optionjson = this.privateData.optionjson;
      optionjson.forEach((item, idx) => {
        if (index == idx) {
          item.status = 1;
        } else {
          item.status = 0;
        }
      });
      this.$set(this.privateData, "answerVal", item.value);
      this.$set(this.privateData, "answerIdx", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.option-text{
  flex: 1;
}
.result-box {
  font-size: 0.26rem;
  line-height: 1.6;
  .box-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.1rem;
    .tit {
      min-width: 1.8rem;
    }
    .con {
      flex: 1;
      word-break: break-all;
      overflow: auto;
      ::v-deep * {
        color: initial !important;
        font-family: initial !important;
        .math-formula {
          line-height: 1 !important;
          font-family: MathJax_Main !important;
          * {
          font-family: MathJax_Main !important;
            line-height: 1 !important;
          }
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
  }
}
.question-content {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px #dcdcdc dashed;
  ::v-deep img {
    max-width: 100%;
  }
}
.question-test {
  ::v-deep .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
::v-deep .option-text {
  .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
.question-test {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}
.question-option {
  li {
    display: flex;
    align-items: center;
    padding: 0.32rem;
    margin-bottom: 0.32rem;
    background: #f8f8f8;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    color: #3a3a3a;
    line-height: 1.6;
    overflow: auto;
    .value {
      position: relative;
      padding-right: 0.26rem;
      margin-right: 0.26rem;
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 0.02rem;
        height: 0.24rem;
        background: #d8d8d8;
      }
    }
  }
  .select {
    background: #e1f5f1;
  }
  .proper {
    color: #fff;
    background: #27b295;
    .option-text {
      flex: 1;
      ::v-deep .mubiaozi {
        &::after {
          background: #fff;
        }
      }
    }
  }
  .error {
    color: #fff;
    background: #ff494a;

    .option-text {
      flex: 1;
      ::v-deep .mubiaozi {
        &::after {
          background: #fff;
        }
      }
    }
  }
}
</style>