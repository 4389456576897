<template>
  <div class="zheti-page">
    <navBar>
      <span>历年真题</span>
      <!-- <template v-slot:right><span class="print-btn" @click="printAction">打印</span></template> -->
    </navBar>
    <ul class="filter-box">
      <li
        v-for="(item, tag) in filterData"
        :key="tag"
        :class="{ active: item.active }"
        @click="selectRule(tag)"
      >
        <span>{{ item.value }}</span>
        <van-icon name="play" class="icon" />
      </li>
    </ul>

    <div class="area-picker" v-if="showPicker" @click="showPicker=false">
      <div
        class="picker-main"
        :class="{ show: showPicker }"
        @click.stop="showPicker = true"
      >
        <div class="body" ref="pickerBody">
          <ul class="data-list">
            <template v-for="(item, index) in areaData">
              <li
                v-if="item.label"
                :key="index"
                :class="{ active: currentIndex == index }"
                ref="li"
                @click.stop="
                  selectAction(index, item.value, item.label, item.children)
                "
              >
                {{ item.label }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <div class="test-content">
      <template v-if="activeIndex == 0">
        <div
          class="con"
          v-html="papeData.papecontent"
          v-if="papeData.papecontent"
        ></div>
      </template>
      <template v-if="activeIndex == 1">
        <div
          class="con"
          v-html="papeData.previewanswer"
          v-if="papeData.previewanswer"
        ></div>
      </template>
    </div>

    <template v-if="JSON.stringify(papeData) != '{}'">
      <div
        class="change-menu"
        v-show="activeIndex == 0"
        @click="activeIndex = 1"
      >
        答案
      </div>
      <div
        class="change-menu"
        style="background: #ff494a"
        v-show="activeIndex == 1"
        @click="activeIndex = 0"
      >
        试题
      </div>
    </template>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
import cityData from "@/utils/city.json";
export default {
  name: "zhenTi",
  data() {
    return {
      ztBg: require("@/assets/img/ztBg.png"),
      lineBg: require("@/assets/img/line.png"),
      showPicker: false,
      areaData: [],
      currentIndex: "",
      currentProvinceIndex: -1,
      currentCityIndex: -1,
      currentTimeIndex: -1,
      activeIndex: 0,
      currentTag: "",
      timeData: [],
      cityData:[],
      filterData: {
        province: {
          id: "",
          value: "--省份--",
          active: false,
        },
        time: {
          id: "",
          value: "--年份--",
          active: false,
        },
        city: {
          id: "",
          value: "--城市--",
          active: false,
        },
        
      },
      papeData: {},
    };
  },
  created() {
    //this.initFilter();
    //this.getTestData();
  },
  methods: {
    setSourceUrl,
    // printAction(){
    //   window.print();
    // },
    getCityData(){
      this.$api
        .getPaperCitys({
          province: this.filterData.province.value,
          coursetypeid:this.$store.getters['common/getCurrentSuject'].id,
          year:this.filterData.time.value,
        })
        .then((res) => {
          let year = res.data || [];
          let _cityData=[];
          year.forEach((item) => {
            _cityData.push({
              label:item,
              value:item
            })
          });
          this.cityData = _cityData
        });
    },
    getYearData() {
      this.$api
        .getPapeYaer({
          province: this.filterData.province.value,
          coursetypeid:this.$store.getters['common/getCurrentSuject'].id
        })
        .then((res) => {
          let year = res.data || [];
          let _timeData=[];
          year.forEach((item) => {
            _timeData.push({
              label:item,
              value:item
            })
          });
          this.timeData = _timeData
        });
    },
    getTestData() {
      this.activeIndex=0;
      this.$api
        .getYearTest({
          papeid: this.currentId,
          city: `${this.filterData.province.value}/${this.filterData.city.value}`,
          year: this.filterData.time.value,
        })
        .then((res) => {
          let resData = res.data || {};
          this.papeData = resData;
        });
    },
    selectRule(tag) {
      switch (tag) {
        case "province": {
          this.areaData = cityData;
          this.currentIndex = this.currentProvinceIndex;
          this.clickRuleItem(tag);
          break;
        }
        case "city": {
          if (!this.filterData.time.id) {
            this.$toast({
              message: "请先选择年份",
            });
            return false;
          }
          this.areaData = this.cityData;
          this.currentIndex = this.currentCityIndex;
          this.clickRuleItem(tag);
          break;
        }
        case "time": {
          if (!this.filterData.province.id) {
            this.$toast({
              message: "请先选择省份",
            });
            return false;
          }

          this.currentIndex = this.currentTimeIndex;
          this.areaData = this.timeData;
          this.clickRuleItem(tag);
          break;
        }
        default: {
          break;
        }
      }
    },
    clickRuleItem(tag) {
      Object.keys(this.filterData).forEach((item) => {
        this.$set(this.filterData[item], "active", false);
        if (item == tag) {
          this.$set(this.filterData[item], "active", true);
        }
      });

      this.showPicker = true;
      this.currentTag = tag;
      this.$nextTick(() => {
        let _height = this.$refs.li[0]?.offsetHeight;
        let _top = (this.currentIndex - 4) * _height;
        this.$refs.pickerBody.scrollTop = _top;
      });
    },
    closePicker() {
      this.showPicker = false;
      for (let i in this.filterData) {
        this.$set(this.filterData[i], "active", false);
      }
    },
    resetProvice() {
      this.$set(this.filterData.city, "id", "");
      this.$set(this.filterData.city, "value", "--城市--");
      this.$set(this.filterData.city, "active", false);
      this.currentTimeIndex=-1;

    },
    resetYear(){
      this.$set(this.filterData.time, "id", "");
      this.$set(this.filterData.time, "value", "--年份--");
      this.$set(this.filterData.time, "active", false);
      this.timeData=[];
      this.currentCityIndex=-1;
    },
    selectAction(idx, id, val, child) {
      this.$set(this.filterData[this.currentTag], "id", id);
      this.$set(this.filterData[this.currentTag], "value", val);
      switch (this.currentTag) {
        case "province": {
          this.currentProvinceIndex = idx;
          this.resetProvice(child);
          this.resetYear(child);
          this.getYearData(val);
          break;
        }
        case "city": {
          this.currentCityIndex = idx;
          this.getTestData();
          break;
        }
        case "time": {
          this.currentTimeIndex = idx;
          this.resetProvice();
          this.getCityData();
          break;
        }
        default: {
          break;
        }
      }
      this.closePicker();
    },
  },
};
</script>

<style lang="scss" scoped>
  .print-btn{
    text-align: right;
    color: #28b295;
  }
.change-menu {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1000;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #28b295;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.test-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 2.59rem;
  bottom: 0;
  z-index: 1;
  background: #fff;
  .con {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    border-radius: 0.08rem;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    ::v-deep{
      img{
        width: 120%;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}

.area-picker {
  position: fixed;
  left: 0;
  right: 0;
  top: 2.65rem;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  background: rgba(0,0,0,.3);
  .picker-main {
    width: 100%;
    background: #fff;
    background: #fff;
    border-bottom: .01rem  #28b295 solid;
    .body {
      max-height: calc(100vh - 5rem);
      overflow-y: auto;
      scroll-behavior: smooth;
      .data-list {
        li {
          height: 0.92rem;
          line-height: 0.92rem;
          padding-left: 0.54rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #333;
        }
        .active {
          background: #28b295;
          color: #fff;
        }
      }
    }
  }
}
.main-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 2.28rem;
  bottom: 1.3rem;
  padding: 1.1rem 0.28rem 0.28rem 0.28rem;
  background: #fff;
  background-size: 100% 2.7rem;
  background-position: top center;
  background-repeat: no-repeat;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 23px 1px rgba(128, 128, 128, 0.08);
    border-radius: 0.2rem;
    overflow-y: auto;
    scroll-behavior: smooth;
    .title {
      padding: 0.6rem 0 0.4rem 0;
      font-size: 0.3rem;
      color: #333;
      font-weight: 500;
      text-align: center;
      line-height: 0.52rem;
    }
    .base-infs {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.3rem;
      height: 0.6rem;
      margin-bottom: 0.95rem;
      border-radius: 0.3rem;
      background: #f2f2f2;
      li {
        flex: 1;
        font-size: 0.24rem;
        color: #333;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        border-right: 0.02rem #c0c1c3 solid;
        &:last-child {
          border: 0;
        }
      }
    }
    .base-question {
      width: 100%;
      padding: 0 0.88rem;
      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.55rem;
        color: #333;
        .tit {
          font-size: 0.26rem;
          font-weight: 500;
        }
        .code {
          font-size: 0.24rem;
          font-weight: 400;
        }
        .line {
          flex: 1;
          margin: 0 0.2rem;
          height: 0.03rem;
          background-size: 0.15rem 0.03rem;
          background-position: left top;
          background-repeat: repeat-x;
        }
      }
    }
  }
}
.star-btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.25rem 0.28rem;
  background: #fff;
  .btn {
    width: 100%;
    height: 0.8rem;
    background: #28b396;
    border-radius: 0.4rem;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #fff;
    &:active {
      opacity: 0.8;
    }
  }
}
.zheti-page {
  min-height: 100vh;
  background: #fafafa;
}
.filter-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.23rem;
  z-index: 100;
  padding: 0.3rem 0.28rem;
  display: flex;
  justify-content: space-between;
  background: #fff;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.1rem;
    height: 0.6rem;
    line-height: 1;
    border-radius: 0.3rem;

    span {
      width: 1.5rem;
      text-align: center;
      font-size: 0.3rem;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #323232;
    }
    .icon {
      font-size: 0.3rem;
      color: #fff;
      color: #dcdee0;
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out;
    }
  }
  .active {
    span {
      color: #28b295;
    }
    .icon {
      transform: rotate(-90deg);
      color: #28b295;
    }
  }
}
</style>