<template>
  <div class="page main-page">
    <van-swipe
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
      v-if="swiperImg.length"
    >
      <van-swipe-item
        v-for="(item, index) in swiperImg"
        :key="`a${index}`"
        @click="swiperDetail(item.url, item.title)"
      >
        <div class="swiper-img">
          <van-image
            class="img"
            width="100%"
            height="100%"
            fit="cover"
            :src="setSourceUrl(item.image)"
          />
        </div>
      </van-swipe-item>
    </van-swipe>

    <ul class="main-nav">
      <li
        v-for="(item, index) in indexNav"
        :key="`b${index}`"
        @click="goClass(item)"
      >
        <div class="nav-icon">
          <van-image
            width="100%"
            height="100%"
            fit="cover"
            :src="setSourceUrl(item.navimage)"
          />
        </div>
        <div class="nav-text">{{ item.title }}</div>
      </li>
    </ul>

    <div class="notify" v-if="notifyData.length">
      <van-image class="notify-icon" :src="notifyIcon"></van-image>
      <div class="notify-con">
        <van-swipe
          class="notify-swipe"
          :autoplay="3000"
          vertical
          :show-indicators="false"
        >
          <van-swipe-item
            v-for="(item, index) in notifyData"
            :key="`b${index}`"
            @click="notifyDetail(item.id)"
          >
            <p class="notify-text">{{ item.title }}</p>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="banner-box" v-if="advertData.advertisementimage">
      <van-image
        :src="setSourceUrl(advertData.advertisementimage)"
        @click="detailAdvert"
      ></van-image>
    </div>
    <template v-if="!loadingHistory">
      <div class="course-learn" v-if="courseData.length">
        <div class="item-header">
          <div class="header-left">最近学习</div>
          <div class="header-right" @click="viewMore">
            查看更多 <i class="iconfont icon-fanhui"></i>
          </div>
        </div>
        <ul class="course-nav">
          <li :class="{ active: activeNav == 100 }" @click="changeCourse(100)">
            全部
          </li>
          <li
            :class="{ active: activeNav == index }"
            v-for="(item, index) in dataSource"
            :key="`c${index}`"
            @click="changeCourse(index)"
          >
            {{ item[0].maxtypename }}
          </li>
        </ul>

        <courseList
          :courseData="courseData"
          v-if="courseData.length"
        ></courseList>
      </div>
      <div class="course-learn" v-else>
        <div class="item-header">
          <div class="header-left">最近学习</div>
          <div class="header-right" @click="viewMore">
            查看更多 <i class="iconfont icon-fanhui"></i>
          </div>
        </div>
        <div class="nothing">
          <custm-empty></custm-empty>
        </div></div
    ></template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setSourceUrl } from "@/utils/public";
import courseList from "@/components/courseList.vue";

export default {
  name: "mainPage",
  components: {
    courseList,
  },
  computed: {
    ...mapGetters({
      swiperImg: "mainIndex/getSwiperImg",
      indexNav: "mainIndex/getIndexNav",
      refreshToken: "common/getRefreshToken",
      siteUrl: "common/getSiteurl",
    }),
  },
  watch: {
    refreshToken: {
      handler: function (val) {
        if (!val) {
          this.initIndex();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      loadingHistory: true,
      notifyIcon: require("../../assets/img/notify_icon.png"),
      notifyData: [],
      activeNav: 100,
      dataSource: {},
      allData: [],
      courseData: [],
      fileList: [],
      advertData: {},
    };
  },
  created() {
    this.initIndex();
  },
  methods: {
    setSourceUrl,
    initIndex() {
      this.getSwiperlist();
      this.getIndexNav();
      this.getLastLearn();
      this.getIndexNotify();
      this.getAdvert();
    },
    getSwiperlist() {
      this.$api.getSwiperlist().then((res) => {
        this.$store.commit("mainIndex/setSwiperImg", res.data);
      });
    },
    swiperDetail(url, title) {
      if (url) {
        this.$router.push({
          path: "/webView",
          query: {
            webUrl: url,
            title: title,
          },
        });
      }
    },
    getIndexNav() {
      this.$api.getIndexNav().then((res) => {
        this.$store.commit("mainIndex/setIndexNav", res.data);
      });
    },
    getIndexNotify() {
      this.$api.getIndexNotify().then((res) => {
        this.notifyData = res.data;
      });
    },
    getLastLearn() {
      let that = this;
      that.loadingHistory = true;
      that.$api.getLastLearn().then((res) => {
        let resData = res.data || [];

        // 转换后的二维数组
        let newData = Object.values(
          resData.reduce((res, item) => {
            res[item.maxtypename]
              ? res[item.maxtypename].push(item)
              : (res[item.maxtypename] = [item]);
            return res;
          }, {})
        );
        that.allData = newData.flat(1);
        that.dataSource = newData;
        that.loadingHistory = false;
        that.changeCourse(100);
      });
    },
    getAdvert() {
      this.$api.getAdvert().then((res) => {
        this.advertData = res.data || {};
      });
    },
    detailAdvert() {
      if (this.advertData.url) {
        this.$router.push({
          path: "/webView",
          query: {
            webUrl: this.advertData.url,
            title: this.advertData.title,
          },
        });
      }
    },
    changeCourse(idx) {
      if (idx != 100) {
        this.courseData = this.dataSource[idx];
      } else {
        this.courseData = this.allData;
      }
      this.activeNav = idx;
    },
    notifyDetail(id) {
      this.$router.push({
        path: "/notifyDetail",
        query: {
          id: id,
        },
      });
    },
    goClass(item) {
      this.$store.commit("common/setCurrentSuject", {
        name: item.title,
        id: item.id,
      });
      this.$router.push({
        path: "/class",
        query: {
          name: item.title,
          id: item.id,
        },
      });
    },
    viewMore() {
      this.$router.push({
        path: "/moreHistory",
      });
    },
  },
};
</script>

<style lang="scss">
.my-swipe {
  .van-swipe__indicators {
    bottom: 0.36rem;
  }
  .van-swipe__indicator {
    width: 0.24rem;
    height: 0.04rem;
    border-radius: 0.02rem;
  }
}
</style>
<style lang="scss" scoped>
.nothing {
  height: 4rem;
  ::v-deep .empty-box {
    height: 100%;
  }
}
.course-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.55rem;
  li {
    position: relative;
    margin-right: 0.4rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #909090;
    transition: all 0.3s ease-in;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.15rem;
      height: 0.04rem;
      width: 0.24rem;
      content: "";
      background: #28b396;
      border-radius: 0 02rem;
      opacity: 0;
      transition: all 0.3s ease-in;
      transform: translateX(-50%);
    }
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      bottom: -0.05rem;
      height: 0.1rem;
      content: "";
      background: #fff;
    }
    &.active {
      position: relative;
      font-weight: 500;
      font-size: 0.28rem;
      color: #28b396;
      &::after {
        opacity: 1;
      }
    }
  }
}
.course-learn {
  padding: 0 0.28rem;
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.45rem;
    .header-left {
      display: flex;
      align-items: center;
      font-size: 0.32rem;
      font-weight: 500;
      color: #333;
      .iconfont {
        font-size: 0.42rem;
        color: #0bbda8;
        margin-right: 0.18rem;
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      color: #909090;
      font-weight: 400;
      .iconfont {
        font-size: 0.24rem;
        transform: rotate(180deg);
      }
    }
  }
}
.banner-box {
  padding: 0 0.28rem;
  margin-bottom: 0.1rem;
}
.notify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0.28rem;
  padding: 0 0.2rem;
  height: 0.72rem;
  margin-bottom: 0.47rem;
  background: #f8f8f8;
  border-radius: 4px;
  .notify-icon {
    width: 1.04rem;
    height: 0.34rem;
    margin-right: 0.22rem;
  }
  .notify-con {
    width: calc(100vw - 2rem);
  }
  .notify-swipe {
    width: 100%;
    height: 0.72rem;
  }
  .notify-text {
    font-size: 0.26rem;
    line-height: 0.72rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.main-nav {
  padding: 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  li {
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.27rem;
    padding: 0.1rem 0;
    &:active {
      background: rgba(0, 0, 0, 0.02);
    }
    .nav-icon {
      width: 0.98rem;
      height: 1.13rem;
    }
    .nav-text {
      font-size: 0.26rem;
    }
  }
}
.my-swipe {
  margin-bottom: 0.02rem;
  .van-swipe-item {
    height: 3.92rem;
  }

  .swiper-img {
    margin: 0.28rem;
    height: 3.38rem;
    overflow: hidden;
    .img {
      width: 100%;
      height: 3.38rem;
    }
  }
}
</style>