<template>
  <div class="class-page">
    <navBar>
      <span class="title">
        {{ currentTitle }}
      </span>
    </navBar>

    <custm-empty v-if="isEmpty"></custm-empty>
    <div class="page-content" v-else>
      <ul class="course-outline" v-if="dataList.length">
        <li
          v-for="(item, index) in dataList"
          :key="`a${index}`"
          @click="goNext(item.title, item.id)"
        >
          <van-image
            class="img"
            fit="cover"
            :src="setSourceUrl(item.navimage)"
          ></van-image>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
import custmEmpty from '@/components/custmEmpty.vue';
export default {
  components: { custmEmpty },
  name: "classChannle",
  data() {
    return {
      isEmpty: false,
    };
  },
  created() {},
  methods: {
    setSourceUrl,
    init() {
      this.$api
        .getNavClass({
          navid: this.currentId,
        })
        .then((res) => {
          let resData = res.data.data || [];
          if (resData.length) {
            this.setData(resData);
          }else{
            this.isEmpty=true
          }
        });
    },
    goNext(name, id) {
      let _path = "/bookContent";
      let _type = 1;
      if (name == "知识全解") {
        _path = "/zhishiquanjie";
        _type = 0;
      } else {
        this.$store.commit("common/setBookContent", []);
      }

      this.$router.push({
        path: _path,
        query: {
          name: name,
          id: id,
          navType: _type,
          class: this.$route.query.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.course-outline {
  padding: 0.28rem 0.28rem 0 0.28rem;

  li {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.3rem;
    .img {
      width: 100%;
      height: 2.84rem;
      border-radius: 0.1rem;
      overflow: hidden;
    }
  }
}
</style>