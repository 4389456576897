

// src/mixins/index.js
let mixin = {
  computed: {
    pageData: {
      get: function () {
        return this.$store.getters["common/getPageData"][this.$options.name];
      },
      set: function (data) {
        this.$store.commit("common/setPageData", data);
      },
    },
    pageId: {
      get: function () {
        return this.$store?.getters["common/getPageIds"][this.$options.name];
      },
      set: function (data) {
        this.$store.commit("common/setPageIds", data);
      },
    },
  },
  data() {
    return {
      currentTitle: '',
      currentId: '',
      dataList: [],
      mergeData: false,
      questionObject: []
    }
  },
  created() {
    this.currentTitle = this.$route?.query.name || this.$route?.query.title || '';
    this.currentId = this.$route?.query.id || this.defaultId;
    if (
      !this.pageId ||
      this.pageId != this.currentId
    ) {
      this.init();
    } else {
      this.dataList = this.pageData;
    }
  },
  methods: {
    init() { },
    setData(data) {
      this.pageData = {
        name: this.$options.name,
        data: data,
      };
      this.pageId = {
        name: this.$options.name,
        id: this.currentId,
      };
      this.dataList = data;
    },

    initQuestion() {
      this.$api.questionBase({ papeid: this.currentId }).then((res) => {
        if (res.data?.id) {
          this.getQuestionType(res.data.id);
        }
      });
    },
    getQuestionType(id) {
      this.$api.questionTitle({ papeinfoid: id, showToast: 1 }).then((res) => {
        let resData = res.data || [];
        for (let i = 0; i < resData.length; i++) {
          this.getQuestionContent(resData, i);
        }
      });
    },
    getQuestionContent(data, i) {
      this.$api
        .questionContent({ papetypeid: data[i].id, showToast: 1 })
        .then((res) => {
          let resData = res.data;
          resData.forEach((item, index) => {
            item.answerVal = '';
            item.answerIdx = '';
            item.questionIdx = [i, index];
            item.showParse = false;
            item.showAnswer = false;
            item.optionjson = this.computeOptionjson(item.optionjson, item.mubiaozi)
          })

          if (this.mergeData) {
            this.questionObject = [...this.questionObject, ...resData];
          } else {
            this.$set(this.questionObject, i, { title: data[i].title, list: resData })
          }
          this.$store.commit('common/setQuestionObject', this.questionObject)
        });
    },
    computeOptionjson(str, tag) {
      if (str) {
        let option = [];
        let optionjson = JSON.parse(str);
        if (tag && tag != '') {
          tag = tag.replace(/\s+/g, "")
          Object.keys(optionjson).forEach((item) => {
            let text = optionjson[item];
            for (let i = 0; i < tag.length; i++) {
              text = text.replace(tag[i], `<span>${tag[i]}</span>`)
            }
            option.push({
              text: text,
              value: item,
              status: 0,
            });
          });
        } else {
          Object.keys(optionjson).forEach((item) => {
            option.push({
              text: optionjson[item],
              value: item,
              status: 0,
            });
          });
        }

        return option;
      }
    },
  }
};
export default mixin;