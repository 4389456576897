<template>
  <div class="notify-page">
    <navBar>
      <span class="title">消息详情</span>
    </navBar>
    <div class="notify-wrapper">
      <div class="notify-box">
        <div class="notify-title">{{ notifyDetail.title }}</div>
        <div class="notify-authname">{{ notifyDetail.authname }}</div>
        <div class="notify-createtime">{{ notifyDetail.createtime }}</div>
        <div class="notify-content" v-html="notifyDetail.newscontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "changePhonePage",
  data() {
    return {
      notifyDetail: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.$api
        .getNotifyDetail({ newsid: this.$route.query.id })
        .then((res) => {
          this.notifyDetail = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .notify-page{
    height: 100vh;
    background: #fafafa;
  }
.notify-wrapper{
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  bottom: 0;
  padding: .3rem 0 .3rem .3rem;
  background: #fff;
  
  
}
.notify-box{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: .3rem;
  font-size: .24rem;
  .notify-title{
    padding: 0 .5rem;
    font-size: .32rem;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-bottom: .1rem;
  }
  .notify-authname, .notify-createtime{
    margin-bottom: .1rem;
    color: #909090;
    text-align: center;
  }
  .notify-content{
    padding-top: .5rem;
  }
}
</style>