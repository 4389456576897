<template>
  <div class="version-page">
    <navBar>
      <span class="title">版本信息</span>
    </navBar>
    <div class="page-content" v-if="!isLoading">
      <van-tabs
        v-model="activeCourse"
        v-if="versionData.length"
        class="version-tab"
        :swipeable="true"
      >
        <van-tab
          v-for="(course, index) in courseData"
          :title="course.title"
          :key="`tab${index}`"
        >
          <ul class="version-list">
            <li
              :class="{
                active: computeChecked(
                  versionData[index].bookversionid,
                  item.id
                ),
              }"
              v-for="(item, idx) in versionData[index].versionList"
              :key="`version${idx}`"
              @click="selectVersion(item.id)"
            >
              <span class="suctomCheck">
                <van-icon class="success" name="success" />
              </span>
              {{ item.bookversionname }}
            </li>
          </ul>
        </van-tab>
      </van-tabs>
      <p class="notify" v-if="versionData.length">每科最多选2个版本</p>
    </div>
    <div class="save-btn" @click="saveVersion">保存版本</div>
  </div>
</template>

<script>
export default {
  name: "versionPage",
  data() {
    return {
      activeCourse: 0,
      courseData: [],
      versionData: [],
      isLoading: true,
      requestAcount: 0,
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      this.$api.getClassNav().then((res) => {
        this.courseData = res.data || [];

        this.$api.getVersion().then((res) => {
          let resData = res.data || [];
          
          for (let i = 0; i < this.courseData.length; i++) {
            this.$api
              .getSelectVersion({
                coursetypeid: this.courseData[i].id,
              })
              .then((res) => {
                this.requestAcount++;
                let selectData = res.data || [];
                let selectVersion = [];
                for (let j = 0; j < selectData.length; j++) {
                  selectVersion[j] = selectData[j].id;
                }
                selectVersion = selectVersion.join();

                this.$set(this.versionData, i, {
                  coursetypeid: this.courseData[i].id,
                  versionList: JSON.parse(JSON.stringify(resData)),
                  bookversionid: selectVersion || resData[0].id,
                });
                if (this.requestAcount == this.courseData.length) {
                  this.isLoading = false;
                }
              });
          }
        });
      });
    },
    computeChecked(str, n) {
      let arr = str.split(',');
      if(arr.includes(String(n))){
        return true
      }else{
        return false
      }
    },
    selectVersion(id) {
      id = String(id);
      let bookversionid = this.versionData[this.activeCourse].bookversionid;
      bookversionid = String(bookversionid).split(",");

      let _idx = bookversionid.indexOf(id);
      if (-1 < _idx) {
        if (1 < bookversionid.length) {
          bookversionid.splice(_idx, 1);
        }
      } else {
        if (1 < bookversionid.length) {
          bookversionid.splice(0, 1);
        }
        bookversionid.push(id);
      }
      this.$set(
        this.versionData[this.activeCourse],
        "bookversionid",
        bookversionid.join()
      );
    },
    saveVersion() {
      let params = [];
      this.versionData.forEach((item, index) => {
        params[index] = {
          coursetypeid: item.coursetypeid,
          bookversionid: item.bookversionid,
        };
      });
      this.$api.saveCheckVersion(params).then(() => {
        this.$toast({
          type: "success",
          message: "保存成功！",
        });
      });
    },
  },
};
</script>

<style lang="scss">
.version-tab {
  .van-tabs__line {
    background-color: #36ba9e;
  }
}
</style>
<style lang="scss" scoped>
.notify {
  color: #ff3232;
  font-size: 0.24rem;
  line-height: 0.5rem;
  text-align: center;
  background: #fff;
}
.save-btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1rem;
  background: #28b295;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  color: #fff;
  &:active {
    opacity: 0.8;
  }
}
.page-content {
  padding-top: 1.2rem;
}
.version-list {
  height: calc(100vh - 3.55rem);
  background: #ffffff;
  margin-top: 0.05rem;
  padding-bottom: 1rem;
  overflow-y: auto;
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.88rem;
    padding: 0 0.32rem;
    border-bottom: 0.01rem #dcdcdc solid;
    font-size: 0.24rem;
    font-weight: 500;
    color: #333;
    line-height: 0.52rem;
    cursor: pointer;
    &:last-child {
      border: 0;
    }
    .suctomCheck {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0.24rem;
      height: 0.24rem;
      background: #ffffff;
      border: 0.02rem solid #dcdcdc;
      margin-right: 0.35rem;
      .success {
        opacity: 0;
      }
    }
  }
  .active {
    color: #28b295;
    .suctomCheck {
      border-color: #28b295;
      .success {
        transition: all 0.3s ease-in-out;
        opacity: 1;
      }
    }
  }
}
</style>