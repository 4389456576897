<template>
	<div class="landing-page">
		<navBar>
			<span>{{ currentTitle }}</span>
			<template slot="right" v-if="viewAllStatus">
				<span @click="viewAllStatus = false">返回</span>
			</template>
		</navBar>
		<div class="page-content-word">
			<div class="page-main">
				<div class="flex-box subnav-acount">
					<ul class="left sub-nav">
						<li :class="{ active: navIndex == 0 }" @click="navIndex = 0">
							<img :src="navImg33" />
							速记
						</li>
						<li :class="{ active: navIndex == 1 }" @click="navIndex = 1">
							<img :src="navImg22" />
							拼写
						</li>
						<li
							:class="{ active: navIndex == 2 }"
							@click="
								navIndex = 2;
								moudleSelect = true;
							"
						>
							<img :src="navImg11" />
							练习
						</li>
					</ul>
					<div class="right acount-box" v-if="totalNum">{{ Number(currentWord) + 1 }}/{{ totalNum }}</div>
				</div>
				<div class="main-con">
					<div class="inner-loading" v-if="loading">数据加载中...</div>
					<questionType
						v-if="!dataEmpty && !translateType"
						:questionData="wordsList[currentWord]"
						:dataLength="wordsList.length"
						:active="Number(currentWord)"
					></questionType>

					<div class="translate-question" v-if="!dataEmpty && translateType">
						<questionType
							:questionData="translateData[currentWord]"
							:dataLength="translateData.length"
							:active="Number(currentWord)"
						></questionType>
					</div>
					<div class="change-operate" v-if="translateType && !moudleSelect">
						<div class="btn"  @click="changeTranselate(0)">
							重新学习
						</div>
						<div class="btn" :class="{ disable: currentWord == 0 }" @click="changeTranselate(1)">
							上一题
						</div>
						<div class="btn" :class="{ disable: currentWord == totalNum - 1 }" @click="changeTranselate(2)">
							下一题
						</div>

						<div class="btn view-all" @click="viewAllWordsFunction">查看全部</div>
					</div>

					<ul class="select-moudle" v-if="moudleSelect">
						<li>
							<img :src="optionImg" alt="" />
							<span @click="selectMoudle(2)">英译汉</span>
						</li>
						<li>
							<img :src="optionImg" alt="" />
							<span @click="selectMoudle(1)">汉译英</span>
						</li>
					</ul>
				</div>

				<div class="view-all-layer" v-if="viewAllStatus">
					<div class="words-short-hand">
						<div class="follow-along-body">
							<ul class="follow-theader">
								<li class="td1">序号</li>
								<li class="td2">单词/音标</li>
								<li class="td3">翻译</li>
								<li class="td4">例句</li>
								<li class="td5">音频</li>
							</ul>
							<div class="follow-tbody">
								<ul class="tbody-list">
									<li class="tr" v-for="(item, index) in pageWordData" :key="`follow${index}`">
										<ol class="list-tr" id="list-tr">
											<li class="td1">
												{{ (currentPage - 1) * pageSize + index + 1 }}
											</li>
											<li class="td2">
												<div class="td2-box">
													<van-image
														fit="fill"
														v-if="item.image"
														:src="setSourceUrl(item.image)"
														class="follow-along-img"
													></van-image>
													<div class="follow-along-text">
														<p class="along-word">
															{{ item.testpapecontent }}
														</p>
														<p class="along-symbol">[{{ item.mubiaozi }}]</p>
													</div>
												</div>
											</li>
											<li class="td3">
												<div class="translate-box" v-html="item.answerminutes"></div>
											</li>
											<li class="td4">
												<div class="exaple-box" v-html="item.analysiscontent"></div>
												<div class="exaple-box" v-html="item.rightkey"></div>
											</li>
											<li class="td5">
												<template v-if="item.hearing">
													<div
														:style="`backgroundImage: url(${baofang2}) `"
														v-show="selectWord == index"
														class="sigin-play"
														@click="siginPlayAction(index, item.hearing)"
													></div>
													<div
														:style="`backgroundImage: url(${baofang1}) `"
														v-show="selectWord != index"
														class="sigin-play"
														@click="siginPlayAction(index, item.hearing)"
													></div>
												</template>
											</li>
										</ol>
									</li>
								</ul>
							</div>
						</div>
						<div class="pagination-box">
							<van-pagination
								v-model="currentPage"
								:total-items="totalNum"
								:items-per-page="10"
								@change="changePage"
							/>
						</div>
						<div class="audio-box">
							<audio id="audio" ref="hearAudio" :src="setSourceUrl(hearingSrc)" @ended="ended">
								您的浏览器不支持 audio 标签。
							</audio>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import questionType from './questionType.vue';
import { deepClone, setSourceUrl, computeOptionjson } from '@/utils/public';
export default {
	name: 'wordsBreakThrough',
	components: { questionType },
	watch: {
		navIndex: function (val) {
			let type = 12;
			let dataList = this.deepClone(this.questionSource);
			switch (val) {
				case 0: {
					this.currentWord = window.localStorage.getItem('currentWordSuji') || 0;
					this.moudleSelect = false;
					this.translateType = 0;
					if (this.wordsList && this.wordsList.length > 0) {
						this.totalNum = this.wordsList.length;
					}
					this.$nextTick(()=>{
						this.anniu();
					})
					type = 12;
					break;
				}
				case 1: {
					this.currentWord = window.localStorage.getItem('currentWordPinxie') || 0;
					this.moudleSelect = false;
					this.translateType = 0;
					if (this.wordsList && this.wordsList.length > 0) {
						this.totalNum = this.wordsList.length;
					}
					type = 13;
					break;
				}
				case 2: {
					this.currentWord = window.localStorage.getItem('currentWordLianxi2') || 0;
					this.moudleSelect = true;
					if (this.translateData && this.translateData.length > 0) {
						this.totalNum = this.translateData.length;
					} else {
						this.totalNum = 0;
					}
					type = 1;
					break;
				}
				default: {
					console.log(666);
				}
			}
			dataList.forEach((item) => {
				item.type = type;
			});

			this.wordsList = dataList;
		}
	},
	data() {
		return {
			loading: false,
			dataEmpty: false,
			moudleSelect: false,
			viewAllStatus: false,
			optionImg: require('@/assets/img/44.png'),
			navImg11: require('@/assets/img/11.png'),
			navImg22: require('@/assets/img/22.png'),
			navImg33: require('@/assets/img/33.png'),
			baofang1: require('@/assets/img/bofang.png'),
			baofang2: require('@/assets/img/bofang.gif'),

			navIndex: 0,
			questionSource: [],
			wordsList: [],
			totalNum: 0,
			currentWord: 0,
			pageLimit: 10000,
			translateType: 0, //0：其他；1：汉译英；2：英译汉
			translateData: [],
			pageWordData: [],
			pageSize: 10,
			currentPage: 1,
			selectWord: -1,
			hearingSrc: null,
			hearAudio: null
		};
	},
	created() {
		let wordsList = this.$store.getters['common/getWordsList'];

		this.currentWord = window.localStorage.getItem('currentWordSuji') || 0;
		if (!wordsList.length) {
			this.initWordBreakThrough();
		} else {
			this.wordsList = wordsList;
			this.questionSource = wordsList;
			this.totalNum = wordsList.length;
			this.$store.commit('common/setTotalQuestion', this.totalNum);
		}
	},
	mounted() {
		this.addHistory();
		this.$eventBus.$on('changeQuestion', () => {
			this.changeQuestion();
		});
		this.$eventBus.$on('unKnowWord', (val) => {
			this.currentWord = val;
			let dataList = this.wordsList;
			let unKuowObj = dataList[val];
			let unknowNum = ++unKuowObj.unknowNum;
			let step = 0;
			step = val + unknowNum + 1;
			if (dataList.length < step) {
				step = dataList.length;
			}
			unKuowObj.unknowNum = unknowNum;
			dataList.splice(val, 1);
			dataList.splice(step, 0, unKuowObj);
			this.wordsList = dataList;
		});
		this.$eventBus.$on('viewAllWords', () => {
			this.viewAllWordsFunction();
		});
		this.anniu();
	},
	destroyed() {
		this.$eventBus.$off('masterWord');
		this.$eventBus.$off('unKnowWord');
		this.$eventBus.$off('viewAllWords');
		this.setwordcord();
	},
	methods: {
		setSourceUrl,
		deepClone,
		computeOptionjson,
		addHistory() {
			this.$api
				.addLearnHistory({
					course_id: this.$route.query.courseId,
					coursechapters_id: this.$route.query.id,
					rateoflearning: '0',
					learning_info: this.$store.getters['common/getUnitNav'],
					active: this.$route.query.active,
					showToast: 1
				})
				.then();
		},
		ended() {
			this.selectWord = -1;
		},
		siginPlayAction(n, src) {
			this.selectWord = n;
			this.hearingSrc = src;
			this.$nextTick(() => {
				this.hearAudio.play();
			});
		},
		changePage(val) {
			this.currentPage = val;
			this.pageWordData = this.wordsList.slice((this.currentPage - 1) * this.pageSize, val * this.pageSize);
			this.$nextTick(() => {
				this.$eventBus.$emit('refreshScroll', { dom: 'scrollFollowAlong' });
				this.$eventBus.$emit('backTop', { dom: 'scrollFollowAlong' });
			});
		},
		changeSize(val) {
			this.pageSize = val;
			this.currentPage = 1;
			this.pageWordData = this.wordsList.slice(0, val);
			this.$nextTick(() => {
				this.$eventBus.$emit('refreshScroll', { dom: 'scrollFollowAlong' });
				this.$eventBus.$emit('backTop', { dom: 'scrollFollowAlong' });
			});
		},
		viewAllWordsFunction() {
			this.viewAllStatus = true;
			this.pageWordData = this.wordsList.slice(0, 10);
			this.$nextTick(() => {
				this.hearAudio = this.$refs['hearAudio'];
				this.$eventBus.$emit('backTop', { dom: 'scrollFollowAlong' });
			});
		},
		changeTranselate(n) {
			if (n == 1) {
				if (0 < this.currentWord) {
					this.currentWord = --this.currentWord;
				} else {
					this.currentWord = 0;
					this.$notify({
						type: 'warning',
						message: '已经是第一题了！'
					});
				}
			}
			if (n == 2) {
				if (this.currentWord < this.totalNum - 1) {
					this.currentWord = ++this.currentWord;
				} else {
					this.currentWord = this.totalNum - 1;
					this.$notify({
						type: 'warning',
						message: '已经是最后一题了！'
					});
				}
			}
			if(n == 0){
				window.localStorage.setItem(`currentWordLianxi${this.translateType}`, 0);
				this.currentWord = 0;
			}else{
				window.localStorage.setItem(`currentWordLianxi${this.translateType}`, this.currentWord);
			}
		},
		selectMoudle(n) {
			this.translateType = n;
			this.moudleSelect = false;
			this.currentWord = window.localStorage.getItem(`currentWordLianxi${n}`) || 0;
			let storeData = this.$store.getters[`common/getLianxiData${n}`];
			if (storeData.length) {
				this.translateData = storeData;
				this.totalNum = storeData.length;
			} else {
				this.initWordBreakThroughTest(n);
			}
		},
		initWordBreakThroughTest(n) {
			this.loading = true;
			this.$api
				.getWordBreakThroughTest({
					page: 1,
					chineseenglish: n,
					limit: 10000,
					showToast: 1
				})
				.then((res) => {
					let resData = res.data?.data;
					this.loading = false;
					if (resData?.length) {
						resData.forEach((item) => {
							item.optionjson = this.computeOptionjson(item.optionjson);
						});
					}
					this.translateData = resData;
					this.totalNum = resData.length;
					this.$store.commit(`common/setLianxiData${n}`, resData);
				});
		},
		initWordBreakThrough() {
			this.loading = true;
			this.$api
				.getWordBreakThrough({
					showToast: 1,
					page: 1,
					limit: this.pageLimit
				})
				.then((res) => {
					let resData = res.data;
					this.loading = false;
					this.totalNum = res.data.total;
					this.$store.commit('common/setTotalQuestion', this.totalNum);
					if (resData.data?.length) {
						let dataList = resData.data;
						dataList.forEach((item) => {
							item.type = 12;
							item.unknowNum = 0;
						});
						this.wordsList = dataList;
						this.questionSource = [...this.questionSource, ...dataList];
						this.$store.commit('common/setWordsList', this.questionSource);
						this.getuserwordnum();
					} else {
						this.dataEmpty = true;
					}
					console.log(res, 'ressdfsdfsdf');
				});
		},
		setwordcord() {
			// 1:速记 2：拼写3：英译汉4：汉译英
			let apiData = {
				data: [
					{
						type: 1,
						num: window.localStorage.getItem('currentWordSuji') || 0
					},
					{
						type: 2,
						num: window.localStorage.getItem('currentWordPinxie') || 0
					},
					{
						type: 3,
						num: window.localStorage.getItem('currentWordLianxi2') || 0
					},
					{
						type: 4,
						num: window.localStorage.getItem('currentWordLianxi1') || 0
					}
				]
			};
			this.$api.setwordcord(apiData).then((res) => {
				console.log(res);
			});
		},
		getuserwordnum() {
			this.$api.getuserwordnum().then((res) => {
				if (res.data.length > 0) {
					for (let i = 0; i < res.data.length; i++) {
						let item = res.data[i];
						if (item.type == 1) {
							window.localStorage.setItem('currentWordSuji', item.num);
							this.currentWord = item.num;
						}
						if (item.type == 2) {
							window.localStorage.setItem('currentWordPinxie', item.num);
						}
						if (item.type == 3) {
							window.localStorage.setItem('currentWordLianxi2', item.num);
						}
						if (item.type == 4) {
							window.localStorage.setItem('currentWordLianxi1', item.num);
						}
					}
				}
			});
		},
		anniu() {
			// 获取元素
			var element = document.getElementById('cxxx');
			let _this = this;
			// 绑定事件
			element.addEventListener('click', function () {
				// 点击事件触发后执行的代码
				console.log(111);
				window.localStorage.setItem('currentWordSuji', 0);
				_this.currentWord = 0;
			});
		},
		changeQuestion() {
			let len = this.wordsList.length;
			if (this.currentWord < len - 1) {
				this.currentWord++;
				if (this.navIndex == 0) {
					if(this.$route.query.name == "速记"){
						window.localStorage.setItem('kewencurrentWordSuji', this.currentWord);
					}else{
						window.localStorage.setItem('currentWordSuji', this.currentWord);
					}
				}
				if (this.navIndex == 1) {
					window.localStorage.setItem('currentWordPinxie', this.currentWord);
				}
			} else {
				this.$notify({
					type: 'warning',
					message: '已经是最后个单词了！'
				});
				return false;
			}
		},
		goBack() {
			if (this.viewAllStatus) {
				this.viewAllStatus = false;
			} else {
				this.$router.go(-1);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.inner-loading {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.24rem;
}
.pagination-box {
	padding-top: 0.5rem;
	display: flex;
	justify-content: center;

	::v-deep .van-pagination__item {
		color: #009b7c;
	}
	::v-deep .van-pagination__item--active {
		background: #009b7c;
		color: #fff;
	}
	::v-deep .van-pagination__item:active {
		color: #fff;
	}
}
.view-all-layer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
	padding: 0.3rem;
	border-radius: 0.3rem;
}
.words-short-hand {
	position: relative;
	height: calc(100% - 0.3rem);
	border-radius: 0.1rem;

	.follow-along-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.18rem;
		margin-top: -0.12rem;
		.follow-along-play {
			width: 0.58rem;
			height: 0.58rem;
			background: #1e9e85;
			border-radius: 50%;
			line-height: 0;
			padding: 0;
			margin: 0;
			overflow: hidden;
			.play-icon-img {
				width: 0.58rem;
				height: 0.58rem;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.follow-along-body {
		width: 100%;
		border: 1px #dcdcdc solid;
		overflow: auto hidden;
		.follow-theader {
			display: flex;
			height: 0.86rem;
			font-size: 0.24rem;
			li {
				line-height: 0.86rem;

				border-bottom: 1px #dcdcdc solid;
				background: #fafafa;
			}
		}
		li {
			border-left: 1px #dcdcdc solid;
		}
		.follow-tbody {
			width: 14rem;
			position: relative;
			height: calc(100vh - 4.25rem);
			overflow: hidden auto;
			.data-loading {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 100;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.5rem;
				color: #1e9e85;
				background: rgba(255, 255, 255, 0.8);
			}
		}
		.tbody-list {
			margin-right: -0.3rem;
			font-size: 0.24rem;
			font-weight: 400;
			.tr {
				border-left: 0;
				&:last-child {
					border: 0;
				}
				&:hover {
					opacity: 0.8;
				}
			}
			.currentRow {
				background: #f2faf8;
			}
			.list-tr {
				display: flex;
				width: 100%;
				li {
					border-bottom: 1px #dcdcdc solid;
					padding: 0.24rem 0;
				}
				.sigin-play {
					width: 0.32rem;
					height: 0.32rem;
					background-size: cover;
				}
				.td2-box {
					position: relative;
					display: flex;
					align-items: center;
					padding: 0 0.4rem;
					.follow-along-img {
						width: 1rem;
						height: 0.75rem;
						margin-right: 0.3rem;
					}
					.follow-along-text {
						.along-word {
							font-size: 0.24rem;
							font-weight: 500;
							line-height: 1;
							margin-bottom: 0.1rem;
						}
						.hideEnglish {
							opacity: 0;
						}
						.along-symbol {
							font-size: 0.18rem;
							line-height: 1;
						}
					}
					.word-scode {
						position: absolute;
						right: 0.4rem;
						top: 50%;
						transform: translateY(-50%);
					}
					.word-scode.upper-limit {
						color: #1e9e85;
					}
					.word-scode.down-limit {
						color: #ff6d6d;
					}
				}
				.translate-box {
					height: 100%;
					padding: 0 0.4rem;
					display: flex;
					align-items: center;
				}
				.exaple-box {
					line-height: 1.6;
					padding: 0 0.4rem;
				}
			}
		}
		.td1 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1rem;
			min-width: 1rem;
			max-width: 1rem;
			border-left: 0;
			text-align: center;
		}
		.td2 {
			display: flex;
			align-items: center;
			width: 4rem;
			min-width: 4rem;
			max-width: 4rem;
			padding: 0 0.2rem;
			text-align: left;
		}

		.td3 {
			width: 4rem;
			min-width: 4rem;
			max-width: 4rem;
			padding: 0 0.4rem;
			text-align: left;
		}
		.td4 {
			width: 4rem;
			min-width: 4rem;
			max-width: 4rem;
			padding: 0 0.4rem;
			text-align: left;
		}
		.td5 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1rem;
			min-width: 1rem;
			max-width: 1rem;
		}
	}
	.change-moudle {
		display: flex;
		.moudle-switch {
			display: flex;
			align-items: center;
			margin-right: 0.47rem;
			font-size: 0.2rem;
			font-weight: 400;
			span {
				padding-left: 0.1rem;
			}
		}
	}
}
.change-operate {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.btn {
		width: 2rem;
		height: 0.64rem;
		background: #e2f5f1;
		border-radius: 0.32rem;
		font-size: 0.24rem;
		font-weight: 400;
		color: #333333;
		line-height: 0.64rem;
		text-align: center;
		margin-left: 0.32rem;
		padding: 0;
		border: 0;
		&:hover {
			opacity: 0.8;
		}
	}
	.view-all {
		color: #fff;
		background: #009b7c;
	}
	.btn.disable {
		background: #dcdcdc;
		&:hover {
			opacity: 1;
		}
	}
}
.translate-question {
	height: calc(100% - 0.9rem);
	width: 100%;
	border-radius: 0.1rem;
	border: 0.01rem #dcdcdc solid;
	margin-bottom: 0.3rem;
	padding: 0.3rem;
}
.select-moudle {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
	border-radius: 0.1rem;
	border: 0.01rem #dcdcdc solid;
	display: flex;
	align-items: center;
	justify-content: center;
	li {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		margin: 0 0.5rem;
		img {
			width: 2.17rem;
			height: 2.125rem;
		}
		span {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -0.17rem;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1.235rem;
			height: 0.34rem;
			background: #ffa632;
			box-shadow: 0rem 0.02rem 0.09rem 0rem rgb(234 148 36 / 44%);
			border-radius: 0.17rem;
			font-size: 0.24rem;
			color: #fff;
			font-weight: 500;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
.empty-box {
	width: 100%;
	height: 100%;
}
.landing-page {
	width: 100%;
	height: 100vh;
	background-color: #fafafa;
	.acount-box {
		width: 1.78rem;
		height: 0.48rem;
		background: #fff;
		border: 0.02rem solid #1e9e85;
		border-radius: 0.24rem;
		font-size: 0.24rem;
		font-weight: 400;
		color: #1e9e85;
		line-height: 0.48rem;
		text-align: center;
	}
	.sub-nav {
		display: flex;
		align-items: center;
		font-size: 0.24rem;
		color: #333;
		font-weight: 400;
		word-break: keep-all;
		white-space: nowrap;
		li {
			display: flex;
			align-items: center;
			padding-right: 1rem;
			img {
				width: 0.24rem;
				height: 0.24rem;
				margin-right: 0.1rem;
			}
		}
		.active {
			color: #20a287;
		}
	}
	.subnav-acount {
		height: 0.48rem;
		margin-bottom: 0.3rem;
	}
	.flex-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.page-content-word {
		display: flex;
		flex-direction: column;
		background: #fafafa;
		position: fixed;
		left: 0;
		right: 0;
		top: 1.28rem;
		bottom: 0;
		.page-main {
			position: relative;
			display: flex;
			flex-direction: column;
			flex: 1;
			width: 100%;
			height: 100%;
			background: #fff;
			background: #ffffff;
			padding: 0.3rem;
			.main-con {
				position: relative;
				width: 100%;
				flex: 1;
				.inner-loading {
					position: absolute;
					left: 0.01rem;
					right: 0.01rem;
					top: 0.01rem;
					bottom: 0.91rem;
					z-index: 100;
					background: #fff;
					border-radius: 0.1rem;
				}
			}
		}
		.page-menu {
			display: flex;
			align-items: center;
			height: 0.46rem;
			li {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 0.64rem;
				padding: 0 0.3rem;
				background: #ffa632;
				color: #fff;
				border-radius: 0.32rem;
				font-size: 0.26rem;
				font-weight: 400;
				word-break: keep-all;
				white-space: nowrap;
				margin-right: 0.3rem;
				cursor: default;
			}
		}
	}
}
</style>
