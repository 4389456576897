<template>
  <div class="inner-page">
    <navBar>
      <span class="title">{{ currentTitle }}</span>
    </navBar>
    <div v-if="bookObj.content">
      <div class="read-type">
        <customTabNav
          :tabList="tabList"
          :selectTab="currentTab"
          @change="tabChange"
        ></customTabNav>
      </div>
      <div class="class-content">
        <div class="content">
          <div class="text-title" v-html="bookObj.name"></div>
          <van-image
            class="text-post"
            fit="cover"
            :src="setSourceUrl(bookObj.cover)"
          ></van-image>
          <p v-for="(item, index) in content" :key="`a${index}`">
            <span
              :class="{
                active: item1.status,
                recite: item1.recite,
                recited: !item1.isRecite && item1.recite,
              }"
              v-for="(item1, index1) in item"
              :key="`b${index1}`"
              @click="selectWords(index, index1, item1.text)"
            >
              {{ item1.text }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="audio-box" v-if="currentTab < 2 && bookObj.content">
      <audioPlayer
        :audioSrc="audioSrc"
        @startPlay="startPlay"
        @playEnd="playEnd"
      ></audioPlayer>
      <div class="readall-btn" @click="readAll" v-if="isReadAll"></div>
    </div>
  </div>
</template>

<script>
import audioPlayer from "@/components/audioPlayer.vue";
import customTabNav from "@/components/customTabNav.vue";
import { setSourceUrl, getRandom } from "@/utils/public";
export default {
  name: "textTeading",
  components: {
    audioPlayer,
    customTabNav,
  },
  data() {
    return {
      isReadAll: true,
      playAudio: false,
      reciteActive: 0,
      index: 0,
      index1: 0,
      index2: 0,
      index3: 0,
      readIndex: 0,
      currentTab: 0,
      audioSrc: "",
      nextSrc: "",
      content: [],
      bdttscont: [],
      bookObj: {
        cover: "",
        name: "",
        preview: "",
        content: "",
      },
      tabList: [
        {
          name: 0,
          text: "名家朗读",
        },
        {
          name: 1,
          text: "单句跟读",
        },
        {
          name: 2,
          text: "背诵练习",
        },
      ],
      reciteMoudle: [
        {
          id: 4,
          text: "正常模式",
        },
        {
          id: 7,
          text: "中等模式",
        },
        {
          id: 10,
          text: "复杂模式",
        },
      ],
    };
  },
  created() {},
  methods: {
    setSourceUrl,
    getRandom,
    init() {
      this.getCourseReadContent();
    },
    getCourseReadContent() {
      this.$api
        .getCourseReadContent({
          mjldid: this.$route.query.id,
        })
        .then((res) => {
          if (res.data.content) {
            this.bookObj.content = res.data?.content || "";
            this.bookObj.name = res.data?.toptitle || "";
            this.bookObj.preview = res.data?.topyuxi || "";
            this.bookObj.cover = res.data?.topimg || "";
            this.bdttscont = res.data?.bdttscont || [];
            this.content = this.computeContent(res.data?.content);
          }
        });
    },

    readAll() {
      if (this.currentTab == 0) {
        this.isReadAll = false;
        this.index = 0;
        this.index1 = 0;
        this.getAudio(0, 0);
      }
    },
    playEnd() {
      if (this.currentTab == 0) {
        this.audioSrc = this.nextSrc;
        this.setSelect(this.index2, this.index3);
      }
    },
    startPlay() {
      if (this.currentTab == 0) {
        let idx = 0;
        let idx1 = 0;
        let text = this.content[this.index][Number(this.index1 + 1)]?.text;

        if (text) {
          idx = this.index;
          idx1 = Number(this.index1 + 1);
        } else {
          idx = Number(this.index + 1);
          idx1 = 0;
        }
        this.setSelect(this.index, this.index1);
        this.index2 = idx;
        this.index3 = idx1;
        let _text = this.content[idx][idx1]?.text.replace(/ /g, "");
        this.$api
          .baiduVoice({
            coursechaptersid: Number(this.$route.query.id),
            tex: _text,
            showToast: 1,
          })
          .then((res) => {
            this.nextSrc = res.data?.realpath || "";
          });
      }
    },
    selectWords(idx, idx1) {
      if (this.currentTab == 0) {
        this.currentTab = 1;
      }

      this.getAudio(idx, idx1);
    },
    getAudio(idx, idx1) {
      let _text = this.content[idx][idx1].text.replace(/ /g, "");
      this.audioSrc = "";
      this.setSelect(idx, idx1);

      this.$api
        .baiduVoice({
          coursechaptersid: Number(this.$route.query.id),
          tex: _text,
          showToast: 1,
        })
        .then((res) => {
          this.audioSrc = res.data?.realpath || "";
        });
    },
    setSelect(idx, idx1) {
      if (this.currentTab != 2) {
        this.resetStatus();
        if (this.content[idx][idx1]) {
          this.$set(this.content[idx][idx1], "status", true);
        }

        this.index = idx;
        this.index1 = idx1;
      }
      if (this.currentTab == 2) {
        this.$set(this.content[idx][idx1], "isRecite", false);
      }
    },
    resetStatus() {
      this.content.forEach((item, index) => {
        item.forEach((opt, index2) => {
          this.$set(this.content[index][index2], "status", false);
        });
      });
    },
    tabChange(val) {
      let contentString = this.bookObj.content;
      this.currentTab = val;

      this.audioSrc = "";
      switch (val) {
        case 0: {
          this.readIndex = 0;
          this.isReadAll = true;
          this.content = this.computeContent(contentString);
          break;
        }
        case 1: {
          this.isReadAll = false;
          this.content = this.computeContent(contentString);
          break;
        }
        case 2: {
          this.resetStatus();
          this.content = this.computeContent(contentString, 2);
          break;
        }
        default:
          break;
      }
    },
    computeContent(str, type = 1) {
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      let idxArr = [];
      str = str.replace(/<div/g, "<p");
      str = str.replace(/<\/div>/g, "</p>");
      arr1 = str.split("</p>");
      for (let i = 0; i < arr1.length - 1; i++) {
        let _s = arr1[i].replace(/<\/?.+?>/g, "").replace(/ /g, "");
        arr.push(_s);
      }
      for (let i = 0; i < arr.length; i++) {
        let _arr = [];
        for (let j = 0; j < this.bdttscont.length; j++) {
          let n = 0;
          let targetStr = this.bdttscont[j].replace(/ /g, "");
          if (arr[i].indexOf(targetStr) != -1) {
            _arr.push({
              text: targetStr,
              status: false,
              recite: false,
              isRecite: false,
            });
            n++;
          }
          idxArr.push([i, n]);
        }
        arr2.push(_arr);
      }
      if (type == 2) {
        let ran = -1;
        for (let i = 0; i < 3; i++) {
          ran = this.getRandom(0, idxArr.length, ran);
          let item = idxArr[ran];
          if (arr2[item[0]].length) {
            if (arr2[item[0]][item[1]]) {
              arr2[item[0]][item[1]].recite = true;
              arr2[item[0]][item[1]].isRecite = true;
            }
          }
        }
      }

      return arr2;
    },
  },
};
</script>


<style lang="scss" scoped>
.text-post {
  padding-bottom: 0.3rem;
}
.text-title {
  margin-bottom: 0.3rem;
}
.recite-moudle {
  position: fixed;
  left: 0.29rem;
  right: 0.3rem;
  top: 2.28rem;
  z-index: 101;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.6rem;
  li {
    font-weight: 400;
    color: #909090;
    font-size: 0.26rem;
    padding: 0.2rem 0;
  }
  .active {
    position: relative;
    color: #28b295;
    &::after {
      position: absolute;
      left: 50%;
      bottom: 0.1rem;
      content: "";
      width: 0.5rem;
      height: 0.03rem;
      background: #28b295;
      transform: translateX(-50%);
    }
  }
}
.class-content {
  position: fixed;
  top: 2.47rem;
  bottom: 1.76rem;
  left: 0.28rem;
  right: 0.28rem;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: 0.1rem;
  &.beisong {
    bottom: 0.28rem;
    padding-top: 0.8rem;
  }
  .class-base {
    margin-bottom: 0.28rem;
    display: flex;
    justify-content: flex-start;
    .base-img {
      min-width: 2.1rem;
      width: 2.1rem;
      height: 2.5rem;
      margin-right: 0.4rem;
      border-radius: 0 0.3rem 0.3rem 0;
      overflow: hidden;
    }
    .infs {
      flex: 1;
      font-size: 0.24rem;
      .name-author {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-bottom: 0.2rem;
        .name {
          font-size: 0.35rem;
          font-weight: 600;
          color: #333;
          margin-right: 0.1rem;
        }
        .author {
          font-size: 0.24rem;
          font-weight: 400;
          color: #333;
        }
      }
      .preview {
        width: 100%;
        padding: 0.15rem 0;
        border-top: 0.02rem dashed #28b295;
        border-bottom: 0.02rem dashed #28b295;
        p {
          font-size: 0.24rem;
          color: #28b295;
          font-weight: 400;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
  .content {
    font-size: 0.26rem;
    text-indent: 0.48rem;
    line-height: 1.6;
    color: #1c1c1c;
    .active {
      color: #2db79a;
    }
    .recite {
      position: relative;
      border-bottom: 0.01rem #909090 solid;
      color: #fff;
    }
    .recited {
      border-bottom: 0.01rem #909090 solid;
      color: #2db79a;
    }
  }
}
.read-type {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.08rem;
  z-index: 100;
  padding: 0.28rem 0.28rem 0 0.28rem;
  display: flex;
  background: #fff;
  border-top: 0.16rem #f7f8fa solid;
}
.audio-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 0.28rem;
  background: #fff;
  .readall-btn {
    position: absolute;
    left: 0.28rem;
    top: 0.28rem;
    width: 1rem;
    height: 1rem;
    z-index: 100;
  }
}
</style>