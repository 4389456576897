import https from '../http';
const library = {
  /* 获得图书馆顶级导航 */
  getLibraryNav: function (params) {
    return https.post('/library/get_library_nav', params)
  },
  /*  获得图书馆对应导航ID下的图书  */
  getLibraryBook: function (params) {
    return https.post('/library/get_library_tushu', params)
  },

  /*  获得图书的章节  */
  getBookChapters: function (params) {
    return https.post('/library/get_tushu_chapters', params)
  },

  /*  获得图书的章节内容  */
  getBookChapterContent: function (params) {
    return https.post('/library/get_tushuchapter_content', params)
  },
  /*  添加阅读数量  */
  addViewNum: function (params) {
    return https.post('/library/add_library_num', params)
  },

  /*  添加最近于都章节  */
  addRecentBooks: function (params) {
    return https.post('/library/add_recentbooks', params)
  },
  

};

export default library
