<template>
	<div class="keyboardYY" v-show="showKeyboardvalue" @click.stop="showKeyboardvalue = true">
		<div class="keylistbox">
			<div
				class="keyLi key-style"
				v-for="(w, index) in keyInfoData[0]"
				:key="`w${index}`"
				@click.stop="changeWrod(w)"
			>
				{{ w }}
			</div>
		</div>
		<div class="keylistbox">
			<div
				class="keyLi key-style"
				v-for="(w, index) in keyInfoData[1]"
				:key="`w${index}`"
				@click.stop="changeWrod(w)"
			>
				{{ w }}
			</div>
		</div>
		<div class="listbox3">
			<div class="key-style keyQh" :class="{ DXKey: isKeyDX }" @click.stop="DXkey">
				<span v-if="isKeyNum">{{ isQhNum ? '123' : '#+=' }}</span>
				<img v-else :src="`${isKeyDX ? iconS : iconSK}`" width="23px" alt="" />
			</div>
			<div class="keylistbox">
				<div
					class="keyLi key-style"
					:class="{ keyLi3: isKeyNum }"
					v-for="(w, index) in keyInfoData[2]"
					:key="`w${index}`"
					@click.stop="changeWrod(w)"
				>
					{{ w }}
				</div>
			</div>
			<div class="key-style deleteKey" @click.stop="deleteKey"></div>
		</div>
		<div class="keylistbox">
			<div class="key-style keyYH" @click.stop="YHkey">{{ isKeyNum ? 'ABC' : '123' }}</div>
			<div class="key-style keyXl"></div>
			<div class="key-style space" @click.stop="changeWrod(' ')" style="">space</div>
			<div class="key-style offKey" @click.stop="offKey" :class="{ keyReturn: keyData.length }">return</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'keyBoard',
	model: {
		prop: 'keyBoardData', // 默认是value
		event: 'myInput' // 默认是input
	},
	props: {
		keyBoardData: [String, Array, Number],
		showKeyboard: {
			type: [Boolean, Number],
			default: false
		},
		keyMax: {
			type: [String, Number],
			default: 1
		}
	},
	watch: {
		keyBoardData: function (val) {
			this.keyData = val;
		},showKeyboard: function (val) {
			this.showKeyboardvalue = val;
		}
	},
	data() {
		return {
			keyData: [],
			isKeyboard: false,
			isKeyNum: false,
			isKeyDX: false,
			isQhNum: '',
			showKeyboardvalue:this.showKeyboard,
			keyInfoData: [
				['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
				['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
				['z', 'x', 'c', 'v', 'b', 'n', 'm']
			],
			keyMoData: {
				xiao: [
					['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
					['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
					['z', 'x', 'c', 'v', 'b', 'n', 'm']
				],
				da: [
					['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
					['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
					['Z', 'X', 'C', 'V', 'B', 'N', 'M']
				],
				num: [
					[1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
					['-', '/', ':', ';', '(', ')', '$', '&', '@', '"'],
					['.', ',', '?', '!', "'"]
				],
				fu: [
					['[', ']', '{', '}', '#', '%', '^', '*', '+', '='],
					['_', '\\', '|', '~', '<', '>', '€', '£', '¥', '•'],
					['.', ',', '?', '!', "'"]
				]
			},
			iconDel: require('@/assets/icon/icon-del.png'),
			iconDelK: require('@/assets/icon/icon-del-k.png'),
			iconS: require('@/assets/icon/icon-s.png'),
			iconSK: require('@/assets/icon/icon-s-k.png')
		};
	},
	created() {},
	methods: {
		changeWrod(val) {
			if (val == '　') {
				val = ' ';
			}
			if (this.keyData.length < this.keyMax) {
				this.keyData.push(val);
				this.$emit('myInput', this.keyData);
			}
		},
		// 删除
		deleteKey() {
			if (this.keyData.length) {
				this.keyData.splice(this.keyData.length - 1, 1);
				this.$emit('myInput', this.keyData);
			}
		},
		// 输入完成rentru时关闭
		offKey() {
			if (this.keyData.length) {
				this.$parent.showKeyboard = false;
				this.$emit('myInput', this.keyData);
			}
		},
		// 英数字切换
		YHkey() {
			this.isKeyNum = !this.isKeyNum;
			this.isKeyDX = false;
			this.isQhNum = false;
			this.keyInfoData = this.isKeyNum ? this.keyMoData.num : this.keyMoData.xiao;
		}, // 大小写切换
		DXkey() {
			if (!this.isKeyNum) {
				this.isKeyDX = !this.isKeyDX;
				this.keyInfoData = this.isKeyDX ? this.keyMoData.da : this.keyMoData.xiao;
			} else {
				this.isQhNum = !this.isQhNum;
				this.keyInfoData = this.isQhNum ? this.keyMoData.fu : this.keyMoData.num;
			}
		}
	}
};
</script>

<style lang="scss">
.keyboardYY {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	width: 100%;
	height: auto;
	padding: 15px 0;
	background-color: #d2d3d8;
	.key-style {
		border-radius: 5px;
		// border: 1px solid #d9d9d9;
		box-shadow: 0px 1px 1px #888888;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.listbox3 {
		display: flex;

		justify-content: space-between;
		padding: 0 5px;
		.keyQh {
			height: 45px;
			width: 44px;
			font-size: 16px;
			background-color: #acb0bc;
			// margin-right: 12px;
		}
		.DXKey {
			background-color: #fff;
		}
		.deleteKey {
			height: 45px;
			width: 46px;
			font-size: 24px;
			background-color: #acb0bc;
			// margin-left: 12px;
			background-image: url('../assets/icon/icon-del-k.png');
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
			&:active {
				background-color: #fff;
				background-image: url('../assets/icon/icon-del.png');
			}
		}
	}
	.keylistbox {
		// width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 11px;
		.keyGD {
			background-color: #acb0bc;
			width: 48px;
			height: 45px;
			font-size: 24px;
		}
		.keyLi {
			background-color: #fff;
			width: 36px;
			height: 45px;
			font-size: 24px;
			margin: 0px 3px;
			&:active {
				background-color: #007aff;
				color: #fff;
			}
		}
		.keyLi3 {
			width: 52px;
		}
		.keyYH {
			height: 45px;
			width: 46px;
			font-size: 16px;
			background-color: #acb0bc;
			margin: 0 3px;
		}
		.keyXl {
			height: 45px;
			width: 46px;
			font-size: 24px;
			background-color: #acb0bc;
			margin: 0 3px;
			background-image: url('../assets/icon/icon-xl.png');
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
		}
		.space {
			height: 45px;
			width: 210px;
			font-size: 24px;
			background-color: #fff;
			margin: 0 3px;
			&:active {
				background-color: #acb0bc;
			}
		}
		.offKey {
			height: 45px;
			width: 100px;
			font-size: 24px;
			background-color: #acb0bc;
			margin: 0 3px;
		}
		.keyReturn {
			background-color: #007aff;
			color: #fff;
		}
	}
}
</style>
