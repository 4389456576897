<template>
  <div class="question-box question-common">
    <div
      class="question-content"
      v-if="privateData.content"
      v-html="privateData.content"
    ></div>
    <div class="question-test" v-html="privateData.testpapecontent"></div>
    <div class="answer-input">
      <van-field
        v-model="privateData.answerVal"
        rows="5"
        type="textarea"
        placeholder="请输入您的答案"
      />
    </div>
    <div class="result-box" v-if="privateData.showParse">
      <div class="box-item">
        <span class="tit">【正确答案】</span>
        <span class="con" v-html="privateData.rightkey"></span>
      </div>

      <div class="box-item">
        <span class="tit">【您的答案】</span>
        <span class="con">{{ privateData.answerVal || "未作答" }}</span>
      </div>

      <div class="box-item">
        <span class="tit">【答案解析】</span>
        <span
          class="con"
          v-html="privateData.analysiscontent"
          v-if="privateData.analysiscontent"
        ></span>
        <span v-else>暂无</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionType6",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      privateData: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.result-box {
  font-size: 0.26rem;
  line-height: 1.6;
  .box-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.1rem;
    .tit {
      min-width: 1.8rem;
    }
    .con {
      flex: 1;
      word-break: break-all;
      overflow: auto;
      ::v-deep * {
        color: initial !important;
        font-family: initial !important;
        .math-formula {
          line-height: 1 !important;

          font-family: MathJax_Main !important;
          * {
          font-family: MathJax_Main !important;
            line-height: 1 !important;
          }
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
  }
}
.question-content {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px #dcdcdc dashed;
  ::v-deep img {
    max-width: 100%;
  }
}
.question-test {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  ::v-deep .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
.answer-input {
  min-height: 3rem;
  border: 0.01rem #dcdcdc solid;
  border-radius: 0.1rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
</style>