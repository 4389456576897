import https from '../http';
const question = {
    /*题目基础信息*/
    questionBase: function (params) {
        return https.post('/testpape/get_testpapeinfo', params)
    },
    /*题目分类标题*/
    questionTitle: function (params) {
        return https.post('/testpape/get_testpapeinfo_pape', params)
    },
    /* 题目内容*/
    questionContent: function (params) {
        return https.post('/testpape/get_testpapertype_data', params)
    },

    /* 查看科目错题列表*/
    checkErrorList: function (params) {
        return https.get('/mytopicbook/get_papeid_info', params)
    },
    /* 查看科目错题*/
    errorQuestionContent: function (params) {
        return https.get('/mytopicbook/index', params)
    },

    /* 添加错题*/
    addErrorQuestion: function (params) {
        return https.post('/mytopicbook/add_topicbook', params)
    },

    /* 回答问题*/
    answerQuestion: function (params) {
        return https.post('/testpape/add_user_pape', params)
    },

    
    

};

export default question