<template>
	<div class="word-shorthand" @click="showKeyboard = false">
		<div class="short-hand">
			<div class="area-box" :class="{ areaBox1: ['单词集中突破'].includes(currentTitle) }">
				<div class="area-box-main">
					<div class="word">
						<div class="input-word" @click.stop="showKeyboardFn">
							<!-- <div class="input-word" @click.stop="onFoustu"> -->
							<span
								class="letter-input"
								:class="{
									'letter-input-right': letter == inputWord[index],
									'letter-input-error': letter != inputWord[index] && inputWord[index]
								}"
								v-for="(letter, index) in dataObj.testpapecontent
									.toLowerCase()
									.split(/[\t\r\f\n\s]*/g)
									.join('')"
								:key="`letter${index}`"
							>
								{{ inputWord[index] }}
							</span>
						</div>
					</div>
					<div class="voice">
						<div
							class="voice-item"
							v-if="dataObj.mubiaozi"
							@click="playVoice(dataObj.hearing, currentIndex)"
						>
							<span>{{ dataObj.mubiaozi }}</span>
							<svg-icon
								icon-class="voice"
								class="icon"
								:class="{ 'icon-active': audioPlay }"
								v-if="dataObj.hearing"
							></svg-icon>
						</div>
					</div>
					<div class="des">
						<div style="margin-bottom: 0.2rem" v-html="dataObj.answerminutes"></div>
						<van-image fit="cover" class="word-img" :src="setSourceUrl(dataObj.image)" />
						<div class="example" v-if="dataObj.analysiscontent">
							<span class="tit">eg.例子</span>
						</div>
						<div class="prase">
							<p v-if="dataObj.analysiscontent" v-html="dataObj.analysiscontent"></p>
							<p v-if="dataObj.rightkey" v-html="dataObj.rightkey"></p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="audio-box">
			<audio id="audio" ref="audio" :src="setSourceUrl(audioSrc)" @ended="played">
				您的浏览器不支持 audio 标签。
			</audio>
		</div>
		<input
			style="width: 0px; height: 0px; position: absolute; z-index: -1; font-size: 14px"
			@input="inpevent"
			type="text"
			ref="inp"
			v-model="inpData"
		/>
		<keyBoard v-model="keyBoardData" :showKeyboard="showKeyboard" :keyMax="keyMax"></keyBoard>
		<!-- <div class="keyboard" v-show="showKeyboard" @click.stop="showKeyboard = true">
			<ul class="letter">
				<li v-for="(w, index) in keyWords" :key="`w${index}`" @click.stop="changeWrod(w)">
					{{ w }}
				</li>
			</ul>
		</div> -->
	</div>
</template>

<script>
import { setSourceUrl, removeTags } from '@/utils/public';
import keyBoard from '@/components/keyBoard';
export default {
	name: 'questionType13',
	components: {
		keyBoard
	},
	props: {
		dataObj: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	watch: {
		dataObj: {
			handler: function (val) {
				val.testpapecontent = this.removeTags(val.testpapecontent);
				this.privateData = val;

				this.playVoice(this.dataObj.hearing);
			},
			immediate: true
		},
		keyBoardData: {
			handler: function (val) {
				let _this = this;
				_this.inputWord = val;
				// let targetWord = this.dataObj.testpapecontent.toLowerCase();
				// let _val = val.join('');
				// if (_val == targetWord) {
				// 	setTimeout(() => {
				// 		this.showKeyboard = false;
				// 		_this.changeQuestion();
				// 	}, 1000);
				// }
			},
			immediate: true
		},
		inputWord: function (val) {
			let targetWord = this.dataObj.testpapecontent
				.toLowerCase()
				.split(/[\t\r\f\n\s]*/g)
				.join('');
			let _val = val.join('');
			if (_val == targetWord) {
					setTimeout(() => {
						this.showKeyboard = false;
						this.keyBoardData = [];
						// this.inpData = '';
						// this.$refs.inp.blur();
						if (this.currentIndex < this.totalNum - 1) {
							setTimeout(() => {
								this.currentIndex = ++this.currentIndex;
								this.$eventBus.$emit('changeQuestion', this.currentIndex);
								this.inputWord = [];
								this.inputIndex = 0;
							}, 100);
						} else {
							this.$dialog
								.confirm({
									message: '已是最后一个，再做一次？'
								})
								.then(() => {
									this.inputWord = [];
									this.inputIndex = 0;
									this.currentIndex = 0;
									this.nextBtn = false;
									this.audioPlay = false;
									this.$eventBus.$emit('repeatOperate');
								});
						}
					}, 1000);
				
			}
		}
	},
	data() {
		return {
			keyBoardData: [],
			keyMax: 1,
			totalNum: 0,
			currentIndex: 0,
			showKeyboard: false,
			inputIndex: 0,
			audioPlay: false,
			audioSrc: '',
			inpData: '',
			nextBtn: false,
			audioObj: null,
			keyWords: [
				'q',
				'w',
				'e',
				'r',
				't',
				'y',
				'u',
				'i',
				'o',
				'p',
				'a',
				's',
				'd',
				'f',
				'g',
				'h',
				'j',
				'k',
				'l',
				'z',
				'x',
				'c',
				'v',
				'b',
				'n',
				'm',
				',',
				'.',
				'!',
				'?',
				'　',
				"'"
			],
			inputWord: []
		};
	},
	created() {
		this.totalNum = this.$store.getters['common/getTotalQuestion'];
	},
	mounted() {
		this.audioObj = this.$refs['audio'];
		this.audioSrc = this.privateData.hearing;
		this.playVoice(this.dataObj.hearing);
	},
	methods: {
		setSourceUrl,
		removeTags,
		onFoustu() {
			let that = this;
			let device = window.localStorage.getItem('device');
			if (device == 'android') {
				this.$toast('请切换至26键输入');
				setTimeout(() => {
					that.$refs.inp.focus();
				}, 2000);
			} else {
				that.$refs.inp.focus();
			}
		},
		inpevent(event) {
			let dnumber1 = event.target.value;
			dnumber1 = dnumber1.toLowerCase();
			if (
				dnumber1.length >
				this.dataObj.testpapecontent
					.toLowerCase()
					.split(/[\t\r\f\n\s]*/g)
					.join('').length
			) {
				this.inpData = dnumber1.slice(
					0,
					this.dataObj.testpapecontent
						.toLowerCase()
						.split(/[\t\r\f\n\s]*/g)
						.join('').length
				);
				dnumber1 = dnumber1.slice(
					0,
					this.dataObj.testpapecontent
						.toLowerCase()
						.split(/[\t\r\f\n\s]*/g)
						.join('').length
				);
			}
			let wordData = [];
			for (let i = 0; i < dnumber1.length; i++) {
				wordData.push(dnumber1[i]);
			}
			this.inputWord = wordData;
		},
		changeWrod(val) {
			console.log(val);
			let targetWord = this.dataObj.testpapecontent
				.toLowerCase()
				.split(/[\t\r\f\n\s]*/g)
				.join('');
			if (val == '　') {
				val = ' ';
			}
			if (val == targetWord[this.inputIndex]) {
				this.$set(this.inputWord, this.inputIndex, val);
				this.inputIndex = ++this.inputIndex;
			} else {
				this.$set(this.inputWord, this.inputIndex, val);
			}
		},
		showKeyboardFn() {
			this.showKeyboard = true;
			this.keyMax = this.dataObj.testpapecontent.toLowerCase().length;
		},
		initData() {},

		playVoice(src) {
			if (src) {
				this.formatData();
				this.audioPlay = true;
				this.audioSrc = src;
				this.$set(this.dataObj, 'play', !this.dataObj.play);
				if (this.dataObj.play) {
					setTimeout(() => {
						this.$refs.audio.play();
					}, 1);
				} else {
					this.$refs.audio.pause();
				}
			}
		},
		played() {
			this.audioPlay = false;
			this.formatData();
		},
		formatData() {
			this.$set(this.dataObj, 'play', false);
		}
	}
};
</script>

<style lang="scss" scoped>
.audio-box {
	width: 0;
	height: 0;
	overflow: hidden;
}
.keyboard {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
	box-shadow: 0 0 0.1rem #ccc;
	.letter {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		background: #dcdcdc;
		padding: 0.15rem 0;
		li {
			text-align: center;
			line-height: 0.8rem;
			font-size: 0.54rem;
			width: 0.8rem;
			background: #fff;
			margin: 0.2rem;
			border-radius: 0.08rem;
			color: #323233;
			&:active {
				box-shadow: 0 0 0.1rem #ccc;
				background: #27b295;
				color: #fff;
			}
		}
	}
}
.input-word {
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	overflow: hidden;
	.words-input {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
	}
}
.letter-input {
	width: 0.6rem;
	min-width: 0.6rem;
	height: 0.76rem;
	font-size: 0.6rem;
	line-height: 1;
	text-align: center;
	background: #e4e4e4;
	margin: 0.03rem;
	&-right {
		background: #27b295;
		color: #fff;
	}
	&-error {
		background: #ff545c;
		color: #fff;
	}
}
.area-box {
	display: flex;
	align-items: center;

	padding: 0.3rem 0;
	height: calc(100vh - 1.9rem);
	border: 0.01rem #dcdcdc solid;
	border-radius: 0.08rem;

	&-main {
		width: 100%;

		padding: 0 0.3rem;
		max-height: 100%;
		overflow-y: auto;
		.example {
			display: block;
			padding: 0.32rem 0;
			.tit {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 1.36rem;
				height: 0.44rem;
				background: #2db79a;
				border-radius: 0.22rem;
				font-size: 0.24rem;
				color: #fff;
			}
		}
		.des {
			font-size: 0.28rem;
			p {
				margin-bottom: 0.16rem;
			}
			.word-img {
				width: 100%;
			}
		}
		.voice {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 0.3rem;
			.voice-item {
				display: flex;
				align-items: center;
				height: 0.64rem;
				margin: 0 0.15rem;
				border-radius: 0.32rem;
				padding: 0.15rem 0.2rem;
				font-size: 0.28rem;
				color: #323233;
				background: #f4f5f7;
				span {
					color: #909090;
				}
				.icon {
					margin-left: 0.1rem;
					&-active {
						color: #27b295;
					}
				}
			}
		}
		.word {
			display: flex;
			justify-content: center;
			margin-bottom: 0.2rem;
			.word-box {
				position: relative;
				font-size: 0.6rem;
				color: #323233;
				&::before {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0.16rem;
					content: '';
					height: 0.16rem;
					background: #e1f5f1;
				}
				.word-txt {
					position: relative;
					z-index: 2;
				}
			}
		}
		.des {
			img {
				width: 100%;
			}
		}
	}
}
.areaBox1 {
	height: calc(100vh - 2.7rem);
}
</style>
