import https from '../http';
const mianIndex = {
    /* 获取手机端轮播图 */
    getSwiperlist: function (params) {
        return https.get('/index/get_mobile_banner', params)
    },

    /* 获取首页导航 */
    getIndexNav: function (params) {
        return https.get('/index/getnav', params)
    },

     /* 获取首页消息 */
     getIndexNotify: function (params) {
        return https.get('/news/index', params)
    },
    /* 获取首页消息内容 */
    getNotifyDetail: function (params) {
        return https.post('/news/get_newsid_data', params)
    },
    
    /*  获取对应导航下的分类 */
    getNavClass: function (params) {
        return https.post('/index/getnav_class', params)
    },

    /* 获取所有导航以及其分类的树形结构 */
    getNavClassAll: function (params) {
        return https.get('/index/getnavclassall', params)
    },

    /* 获得活动广告信息 */
    getAdvert: function (params) {
        return https.get('/advertisement/get_advertisement', params)
    },
    
};

export default mianIndex