<template>
  <div class="audio-palyer">
    <div class="audio-media">
      <audio
        id="audio"
        ref="audio"
        :src="setSourceUrl(audioSrc)"
        controlslist="nodownload"
        controls="controls"
        @loadedmetadata="loadedmetadata"
        @ended="playEnd"
        @play="play"
        @pause="pause"
      >
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
    <div class="custom-audio">
      <div class="play-btn" @click.stop="palyAction">
        <i
          class="iconfont icon-loading-three iconloading"
          v-if="!canplay && audioSrc"
        ></i>
        <i
          :class="`iconfont ${isplay ? 'icon-zanting' : 'icon-bofang'}`"
          v-else
        ></i>
      </div>
      <div class="play-progress">
        <van-slider
          :disabled="!canplay"
          v-model="progress"
          @drag-start="startChange"
          @drag-end="startEnd"
          @change="change"
        />
      </div>
      <div class="play-tiem">{{ acountTime }}</div>
    </div>
  </div>
</template>

<script>
let timer = null;
import { setSourceUrl } from "@/utils/public";
export default {
  name: "audioMain",
  props: {
    audioSrc: {
      type: String,
      default: "",
    },
  },
  watch: {
    // audioSrc: function (val) {
    //   console.log(val)
    // },
    isplay: function (val) {
      if (val) {
        this.audioDome.play();
      } else {
        this.audioDome.pause();
      }
    },
    progress:function(val){
      this.$eventBus.$emit('playProgress',val)
    }
  },
  data() {
    return {
      audioDome: null,

      canplay: false,
      isplay: false,

      currentTime: 0,
      allTime: "",

      acountTime: "00:00:00",
      progress: 0,
    };
  },
  mounted() {
    this.audioDome = this.$refs["audio"];
  },
  methods: {
    setSourceUrl,
    palyAction() {
      if (this.canplay) {
        this.isplay = !this.isplay;
      }
    },
    startChange() {
      this.isplay = false;
    },
    startEnd() {
      this.isplay = true;
    },
    change(val) {
      if (this.canplay) {
        let lt = ((100 - val) / 100) * this.allTime;
        let ct = (val / 100) * this.allTime;
        this.progress = val;
        this.currentTime = ct;
        this.setTime(lt);
        this.audioDome.currentTime = ct;
      }
    },
    loadedmetadata() {
      let that = this;
      let duration = parseInt(that.audioDome.duration);
      that.canplay = true;
      that.allTime = duration;
      that.isplay = true;
      that.audioDome.addEventListener("timeupdate", function () {
        that.currentTime = that.audioDome.currentTime;
        that.setCurrentProgress(that.currentTime);
      });
      that.setTime(that.allTime);
    },

    pause() {
      clearInterval(timer);
      this.$emit('playPause')
    },
    play() {
      this.downTime();
      this.$emit('startPlay')
    },
    playEnd() {
      this.setTime(0);
      this.isplay = false;
      this.$emit('playEnd')
    },
    setCurrentProgress(ct) {
      let _progress = 0;
      if (ct <= this.allTime) {
        _progress = parseInt((ct / this.allTime) * 100);
      } else {
        _progress = 100;
      }

      this.progress = _progress;
    },
    setTime(val) {
      this.acountTime = this.getTime(val);
    },
    downTime() {
      clearInterval(timer);
      let _time = parseInt(this.currentTime);
      timer = setInterval(() => {
        if (_time < this.allTime) {
          _time = ++_time;
          this.setTime(this.allTime - _time);
        }
      }, 1000);
    },
    getTime(time) {
      // 转换为式分秒
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      let _tiem = `${h}:${m}:${s}`;
      return _tiem;
    },
  },
};
</script>
<style lang="scss">
.custom-audio {
  .van-slider {
    background: #fff;
  }
  .van-slider__bar {
    background: #28b295;
  }
  .van-slider__button {
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    border: 0.02rem #28b295 solid;
  }
}
</style>
<style lang="scss" scoped>
.custom-audio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1rem;
  padding: 0 0.36rem;
  border-radius: 0.5rem;
  background: #e2f5f1;
  .play-progress {
    flex: 1;
    padding: 0 0.15rem;
    .van-slider {
      width: 100%;
    }
  }
  .play-tiem {
    font-size: 0.28rem;
    color: #28b295;
  }
  .play-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    .iconfont {
      font-size: 0.68rem;
      line-height: 1;
      color: #28b295;
    }
    .iconloading {
      font-size: 0.5rem;
      animation: circle 2s linear infinite;
    }
  }
  .disable {
    .iconfont {
      color: #909090;
    }
  }
}
.audio-media {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>