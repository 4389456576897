<template>
  <div class="question-box question-common">
    <ul class="question-test-ul">
      <li
        :style="`backgroundImage:url(${tzg})`"
        v-for="(letter, index) in privateData.testpapecontent"
        :key="`a${index}`"
        :class="{mubiaozi:letter==privateData.mubiaozi}"
      >
        {{letter}}
      </li>
    </ul>
    <ul class="question-option">
      <li
        v-for="(item, index) in privateData.optionjson"
        :key="index"
        :class="{
          proper: item.status == 2,
          error: item.status == 3,
        }"
        @click="selectOpt(item, index)"
      >
        <span class="text" v-html="item.text"></span>
      </li>
    </ul>
    <audio
      :src="errorVoice"
      controlslist="nodownload"
      controls="controls"
      ref="errorAudio"
      class="status-vioce"
    ></audio>
    <audio
      :src="properVoice"
      controlslist="nodownload"
      controls="controls"
      ref="properAudio"
      class="status-vioce"
    ></audio>
  </div>
</template>

<script>
  import { html2string } from '@/utils/public';
export default {
  name: "questionType11",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        val.testpapecontent = val.testpapecontent
          .replace(/<\/?.+?>/g, "")
          .replace(/ /g, ""); 
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      tzg: require("@/assets/img/tzg.png"),
      errorVoice: require("@/assets/audio/error.mp3"),
      properVoice: require("@/assets/audio/proper.mp3"),
      errorAudio: null,
      properAudio: null,
      privateData: {},
    };
  },
  mounted() {
    this.properAudio = this.$refs["properAudio"];
    this.errorAudio = this.$refs["errorAudio"];
  },
  methods: {
    html2string,
    selectOpt(item, index) {
      let rightkey = this.html2string(this.privateData.rightkey);
      let optionjson = this.privateData.optionjson;
      optionjson.forEach((item) => {
        item.status = "";
      });
      if (rightkey == item.value) {
        item.status = 2;
        this.properAudio.play();
      } else {
        item.status = 3;
        this.errorAudio.play();
      }

      this.$set(this.privateData, "answerVal", item.value);
      this.$set(this.privateData, "answerIdx", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.status-vioce {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
 .question-box {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //flex-direction: column;
  width: 100%;
  height: calc(100vh - 7rem);
}
.question-test-ul {
  
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  li {
    width: 0.76rem;
    height: 0.76rem;
    background-size: .752rem;
    margin: 0 0.1rem;
    font-size: 0.48rem;
    text-align: center;
    line-height: 0.76rem;
  }
  .mubiaozi{
    color: #27b295;
  }
}
.question-option {
  width: 100%;
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.32rem;
    margin-bottom: 0.32rem;
    border-radius: 0.54rem;
    border: 0.01rem #dcdcdc solid;
    font-size: 0.32rem;
    color: #3a3a3a;
    line-height: 1.6;
      overflow: auto;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .value {
      position: relative;
      padding-right: 0.26rem;
      margin-right: 0.26rem;
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 0.02rem;
        height: 0.24rem;
        background: #d8d8d8;
      }
    }
    .text {
      font-size: 0.32rem;
    }
  }
  .select {
    background: #e1f5f1;
  }
  .proper {
    color: #fff;
    background: #27b295;
    border-color: #27b295;
    &::after {
      position: absolute;
      right: .32rem;
      font-size: .4rem;
      content: "\eaf1";
    }
  }
  .error {
    color: #fff;
    background: #ff494a;
    border-color: #ff494a;
    &::after {
      position: absolute;
      right: .32rem;
      font-size: .4rem;
      content: "\e628";
    }
  }
}
</style>