<template>
  <div class="tree-menu">
    <menuItem :menuData="menuData" :father="menuData" :navType="navType"></menuItem>
  </div>
</template>

<script>
import menuItem from "./menuItem.vue";
export default {
  name: "treeMenu",
  components: { menuItem },
  props: {
    menuData: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
    navType:{
      type:[String,Number],
      default:0
    }
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss" scoped>
.tree-menu{
  height: initial;
}
</style>