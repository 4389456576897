const state = {
    swiperImg:[],
    indexNav:[]
}
const getters = {
    getSwiperImg (state) {
        return state.swiperImg
    },
    getIndexNav (state) {
        return state.indexNav
    }
}
const actions = {
    
}
const mutations = {
    setSwiperImg (state, data) {
        state.swiperImg = data
    },
    setIndexNav (state, data) {
        state.indexNav = data
    }
}
export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
}