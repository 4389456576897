<template>
  <div class="word-shorthand">
    <navBar
      ><span>{{ currentTitle }}</span></navBar
    >
    <div class="page-content">
      <div class="scroll-box">
        <ul class="words-list" v-if="wordsData.length">
          <li v-for="(item, index) in wordsData" :key="index">
            <div class="word-box">
              <div class="word">{{ item.word }}</div>
              <div class="des">{{ item.explain }}</div>
            </div>
            <div class="word-voice">
              <div
                class="voice"
                v-if="item.yinbiao"
                @click="playVoice(item.fayinfile, index)"
              >
                <span>{{ item.yinbiao }}</span>
                <svg-icon
                  icon-class="voice"
                  class="icon"
                  :class="{ 'icon-active': item.play}"
                  v-if="item.fayinfile"
                ></svg-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="audio-box">
      <audio
        id="audio"
        ref="audio"
        v-if="audioPlay"
        :src="setSourceUrl(audioSrc)"
        @ended="played"
      >
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
export default {
  name: "coreWords",
  data() {
    return {
      audioPlay: false,
      audioSrc: "",
      wordsData: [],
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.formatData();
  },
  methods: {
    setSourceUrl,
    initData() {
      this.$api
        .getCourseUnitContent({
          chapterid: this.currentId,
          type: this.$route.query.type,
          iscourse: this.$route.query.iscourse || 1,
        })
        .then((res) => {
          let resData = res.data || {};
          resData = JSON.parse(resData.content);
          resData.forEach((item) => {
            item.play = false
          });
          this.wordsData = resData;
        });
    },
    playVoice(src, index) {
      if (src) {
        this.formatData();
        this.audioPlay = true;
        this.audioSrc = src;
        this.$set(this.wordsData[index], 'play', true);
        setTimeout(() => {
          this.$refs.audio.play();
        }, 10);
      }
    },
    played() {
      this.audioPlay = false;
      this.formatData();
    },
    formatData() {
      this.wordsData.forEach((item) => {
        item.play = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-box {
  width: 0;
  height: 0;
  overflow: hidden;
  visibility:hidden;
}
.page-content {
  padding-top: 1.23rem;
  .scroll-box {
    padding: 0.28rem;
    min-height: calc(100vh - 1.23rem);
    background: #fff;
    .words-list {
      li {
        margin-bottom: 0.32rem;
        .word-voice {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 0.16rem;
          font-size: 0.28rem;
          color: #323233;
          .voice {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0.5rem;
            span {
              font-size: 0.28rem;
              color: #909090;
            }
            .icon {
              margin-left: 0.2rem;

              color: #000;
              &-active {
                color: #2db79a;
              }
            }
          }
        }
        .word-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #e1f5f1;
          border-radius: 0.32rem;
          .word {
            position: relative;
            height: 100%;
            max-width: 50%;
            padding: 0.1rem 0.3rem;
            font-size: 0.3rem;
            line-height: 1.5;
            color: #fff;
            background: #2db79a;
            border-radius: 0.32rem;
            word-break: break-all;
          }
          .des {
            flex: 1;
            padding: 0 0.3rem;
            font-size: 0.28rem;
            text-align: right;
            color: #323233;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>