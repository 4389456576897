import https from '../http';
const learnHistory = {
    /*获取用户最近学习*/
    getLastLearn: function(params){
        return https.post('/userlearning/get_userlearning',params)
    },
    /* 添加用户学习记录*/
    addLearnHistory: function(params){
        return https.post('/userlearning/add_userlearning',params)
    },
    /*删除指定的用户学习记录*/
    deleteSpecifyLearnHistory: function(params){
        return https.post('/userlearning/deleuserlearning',params)
    }
};

export default learnHistory
