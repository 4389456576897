<template>
  <div class="book-page">
    <navBar>
      <span class="title">{{ currentTitle }}</span>
    </navBar>

    <div
      class="content-box"
      :style="`fontSize:${fontSize / 100}rem; color:${
        colorData[slectModel].color
      };background:${colorData[slectModel].bg}`"
      v-if="bookChapterContent.content"
    >
      <div class="scroll-box" ref="scroll-box">
        <div
          id="bookDetail"
          ref="bookDetail"
          class="book-detail"
          :style="`fontSize:${fontSize / 100}rem; color:${
            colorData[slectModel].color
          };background:${colorData[slectModel].bg}`"
          v-html="bookChapterContent.content"
          @click="showMenu = true"
        ></div>
      </div>
    </div>

    <ul class="footer-menu">
      <li
        v-for="(item, index) in menuData"
        :key="index"
        @click="menuAction(index)"
        :class="{ active: activeMenu == index }"
      >
        <i :class="`iconfont ${item}`"></i>
      </li>
    </ul>

    <div class="picker" v-if="showMenu" @click="closeMenu">
      <div class="picker-main">
        <div class="pick-header">
          <span></span>
          <i class="iconfont icon-cuowu" @click="closeMenu"></i>
        </div>
        <div class="pick-body">
          <!-- <treeMenu :menuData="bookChapters"></treeMenu> -->
          <ul class="unit-list">
            <li
              class="nuit"
              v-for="(item, index) in bookChapters"
              :class="{ active: item.isred == 1 }"
              :key="`aa${index}`"
              @click.stop="selectUnit(index, item)"
            >
              {{ item.title }}
            </li>
            <!--                 
            <li v-for="(item, index) in bookChapters" :key="`aa${index}`">
              <p
                class="charts"
                :class="{ active: setSelected(index) }"
                @click.stop="selectUnit(item)"
              >
                {{ item.title }}
              </p>
              <ol v-for="(unit, idx) in item.children" :key="`cc${idx}`">
               
              </ol>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="picker" v-if="showSet" @click="closeSet">
      <div class="picker-main">
        <div class="pick-body">
          <div class="set-item" style="margin-top: 0.5rem">
            <i class="iconfont icon-case"></i>
            <div class="set-con">
              <span class="tag">小</span>
              <van-slider
                style="margin: 0 0.3rem"
                v-model="fontSize"
                :min="minSize"
                :max="maxSize"
                @input="onChange"
              />
              <span class="tag">大</span>
            </div>
          </div>
          <div class="set-item">
            <i class="iconfont icon-dengpao"></i>
            <div class="set-con">
              <ul class="set-color">
                <li
                  v-for="(item, index) in colorData"
                  :key="`cc${index}`"
                  :style="`background:${item.bg}; border:.02rem ${item.color} solid`"
                  @click="selectReadModel(index)"
                >
                  <i
                    class="iconfont"
                    :class="item.icon"
                    :style="`color:${item.color}`"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import treeMenu from "@/components/treeMenu/menuPage.vue"
export default {
  name: "bookDetail",
  components: {
    //treeMenu
  },
  data() {
    return {
      showMenu: false,
      bookId: "",
      bookTitle: "",
      bookDetail: {},
      bookChapters: [],
      bookChapterContent: {},
      menuData: ["icon-mulu", "icon-shezhi-xianxing", "icon-dingbu"],
      activeMenu: 1000,
      activePicker: [],

      showSet: false,
      minSize: 24,
      maxSize: 36,
      fontSize: 28,
      slectModel: 0,
      historyId: "",
      colorData: [
        {
          bg: "#fff",
          color: "#333",
          icon: "",
        },
        {
          bg: "#28B396",
          color: "#fff",
          icon: "",
        },
        {
          bg: "#88B72D",
          color: "#fff",
          icon: "",
        },
        {
          bg: "#B7922D",
          color: "#fff",
          icon: "",
        },
        {
          bg: "#000000",
          color: "#fff",
          icon: "icon-yueliang",
        },
      ],
    };
  },
  created() {
    this.fontSize = Number(localStorage.getItem("fontSize") || 28);
    this.slectModel = Number(localStorage.getItem("slectModel") || 0);
  },
  mounted() {},
  methods: {
    init() {
      this.getBookChapters();
      this.addViewNum();
    },
    closeSet() {
      this.showSet = false;
      this.activeMenu = 1000;
    },
    onChange(val) {
      localStorage.setItem("fontSize", val);
      this.fontSize = val;
      this.setFontSize();
      // dom.forEach((item)=>{
      //   item.style.fontSize = (val/100)+'rem'
      // })
      //console.log(dom)
    },
    setFontSize() {
      let bookDetail = document.getElementById("bookDetail");
      let dom = bookDetail.getElementsByTagName("*");
      for (let i = 0; i < dom.length; i++) {
        dom[i].style.fontSize = this.fontSize / 100 + "rem";
      }
    },
    selectReadModel(idx) {
      this.slectModel = idx;
      localStorage.setItem("slectModel", idx);
    },
    // setSelected(a, b) {
    //   if (b || b == 0) {
    //     if (a == this.activePicker[0] && b == this.activePicker[1]) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     if (a == this.activePicker[0]) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    selectUnit(idx, opt) {
      this.bookChapters.forEach((item, index) => {
        if (idx == index) {
          item.isred = 1;
        } else {
          item.isred = 0;
        }
        this.$set(this.bookChapters, index, item);
      });
      if (opt.id == this.historyId) {
        return this.$toast({
          message: "正处于当前章节！",
        });
      }
      this.currentTitle = opt.title;
      this.getBookChapterContent(opt.id, opt.pid);
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.closeMenu();
      }, 300);
    },
    closeMenu() {
      this.showMenu = false;
      this.activeMenu = 1000;
    },
    menuAction(n) {
      this.activeMenu = n;
      switch (n) {
        case 0: {
          this.showSet = false;
          this.showMenu = true;
          break;
        }
        case 1: {
          this.showMenu = false;
          this.showSet = true;
          break;
        }
        case 2: {
          let scrollBox = this.$refs["scroll-box"];
          scrollBox.scrollTo(0, 0);
          setTimeout(() => {
            this.activeMenu = 1000;
          }, 300);
          break;
        }
        default:
          break;
      }
    },
    setAction() {},
    backAction() {},
    getBookChapters() {
      this.$api
        .getBookChapters({
          tushuid: this.currentId,
        })
        .then((res) => {
          this.bookChapters = res.data || [];
          if (res.data.length) {
            let idx = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].isred == 1) {
                idx = i;
                this.historyId = res.data[i].id;
                break;
              }
            }
            this.currentTitle = res.data[idx].title;
            this.getBookChapterContent(res.data[idx].id, res.data[idx].pid);
          }
        });
    },
    getBookChapterContent(id, type) {
      this.$api
        .getBookChapterContent({
          chapterid: id,
          type: type,
        })
        .then((res) => {
          this.bookChapterContent = res.data || {};
          this.$nextTick(() => {
            this.setFontSize();
          });
          if (id != this.historyId)
            this.$api
              .addRecentBooks({
                tushuid: this.currentId,
                tushuchapid: id,
              })
              .then(() => {
                this.historyId = id;
                let scrollBox = this.$refs["scroll-box"];
                scrollBox.scrollTo(0, 0);
              });
        });
    },
    addViewNum() {
      this.$api.addViewNum({
        tushuid: this.currentId,
      });
    },
  },
};
</script>
<style lang="scss">
.book-detail {
  font-size: 0.28rem;
  ::v-deep * {
    font-family: initial !important;
  }
  * {
    word-break: break-all;
    font-size: inherit;
    line-height: 1.5;
    text-align: justify;
    background: none !important;
    font-family: inherit !important;
  }
  p,
  div {
    text-indent: 0.56rem;
  }
}
</style>
<style lang="scss" scoped>
.book-page {
  background: #fafafa;
  height: 100vh;
}
.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.picker {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 888;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.05);
  .picker-main {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 0.1rem;
    padding-bottom: 0.98rem;
    animation: showMenu 0.3s ease-in-out;
    .pick-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.8rem;
      padding: 0 0.28rem;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    .pick-body {
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;
      .set-item {
        padding: 0 0.28rem;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .iconfont {
          color: #333;
          margin-right: 0.3rem;
        }
        .set-con {
          flex: 1;
          display: flex;
          align-items: center;
          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.12rem;
            height: 0.48rem;
            color: #333;
            font-size: 0.22rem;
            border-radius: 0.24rem;
            border: 0.02rem solid #28b396;
          }
          .set-color {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1.12rem;
              height: 0.48rem;
              border-radius: 0.24rem;
              .iconfont {
                margin: 0;
              }
            }
          }
        }
      }
      .unit-list {
        li {
          line-height: 0.8rem;
        }
        .charts {
          font-size: 0.32rem;
          padding: 0 0.28rem;
        }
        .nuit {
          font-size: 0.28rem;
          padding-left: 0.84rem;
          &.active {
            color: #fff;
            background: #2db79a;
          }
        }
      }
    }
  }
}
@keyframes showMenu {
  0% {
    opacity: 0.8;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(00, 0);
  }
}
.footer-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 0.98rem;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  li {
    display: flex;
    align-items: center;
    color: #333;
    i {
      font-size: 0.42rem;
    }
    &.active {
      color: #2db79a;
    }
  }
}
.content-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  bottom: 0.88rem;
  padding: 0.3rem 0 0.3rem 0.3rem;
  background: #fff;
  .scroll-box {
    height: 100%;
    padding-right: 0.3rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .book-detail {
    background: #fff;
  }
}
</style>