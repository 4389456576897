<template>
  <div class="read-by-turn">
    <!-- <div class="name-author">
      <div class="name" v-html="html2string(famousReadData.name)"></div>
      <div class="author" v-html="html2string(famousReadData.auther)"></div>
    </div> -->

    <ul class="recite-moudle">
      <li
        :class="{ active: reciteActive == index }"
        v-for="(item, index) in reciteMoudle"
        :key="`bs${index}`"
        @click="selectRecite(index)"
      >
        {{ item.text }}
      </li>
    </ul>

    <div class="class-content">
      <div
        class="content-box"
        :class="{ noindent: currentClass.courseclass_id == 2 }"
      >
        <p v-for="(item, index) in currentClass.paragraph" :key="`a${index}`">
          <span
            :class="{
              recite: item1.recite,
              recited: item1.recited,
            }"
            :key="`b${index1}`"
            v-for="(item1, index1) in item"
            v-html="replaceText(item1.text)"
            @click="selectWords(index, index1)"
          >
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { html2string, getRandom, deepClone } from "@/utils/public";
import { mapGetters } from "vuex";
export default {
  name: "reciteAll",
  computed: {
    ...mapGetters({
      famousReadData: "famousRead/getCurrentFamousRead",
    }),
  },
  watch: {
    famousReadData: function (val) {
      this.currentClass = val;
      this.reciteActive = 0;
      this.selectRecite(0);
    },
  },
  data() {
    return {
      currentClass: {},
      reciteActive: 0,
      reciteMoudle: [
        {
          id: 3,
          text: "正常模式",
        },
        {
          id: 6,
          text: "中等模式",
        },
        {
          id: 9,
          text: "复杂模式",
        },
      ],
    };
  },
  created() {
    this.selectRecite(0);
  },
  mounted() {},
  methods: {
    html2string,
    getRandom,
    deepClone,
    replaceText(str) {
      return str.replace(new RegExp("&lt;br/&gt;", "g"), "<br/>");
    },
    selectWords(idx1, idx2) {
      this.$set(this.currentClass.paragraph[idx1][idx2], "recited", true);
      this.$set(this.currentClass.paragraph[idx1][idx2], "recite", false);
    },
    selectRecite(idx) {
      let _num = this.reciteMoudle[idx].id;
      this.reciteActive = idx;
      
      this.currentClass = this.deepClone(this.famousReadData);
      this.currentClass.paragraph = this.computeContent(_num);
    },
    computeContent(num) {
      let idxArr = [];
      let bookObjParagraph = this.currentClass?.paragraph;
      if (bookObjParagraph) {
        bookObjParagraph.forEach((item, index) => {
          item.forEach((item, idx) => {
            idxArr.push([index, idx]);
            item.isRecite=false;
            item.recite=false;
          });
        });
        let ran = -1;
        for (let i = 0; i < num; i++) {
          ran = this.getRandom(0, idxArr.length, ran);
          let item = idxArr[ran];
          if (bookObjParagraph[item[0]].length) {
            if (bookObjParagraph[item[0]][item[1]]) {
              bookObjParagraph[item[0]][item[1]].recited = false;
              bookObjParagraph[item[0]][item[1]].recite = true;
              bookObjParagraph[item[0]][item[1]].isRecite = true;
            }
          }
        }
      }

      return bookObjParagraph;
    },
  },
};
</script>

<style lang="scss" scoped>
.recite-moudle {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.6rem;
  li {
    font-weight: 400;
    color: #909090;
    font-size: 0.26rem;
    padding: 0.2rem 0;
  }
  .active {
    position: relative;
    color: #28b295;
    &::after {
      position: absolute;
      left: 50%;
      bottom: 0.1rem;
      content: "";
      width: 0.5rem;
      height: 0.03rem;
      background: #28b295;
      transform: translateX(-50%);
    }
  }
}
.read-by-turn {
  padding: 0 0.3rem 0.3rem 0.3rem;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  .name-author {
    text-align: center;
    margin-bottom: 0.5rem;
    .name {
      font-size: 0.35rem;
      font-weight: bold;
      margin-bottom: 0.1rem;
    }
    .author {
      font-size: 0.24rem;
      font-weight: 400;
    }
  }
  .class-content {
    padding-top: 1rem;
    text-indent: 2em;
    display: flex;
    justify-content: center;
    font-size: 0.26rem;
    line-height: 1.6;
    color: #1c1c1c;
    .noindent {
      text-indent: 0;
    }
    .recite {
      display: block;
      position: relative;
      border-bottom: 0.01rem #909090 solid;
      color: #fff;
      border-bottom: 1px #333 solid;
    }
    .recited {
      display: block;
      border-bottom: 0.01rem #909090 solid;
      color: #ff1e1e;
    }
  }
}
</style>