import https from '../http';
const course = {
    /*获得书籍版本分类 PC端手机端通用*/
    getBookVersion: function(params){
        return https.get('/bookbanben/index',params)
    },
    /* 获得对应分类的课程*/
    getTypeCourse: function(params){
        return https.post('/course/getcourse',params)
    },
    /* 获得课程的章节*/
    getCourseUnit: function(params){
        return https.post('/course/get_course_chapters',params)
    },
    /*  获得课程章节的内容*/
    getCourseUnitContent: function(params){
        return https.post('/course/getchaptercontent',params)
    },

    /*  获得课程章节的名家朗读内容*/
    getCourseReadContent: function(params){
        return https.post('/course/get_mjld',params)
    },

    /*  获得作者内容*/
    getAuthInfo: function(params){
        return https.post('/course/get_auth_info',params)
    },
    
    /*  获得课程章节的试题内容*/
    getClassUnitTest: function(params){
        return https.post('/course/get_chapter_testpape_content',params)
    },
    
    
    
    /*  获得试题年份*/
    getPapeYaer: function(params){
        return https.get('/papeyear/get_papeyear',params)
    },

    /*  获得试题*/
    getAllQuestion: function(params){
        return https.post('/course/get_chapter_testpape_content_all',params)
    },

    /*  获得历年真题试题*/
    getYearTest: function(params){
        return https.post('/testpape/get_year_pape',params)
    },


    

     
    
    /*  获得 集中精学、专项培优、精准备考接口*/
    getCourseTypeContall: function(params){
        return https.post('/course/get_course_type_contall',params)
    },
};

export default course
