<template>
  <div class="complate-infs">
    <navBar>
      <span class="title">编辑个人信息</span>
    </navBar>
    <div class="infs-from">
      <div class="infs-item" style="position: relative">
        <div class="item-label">
          <span class="label">头像：</span>
        </div>
        <div class="item-input">
          <van-image
            fit="cover"
            class="avator"
            :src="userInfo.avatar"
          ></van-image
          ><input
            type="file"
            class="upload-avator"
            ref="avatorUpload"
            accept="image/*"
            @change="selectAvator"
          />
          <div class="mask" v-if="!isEdit" @click="changeAvatar"></div>
        </div>
      </div>
      <!-- <div class="infs-item">
        <div class="item-label">
          <span class="label">用户名：</span>
        </div>
        <div class="item-input">{{ userInfo.username }}</div>
      </div> -->
      <template v-for="(item, tag) in formData">
        <div class="infs-item" v-if="item.show" :key="tag">
          <div class="item-label">
            <span class="require" v-if="item.require && isEdit">*</span>
            <span class="label">{{ item.label }}：</span>
          </div>
          <div class="item-input" v-if="item.type == 0">
            <input
              v-model="item.data"
              :readonly="!isEdit"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength"
              :type="item.inputType || 'text'"
            />
          </div>
          <div class="item-input" v-if="item.type == 1">
            <span
              @click="selectGender(1)"
              class="gender"
              :class="{ 'gender-select-boy': item.data == 1 }"
              ><i class="iconfont icon-xingbienan"></i>男生</span
            >
            <span
              @click="selectGender(2)"
              class="gender"
              :class="{ 'gender-select-girl': item.data == 0 }"
              ><i class="iconfont icon-xingbienv"></i>女生</span
            >
          </div>
          <div class="item-input" v-if="item.type == 2">
            <input
              type="text"
              v-model="item.data"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength"
            />
            <van-icon name="arrow" class="arrow" />
            <div class="mask" @click="pickerAction(tag)"></div>
          </div>
          <div class="item-input" v-if="item.type == 3">
            <input
              type="text"
              v-model="item.data"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength"
              style="padding-right: 2.1rem"
            />
            <div
              class="send-code"
              :class="{ disable: isSend }"
              @click="sendCodeAction"
            >
              {{ isSend ? sendText : "发送验证码" }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="enter-learn" @click="submitAction" v-if="isEdit">保存</div>
    <div class="enter-learn" v-else @click="isEdit = true">编辑</div>

    <div class="area-picker" v-if="showPicker" @click="showPicker = false">
      <div class="picker-main">
        <div class="picker-header">
          <div class="btn cancle" @click="showPicker = false">取消</div>
          <div class="tit">{{ computeTitle() }}</div>
          <div class="btn confirm" @click="confirmAction(currentTag)">确认</div>
        </div>
        <div class="picker-body">
          <ul class="picker-data" v-if="pickerData.length">
            <li
              v-for="(item, index) in pickerData"
              :class="{ active: selectData.label == item.label }"
              :key="index"
              @click.stop="selectPicker(index, item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminRegions from "@/utils/city.json";
let timer = null;
export default {
  name: "complateInfs",
  watch: {
    isEdit: function (val) {
      this.$set(this.formData.code, "show", val);
    },
  },
  data() {
    return {
      isEdit: false,
      isSend: false,
      sendText: "60s后重新发送",
      showPicker: false,
      userInfo: {
        avator: "",
        name: "",
      },
      formData: {
        name: {
          show: true,
          label: "姓名",
          require: true,
          data: "",
          type: 0,
          placeholder: "请输入学生姓名",
          maxlength: 6,
        },
        gender: {
          show: true,
          label: "性别",
          require: true,
          data: "",
          type: 1,
          maxlength: 2,
        },
        nickname: {
          show: true,
          label: "昵称",
          require: true,
          data: "",
          type: 0,
          placeholder: "请输入学生昵称",
          maxlength: 10,
        },
        province: {
          show: true,
          label: "省份",
          require: true,
          data: "",
          type: 2,
          placeholder: "请选择所在省份",
        },
        city: {
          show: true,
          label: "城市",
          require: true,
          data: "",
          type: 2,
          placeholder: "请选择所在城市",
        },
        area: {
          show: true,
          label: "所在区(县)",
          require: true,
          data: "",
          type: 2,
          placeholder: "请选择所在区(县)",
        },
        school: {
          show: true,
          label: "学校名称",
          require: true,
          data: "",
          type: 0,
          placeholder: "请输入学校名称",
          maxlength: 15,
        },
        grade: {
          show: true,
          label: "所在年级",
          require: true,
          data: "",
          type: 2,
          placeholder: "请选择所在年级",
        },
        mobile: {
          show: true,
          label: "家长电话号码",
          require: true,
          data: "",
          type: 0,
          placeholder: "请输入家长电话号码",
          maxlength: 11,
          inputType: "number",
        },
        code: {
          show: false,
          label: "验证码",
          require: true,
          data: "",
          type: 3,
          placeholder: "请输入验证码",
          maxlength: 6,
          inputType: "number",
        },
      },
      currentTag: "",
      selectData: {},
      pickerData: [],
      cityData: [],
      areaData: [],
      gradeData: [],
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    sendCodeAction() {
      if (this.regTel(this.formData.mobile.data)) {
        if (!this.isSend) {
          this.sendCodeFun();
        }
      }
    },
    sendCodeFun() {
      this.$api
        .sendSms({
          mobile: this.formData.mobile.data,
          event: "profile",
        })
        .then((res) => {
          this.$toast({
            message: res.msg,
          });
          this.isSend = true;
          this.sendText = `60s后重新发送`;
          clearInterval(timer);
          this.computeTime();
        });
    },
    initData() {
      this.$api.getUserInfoNew().then((res) => {
        let resData = res.data || {};
        this.userInfo = {
          ...resData,
        };
        for (let i in resData) {
          let val = resData[i];
          if (this.formData[i]) {
            this.$set(this.formData[i], "data", val);
          }
        }
      });

      this.$api.getUserGrade().then((res) => {
        let resData = res.data || [];
        let gradeData = [];
        for (let i = 0; i < resData.length; i++) {
          gradeData.push({
            label: resData[i],
            value: i,
          });
        }
        this.gradeData = gradeData;
      });
    },
    selectGender(val) {
      if (!this.isEdit) {
        this.$toast({
          message: "请先点击下方的“编辑”按钮！",
        });
        return false;
      }
      this.$set(this.formData.gender, "data", val);
    },
    changeAvatar() {
      if (!this.isEdit) {
        this.$toast({
          message: "请先点击下方的“编辑”按钮！",
        });
        return false;
      }
    },
    selectAvator() {
      let file = this.$refs["avatorUpload"].files[0];
      let formData = new FormData();
      formData.append("file", file);
      this.$api.uploadFile(formData).then((res) => {
        if (res.data) {
          this.userInfo.avatar = res.data.fullurl;
        } else {
          this.$toast({
            type: "fail",
            message: res.msg,
          });
        }
      });
    },
    submitAction() {
      let params = {};
      let hanndle = true;
      for (let i in this.formData) {
        if (this.formData[i].require && !this.formData[i].data) {
          hanndle = false;
          this.$toast({
            message: `请${
              this.formData[i].type == 0 || this.formData[i].type == 3
                ? "输入"
                : "选择"
            }${this.formData[i].label}`,
          });
          break;
        } else {
          if (i == "mobile" && !this.regTel(this.formData.mobile.data)) {
            hanndle = false;
            break;
          }
          params[i] = this.formData[i].data;
        }
      }
      params.avatar = this.userInfo.avatar;
      params.username = this.userInfo.username;
      if (hanndle) {
        this.$api
          .checkSms({
            mobile: this.formData.mobile.data,
            event: "profile",
            code: this.formData.code.data,
          })
          .then((res) => {
            if (!["4000", "4001", "4002"].includes(res.code)) {
              this.$api.changeUserInfs(params).then((res) => {
                this.$toast({
                  type: "success",
                  message: res.msg,
                });
              });
            } else {
              this.$toast({
                message: res.msg,
              });
            }
          });
      }
    },

    regTel(str) {
      let reg = /(13\d|14[579]|15[^4\D]|17[^49\D]|18\d)\d{8}/;
      if (!reg.test(str)) {
        this.$toast({
          message: "请输入正确格式的手机号码！",
        });
        return false;
      } else {
        return true;
      }
    },
    computeTime() {
      let num = 60;
      timer = setInterval(() => {
        if (1 < num) {
          this.sendText = `${--num}s后重新发送`;
        } else {
          clearInterval(timer);
          this.isSend = false;
        }
      }, 1000);
    },
    confirmAction(tag) {
      this.$set(this.formData[tag], "data", this.selectData.label);
      if (this.currentTag == "province") {
        this.$set(this.formData.city, "data", "");
        this.$set(this.formData.area, "data", "");
      }
      if (this.currentTag == "city") {
        this.$set(this.formData.area, "data", "");
      }
    },
    selectPicker(index, item) {
      if (this.currentTag == "province") {
        this.cityData = adminRegions[index].children;
      }
      if (this.currentTag == "city") {
        this.areaData = this.cityData[index].children;
      }
      this.selectData = item;
    },
    computeTitle() {
      if (this.currentTag == "province") {
        return "请选择省份";
      }
      if (this.currentTag == "city") {
        return "请选择城市";
      }
      if (this.currentTag == "area") {
        return "请选择区(县)";
      }
    },
    pickerAction(tag) {
      if (!this.isEdit) {
        this.$toast({
          message: "请先点击下方的“编辑”按钮！",
        });
        return false;
      }
      if (tag == "province") {
        this.pickerData = adminRegions;
      }
      if (tag == "city") {
        if (!this.formData.province.data) {
          this.$toast({
            message: "请先选择省份！",
          });
          return false;
        }
        this.pickerData = this.cityData;
      }
      if (tag == "area") {
        if (!this.formData.province.data) {
          this.$toast({
            message: "请先选择省份！",
          });
          return false;
        }
        if (!this.formData.city.data) {
          this.$toast({
            message: "请先选择城市！",
          });
          return false;
        }
        this.pickerData = this.areaData;
      }
      if (tag == "grade") {
        this.pickerData = this.gradeData;
      }
      this.currentTag = tag;
      this.showPicker = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.area-picker {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.3);
  .picker-main {
    width: 100%;
    height: 50vh;
    background: #fff;
    border-radius: 0.4rem 0.4rem 0 0;
    overflow: hidden;
    animation: showPicker 0.2s ease-in-out;
    @keyframes showPicker {
      0% {
        opacity: 0.5;
        transform: translateY(50%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .picker-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.3rem;
      height: 1rem;
      font-size: 0.28rem;
      color: #333;
      font-weight: 400;
      box-shadow: 0 0 0.1rem #ccc;
      .cancle {
        color: #ccc;
      }
      .confirm {
        color: #2db79a;
      }
    }
    .picker-body {
      width: 100%;
      height: calc(50vh - 1rem);
      overflow-y: auto;
    }
    .picker-data {
      width: 100%;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      li {
        width: 100%;
        padding: 0 0.4rem;
        height: 0.8rem;
        font-size: 0.24rem;
        color: #333;
        line-height: 0.8rem;
        font-weight: 400;
        border-bottom: 0.01rem #f1f1f1 solid;
        &:last-child {
          border: 0;
        }
      }
      .active {
        background: #2db79a;
        color: #fff;
      }
    }
  }
}
.enter-learn {
  margin: 1rem 0.3rem;
  height: 0.98rem;
  background: #2db79a;
  border-radius: 49px;
  text-align: center;
  line-height: 0.98rem;
  font-size: 0.32rem;
  font-weight: 500;
  color: #fff;
  &:active {
    opacity: 0.8;
  }
}
.complate-infs {
  padding-top: 1.08rem;
  .infs-from {
    border-top: 0.01rem #dcdcdc solid;
    padding: 0.3rem;
  }
  .infs-item {
    display: flex;
    align-items: center;
    height: 1rem;
    border-bottom: 1px #dcdcdc solid;
    .item-label {
      display: flex;
      align-items: center;
      font-size: 0.28rem;
      color: #333;
      font-weight: 400;
      word-break: break-all;
      min-width: 2.5rem;
      .require {
        color: #ff3838;
        padding-right: 0.18rem;
      }
    }
    .item-input {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      padding: 0 0.1rem;
      overflow: hidden;
      font-size: 0.28rem;
      .avator {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        overflow: hidden;
      }
      .upload-avator {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;
      }
      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
      }
      input {
        margin: 0;
        border: 0;
        width: 100%;
        height: 0.99rem;
        font-size: 0.28rem;
        text-align: right;
      }
      .arrow {
        position: relative;
        z-index: 2;
        font-size: 0.28rem;
        color: #909090;
        margin-right: -0.1rem;
      }
      .gender {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.18rem;
        height: 0.48rem;
        background: #ededed;
        border-radius: 0.24rem;
        font-size: 0.24rem;
        color: #909090;
        font-weight: 400;
        margin-left: 0.12rem;
        &-select {
          &-boy {
            color: #fff;
            background: #4892ff;
          }
          &-girl {
            color: #fff;
            background: #ff554c;
          }
        }
      }
      .send-code {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 2;
        width: 2rem;
        height: 0.56rem;
        background: #2db79a;
        border-radius: 0.28rem;
        transform: translateY(-50%);
        font-size: 0.26rem;
        color: #fff;
        text-align: center;
        line-height: 0.56rem;
        &:active {
          opacity: 0.8;
        }
      }
      .disable {
        background: #dcdcdc;
      }
    }
  }
}
</style>