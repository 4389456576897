<template>
  <div class="author-page">
    <navBar>
      <span class="title">作者简介</span>
    </navBar>
    <div
      class="author-content"
      :class="{ one: authorBase.length == 1 }"
      v-if="authorBase[currentAuthor]"
      @click="showAuthor = false"
    >
      <ul class="class-list" v-if="1 < authorBase.length">
        <li
          v-for="(item, index) in authorBase"
          :key="index"
          :class="{ active: currentAuthor == index }"
          @click="changeAuthor(index)"
        >
          <span>文章{{ index + 1 }}</span>
        </li>
      </ul>
      <div class="author-box">
        <div class="author-modle">
          <div class="blue-box">
            <div class="avator" @click.stop="showAuthor = true">
              <ul class="author-list" v-if="showAuthor">
                <li
                  v-for="(author, index) in authorList[currentAuthor]"
                  :key="`a${index}`"
                  @click.stop="selectAuthor(author)"
                >
                  <van-image
                    fit="cover"
                    class="author-img"
                    :src="setSourceUrl(author.img)"
                  ></van-image>
                  <p v-html="author.auther"></p>
                </li>
              </ul>

              <div class="bg">
                <i class="iconfont icon-zuozhejianjie"></i>
                <span>点击匹配作者</span>
              </div>
              <div class="img" v-if="authorSrc[currentAuthor]">
                <span
                  class="status"
                  :class="`${matchStatus[currentAuthor] ? 'success' : 'error'}`"
                >
                  <i
                    class="iconfont"
                    :class="`${
                      matchStatus[currentAuthor] ? 'icon-duihao' : 'icon-cuowu'
                    }`"
                  >
                  </i>
                </span>
                <van-image
                  fit="cover"
                  style="width: 100%; height: 100%"
                  :src="setSourceUrl(authorSrc[currentAuthor])"
                ></van-image>
              </div>
            </div>
            <div class="des">
              <p
                v-if="authorName[currentAuthor]"
                class="author-name"
                v-html="authorName[currentAuthor]"
              ></p>
              <p
                v-html="authorBase[currentAuthor]"
                class="author-infos base-html"
              ></p>
            </div>
          </div>
        </div>

        <div class="author-modle">
          <div class="modle-title">
            <svg-icon icon-class="wenxue" class="icon"></svg-icon>
            文学常识
          </div>

          <div class="shadow-box">
            <div
              class="sense-infs base-html"
              v-html="authorSenseContent[currentAuthor]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="answer-status"
      v-show="resultStatus"
      @click="resultStatus = false"
    >
      <van-image
        v-show="matchStatus[currentAuthor]"
        fit="cover"
        class="match-img"
        :src="successImg"
      ></van-image>
      <van-image
        v-show="!matchStatus[currentAuthor]"
        fit="cover"
        class="match-img"
        :src="errorImg"
      ></van-image>
    </div>

    <div
      class="answer-status"
      v-if="answerStatus == 2"
      @click="answerStatus = 0"
    >
      <div class="proper-answer">
        <p>
          正确答案：<span>{{ targetWords[currentAuthor][currentIndex] }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getRandom, setSourceUrl } from "@/utils/public";
export default {
  name: "authorPage",
  data() {
    return {
      successImg: require("@/assets/img/success.png"),
      errorImg: require("@/assets/img/error.png"),
      matchStatus: [],
      resultStatus: false,
      authorSrc: [],
      authorName: [],
      realityResult: "",
      showAuthor: false,
      authorList: [],
      wordsList: [],

      targetWords: [],
      authorBase: [],
      authorSenseContent: [],
      currentIndex: 0,
      answerStatus: 0,
      currentAuthor: 0,
    };
  },
  created() {},
  mounted() {
    this.addHistory();
  },
  methods: {
    addHistory() {
      this.$api
        .addLearnHistory({
          course_id: this.$route.query.courseId,
          coursechapters_id: this.$route.query.id,
          rateoflearning: "0",
          learning_info: this.$store.getters["common/getUnitNav"],
          active: this.$route.query.active,
          showToast:1
        })
        .then();
    },
    setSourceUrl,
    getRandom,
    changeAuthor(n) {
      let that = this;
      that.currentAuthor = n;
      that.showAuthor = false;
      that.$nextTick(() => {
        let inputDom = window.document.getElementsByClassName("inputDom");
        if (inputDom) {
          for (let i = 0; i < inputDom.length; i++) {
            let item = inputDom[i];
            item.onblur = function () {
              let inputVal = this.value;
              that.currentIndex = i;
              if (inputVal) {
                if (inputVal == that.targetWords[that.currentAuthor][i]) {
                  that.answerStatus = 1;
                } else {
                  that.answerStatus = 2;
                }
              }
            };
          }
        }
      });
    },
    init() {
      this.$api
        .getAuthInfo({
          chapterid: this.currentId,
        })
        .then((res) => {
          this.isLoading = false;
          let resData = res?.data || [];
          this.authorList = resData;
          for (let i = 0; i < this.authorList.length; i++) {
            let authorInfs = {};
            let authorItem = this.authorList[i];
            for (let j = 0; j < authorItem.length; j++) {
              if (authorItem[j].isauth == "1") {
                authorInfs = authorItem[j];
              }
            }
            this.computeAuthorBase(authorInfs, i);
            this.$set(this.matchStatus, i, true);
          }
        });
    },

    computeAuthorBase(parmas, i) {
      let that = this;
      let content = parmas?.content || "";
      let senseContent = parmas?.ercontent || "";
      let targetBsaeWords = "";
      let targetSenseWords = "";
      targetBsaeWords = content.match(/{(.+?)}/g) || "";
      targetBsaeWords = targetBsaeWords.toString().replace(/({)/g, "");
      targetBsaeWords = targetBsaeWords.toString().replace(/(})/g, "");
      targetBsaeWords = targetBsaeWords.split(",");

      let _targetBsaeWords=[];
      for(let i=0; i<targetBsaeWords.length; i++){
        if(targetBsaeWords[i]!=""){
          _targetBsaeWords.push(targetBsaeWords[i])
        }
      }
      targetBsaeWords=_targetBsaeWords;
      

      targetSenseWords = senseContent.match(/{(.+?)}/g) || "";
      targetSenseWords = targetSenseWords.toString().replace(/({)/g, "");
      targetSenseWords = targetSenseWords.toString().replace(/(})/g, "");
      targetSenseWords = targetSenseWords.split(",");

      let _targetSenseWords=[];
      for(let i=0; i<targetSenseWords.length; i++){
        if(targetSenseWords[i]!=""){
          _targetSenseWords.push(targetSenseWords[i])
        }
      }
      targetSenseWords=_targetSenseWords;

      content = content.replace(new RegExp("{", "g"), "<--");
      content = content.replace(new RegExp("}", "g"), "-->");

      for (let i = 0; i < targetBsaeWords.length; i++) {
        let reg = new RegExp(`<--${targetBsaeWords[i]}-->`, "g");
        content = content.replace(reg, "<---->");
      }
      senseContent = senseContent.replace(new RegExp("{", "g"), "<--");
      senseContent = senseContent.replace(new RegExp("}", "g"), "-->");
      for (let i = 0; i < targetSenseWords.length; i++) {
        let reg = new RegExp(`<--${targetSenseWords[i]}-->`, "g");
        senseContent = senseContent.replace(reg, "<---->");
      }
      that.$set(that.targetWords, i, [...targetBsaeWords, ...targetSenseWords]);
      

      let content1 = content.replace(
        /<--/g,
        '<input v-model="inputZi" class="zihao inputDom">'
      );
      let content2 = content1.replace(/-->/g, "</>");
      that.$set(that.authorBase, i, content2);

      let content3 = senseContent.replace(
        /<--/g,
        '<input v-model="inputZi" class="zihao inputDom">'
      );
      let content4 = content3.replace(/-->/g, "</>");
      that.$set(that.authorSenseContent, i, content4);
      that.$nextTick(() => {
        let inputDom = window.document.getElementsByClassName("inputDom");
        if (inputDom) {
          for (let i = 0; i < inputDom.length; i++) {
            let item = inputDom[i];
            let inputVal = "";
            item.oninput = function () {
              inputVal = this.value;
              that.currentIndex = i;
            };
            item.onblur = function () {
              if (inputVal) {
                  if (inputVal == that.targetWords[that.currentAuthor][i]) {
                    that.answerStatus = 1;
                  } else {
                    that.answerStatus = 2;
                  }
                }
            };
            item.addEventListener("keydown", (e) => {
              if (e.keyCode == 13) {
                if (inputVal) {
                  if (inputVal == that.targetWords[that.currentAuthor][i]) {
                    that.answerStatus = 1;
                  } else {
                    that.answerStatus = 2;
                  }
                }
              }
            });
          }
        }
      });
    },
    selectAuthor(author) {
      // this.authorSrc = author.img;
      // this.authorName = author.auther;
      this.showAuthor = false;
      this.resultStatus = true;
      this.matchStatus[this.currentAuthor] = author.isauth == "1";
      this.$set(this.authorName, this.currentAuthor, author.auther);
      this.$set(this.authorSrc, this.currentAuthor, author.img);
    },
  },
};
</script>
<style lang="scss">
.base-html {
  * {
    display: inline;
    font-size: 0.24rem !important;
    font-family: initial !important;
  }
  .zihao {
    border: 0;
    border-bottom: 1px #333 solid;
    outline: none;
    padding: 0 0.1rem;
    max-width: 1.5rem;
    text-align: center;
    background: none;
    font-weight: bold;
  }
  .zihao.error {
    color: #ff1e1e;
  }
  .zihao.proper {
    color: #28b396;
  }
}
</style>
<style lang="scss" scoped>
.class-list {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  background: #fff;
  font-size: 0.26rem;
  border-bottom: 0.02rem #dcdcdc solid;
  li {
    flex: 1;
    height: 0.72rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    color: #28b295;
    span {
      position: relative;
      height: 100%;
      line-height: 0.72rem;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.01rem;
        content: "";
        height: 0.01rem;
        background: #28b295;
      }
    }
  }
}

.author-page {
  position: relative;
  background: #fafafa;
  height: 100vh;
}
.answer-status {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  .proper-answer {
    width: 80%;
    padding: 0.3rem;
    background: #fff;
    border-radius: 0.1rem;
    font-size: 0.24rem;
    span {
      color: #ff1e1e;
      font-weight: bold;
    }
  }
}
.author-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  background: #fff;
  padding-top: 0.72rem;
}
.author-content.one {
  padding-top: 0;
}
.author-box {
  background: #fff;
  padding: 0.28rem;
}
.author-infos {
  text-align: justify;
  word-break: break-all;
}
.answer-status {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .match-img {
    width: 6.4rem;
    height: 7.27rem;
  }
}
.author-list {
  position: absolute;
  left: 1.4rem;
  top: 0.8rem;
  z-index: 10;
  width: 5rem;
  padding: 0.3rem 0.15rem 0 0.15rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(40, 179, 150, 0.19);
  border-radius: 0.1rem;
  li {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.15rem;
    .author-img {
      width: 2rem;
      height: 2.4rem;
      border-radius: 0.1rem;
      overflow: hidden;
    }
  }
}
.blue-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0.28rem;
  background: #e2f5f1;
  border-radius: 0.1rem;
  font-size: 0.26rem;
  color: #333;
  line-height: 2;
  margin-bottom: 0.57rem;
  .avator {
    position: relative;
    min-width: 1.86rem;
    width: 1.86rem;
    height: 2.24rem;
    background: #f1f1f1;
    border-radius: 0.1rem;
    margin-right: 0.28rem;
    .img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-radius: 0.1rem;
      overflow: hidden;
      .status {
        position: absolute;
        top: -0.3rem;
        right: -0.3rem;
        z-index: 100;
        width: 0.6rem;
        height: 0.6rem;
        transform: rotate(45deg);
        &.success {
          background: #28b396;
        }
        &.error {
          background: #ff4d4d;
        }
        .iconfont {
          position: absolute;
          left: 0.2rem;
          top: 0.25rem;
          color: #fff;
          font-size: 0.24rem;
          transform: rotate(45deg) scale(0.6);
        }
        .icon-duihao {
          transform: rotate(-45deg) scale(0.6);
        }
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 0.22rem;
      color: #333;
      .iconfont {
        color: #878787;
        font-size: 0.34rem;
      }
    }
  }
  .des {
    font-size: 0.26rem;
    line-height: 0.46rem;
    p {
      text-align: justify;
    }
    .author-name {
      font-size: 0.3rem;
      font-weight: 900;
    }
  }
}

.author-modle {
  margin-bottom: 0.25rem;
  .modle-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.3rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.4rem;
    .icon {
      font-size: 0.5rem;
      margin-right: 0.1rem;
    }
  }
  .shadow-box {
    display: block !important;
    padding: 0.28rem;
    background: #ffffff;
    border: 0.02rem solid #dcdcdc;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    color: #333;
    text-indent: 0.52rem;
    line-height: 2;
    .active {
      color: #ff1e1e;
    }
    .recite {
      position: relative;
      border-bottom: 0.01rem #909090 solid;
      color: #fff;
    }
    .recited {
      border-bottom: 0.01rem #909090 solid;
      color: #ff1e1e;
    }
  }
}
</style>