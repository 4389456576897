
import authorization from './api/authorization';
import mianIndex from './api/mianIndex';
import common from './api/common';
import userCenter from './api/userCenter';
import course from './api/course';
import learnHistory from './api/learnHistory';
import library from './api/library';
import question from './api/question'

//设置个对象，就不用一个个暴露了，直接暴露对象
let api = {
    ...authorization,
    ...mianIndex,
    ...common,
    ...userCenter,
    ...course,
    ...learnHistory,
    ...library,
    ...question
};

//暴露出这个对象
export default api;

