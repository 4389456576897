<template>
  <div class="audio-palyer">
    <audio-main :audioSrc="audioSrc" @startPlay="startPlay" @playPause="playPause"  @playEnd="playEnd" v-if="audioSrc" ref="audioMain"></audio-main>
    <div class="custom-audio" v-else>
      <div class="play-btn">
        <i :class="`iconfont ${isplay ? 'icon-zanting' : 'icon-bofang'}`"></i>
      </div>
      <div class="play-progress">
        <van-slider :disabled="!canplay" v-model="progress" />
      </div>
      <div class="play-tiem">{{ acountTime }}</div>
    </div>
  </div>
</template>

<script>
import audioMain from "@/components/audioMain.vue";
export default {
  name: "audioPlayer",
  components: {
    audioMain,
  },
  props: {
    audioSrc: {
      type: String,
      default: "",
    },
    playAudio: {
      type:Boolean,
      default:()=>{
        return false
      }
    }
  },
  watch:{
    audioSrc:function(){}
  },
  data() {
    return {
      audioDome: null,
      innerShowMain:false,
      canplay: false,
      isplay: false,

      allTime: "",

      acountTime: "00:00:00",
      progress: 0,
    };
  },
  mounted() {},
  methods: {
    startPlay(){
      this.$emit('startPlay')
    },
    playEnd(){
      this.$emit('playEnd')
    },
    playPause(){
      this.$emit('playPause')
      
    }
  },
};
</script>
<style lang="scss">
.custom-audio {
  .van-slider {
    background: #fff;
  }
  .van-slider__bar {
    background: #28b295;
  }
  .van-slider__button {
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    border: 0.02rem #28b295 solid;
  }
}
</style>
<style lang="scss" scoped>
.custom-audio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1rem;
  padding: 0 0.36rem;
  border-radius: 0.5rem;
  background: #e2f5f1;
  .play-progress {
    flex: 1;
    padding: 0 0.15rem;
    .van-slider {
      width: 100%;
    }
  }
  .play-tiem {
    font-size: 0.28rem;
    color: #28b295;
  }
  .play-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    .iconfont {
      font-size: 0.68rem;
      line-height: 1;
      color: #28b295;
    }
    .iconloading {
      font-size: 0.5rem;
      animation: circle 2s linear infinite;
    }
  }
  .disable {
    .iconfont {
      color: #909090;
    }
  }
}
.audio-media {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>