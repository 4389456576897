<template>
  <div class="video-page">
    <navBar>
      <span class="title">{{ currentTitle }}</span>
    </navBar>
    <div class="page-content">
      <div class="video-box" v-if="privateData.content">
        <template v-if="isLocked">
          <div class="video-mask" @click.stop="openLock">
            <van-image fit="cover" :src="postUrl" class="post-img"></van-image>
            <div class="video-play">
              <i class="iconfont icon-a-zuijinxuexicopy25"></i>
              <span>未解锁</span>
            </div>
          </div>
        </template>
        <template v-else>
          <video-player
            :isPlayed="isPlayed"
            :videoUrl="setSourceUrl(privateData.content)"
          ></video-player>
          <div class="video-mask" v-show="!isPlayed" @click.stop="playAction">
            <van-image
              fit="cover"
              :src="setSourceUrl(privateData.img)"
              class="post-img"
            ></van-image>
            <div class="video-play">
              <i class="iconfont icon-bofang"></i>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="infs-box">
        <div class="name">2020年陕西语文题考点精讲课</div>
        <div class="info">每周四20:00上课，共12讲</div>
        <div class="tag">
          <div class="tag-item">
            <div class="tag-icon">
              <i class="iconfont icon-shoucang"></i>
            </div>
            <span>七年级上学期初中卷</span>
          </div>
          <div class="tag-item">
            <div class="tag-icon">
              <i class="iconfont icon-bofang2"></i>
            </div>
            <span>播放798次</span>
          </div>
        </div>

        <div class="author">
          <van-image
            class="author-img"
            fit="cover"
            :src="authorImg"
          ></van-image>
          <div class="author-base">
            <div class="author-name">郑飞</div>
            <div class="goodat">10年资深研究中考题型</div>
            <div class="resume">毕业于西南大学，曾就职新东方</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import videoPlayer from "@/components/videoPlayer.vue";
import { setSourceUrl } from "@/utils/public";
export default {
  name: "unitVideo",
  components: {
    videoPlayer,
  },
  data() {
    return {
      isLocked: false,
      isPlayed: false,
      privateData: {},
    };
  },
  created() {
    this.getVideo();
  },
  mounted() {},
  methods: {
    setSourceUrl,
    getVideo() {
      this.$api
        .getCourseUnitContent({
          chapterid: this.currentId,
          type: 0,
        })
        .then((res) => {
          let resData = res.data || {};
          this.privateData = resData;
        });
    },
    openLock() {
      this.isLocked = false;
    },
    playAction() {
      if (this.privateData.content) {
        this.isPlayed = true;
      }else{
        this.$toast({
          message:'视频还未加载！'
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  .video-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    .post-img {
      width: 100%;
      height: 100%;
    }
    .video-play {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      .iconfont {
        color: #f5f5f5;
        font-size: 1rem;
      }
      span {
        font-size: 0.32rem;
        color: #f5f5f5;
      }
    }
  }
}
.infs-box {
  padding: 0.28rem;
  .name {
    font-size: 0.3rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.25rem;
  }
  .info {
    font-size: 0.24rem;
    font-weight: 400;
    color: #909090;
    margin-bottom: 0.25rem;
  }
  .tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.35rem;
  }
  .tag-item {
    height: 0.52rem;
    padding: 0.1rem 0.2rem;
    border-radius: 0.26rem;
    display: flex;
    align-items: center;
    margin-right: 0.18rem;
    .tag-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.1rem;
      .iconfont {
        font-size: 0.24rem;
        color: #fff;
      }
    }
    span {
      font-size: 0.2rem;
      font-weight: 400;
      color: #fff;
    }
    &:nth-child(1) {
      background: #ffa800;
    }
    &:nth-child(2) {
      background: #28b396;
    }
  }
}
.author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem;
  background: #f4f6fb;
  border-radius: 0.1rem;
  .author-img {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.27rem;
  }
  .author-base {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .author-name {
      font-size: 0.28rem;
      font-weight: 500;
      color: #333;
    }
    .goodat,
    .resume {
      font-size: 0.24rem;
      font-weight: 400;
      color: #909090;
    }
  }
}
</style>