<template>
  <div class="question-box question-common">
    <div
      class="question-content"
      v-if="privateData.content"
      v-html="privateData.content"
    ></div>
    <div class="question-test" v-html="privateData.testpapecontent"></div>
    <div class="draw-area">
      <canvas
        style="width: 100%; height: 5rem"
        id="canvas"
        ref="canvas"
      ></canvas>
    </div>
    <p class="notic" style="font-size: 0.28rem">
      为了有更好的视觉效果，请在纸上画图！
    </p>
    <div class="result-box" v-if="privateData.showParse">
      <div class="box-item">
        <span class="tit">【正确答案】</span>
        <span class="con" v-html="privateData.rightkey"></span>
      </div>

      <div class="box-item">
        <span class="tit">【您的答案】</span>
        <span class="con">{{ privateData.answerVal || "未作答" }}</span>
      </div>

      <div class="box-item">
        <span class="tit">【答案解析】</span>
        <span
          class="con"
          v-html="privateData.analysiscontent"
          v-if="privateData.analysiscontent"
        ></span>
        <span v-else>暂无</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionType4",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        this.privateData = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      privateData: {},
      startPoint: {},
      endPoint: {},
      eraser: true,
      canvas: null,
      cxt: null,
    };
  },
  mounted() {
    let startPoint = {
      x: 0,
      y: 0,
    };
    let endPoint = {
      x: 0,
      y: 0,
    };
    let st = this.$refs.canvas.offsetTop + 60;
    let sl = this.$refs.canvas.offsetLeft - 10;
    let flag = false;
    let canvas = document.getElementById("canvas");
    let cxt = canvas.getContext("2d");
    cxt.lineWidth = 1;
    cxt.lineCap = "butt";
    cxt.lineJoin = "round";
    cxt.strokeStyle = "rgba(255,0,0,0.5)";
    canvas.addEventListener("touchstart", function (e) {
      touchStart(e);
    });
    canvas.addEventListener("touchmove", function (e) {
      touchMove(e);
    });

    function touchStart(e) {
      //[0]表示touch第一个触碰点
      e.preventDefault();
      if (!flag) {
        startPoint = {
          x: e.touches[0].clientX - sl,
          y: e.touches[0].clientY - st,
        };
        cxt.moveTo(startPoint.x, startPoint.y);
        flag = true;
      }

      cxt.beginPath();
    }
    function touchMove(e) {
      e.preventDefault();
      if (flag) {
        endPoint = {
          x: e.touches[0].clientX - sl,
          y: e.touches[0].clientY - st,
        };
        cxt.lineTo(endPoint.x, endPoint.y);
        cxt.stroke();
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.result-box {
  font-size: 0.26rem;
  line-height: 1.6;
  .box-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.1rem;
    .tit {
      min-width: 1.8rem;
    }
    .con {
      flex: 1;
      word-break: break-all;
      overflow: auto;
      ::v-deep * {
        color: initial !important;
        font-family: initial !important;
        .math-formula {
          line-height: 1 !important;

          font-family: MathJax_Main !important;
          * {
          font-family: MathJax_Main !important;
            line-height: 1 !important;
          }
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
  }
}
.notic {
  color: #b94a48;
  padding-top: 0.15rem;
  margin-bottom: 0.5rem;
}
.question-content {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px #dcdcdc dashed;
  ::v-deep img {
    max-width: 100%;
  }
}
.question-test {
  font-size: 0.3rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  ::v-deep .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
.draw-area {
  width: 100%;
  height: 5rem;
  border: 0.01rem #dcdcdc solid;
  margin-bottom: 0.2rem;
}
</style>