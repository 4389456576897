<template>
	<div v-if="isLoading" class="loading-read">
		<i class="iconfont icon-loading"></i>
	</div>
	<div v-else class="read-by-turn" id="reda-box">
		<div class="name-author" :class="{ active: isplay && currentAudio == 0 }">
			<div class="name" v-html="currentClass.name"></div>
			<div class="author" v-html="currentClass.auther"></div>
		</div>

		<div class="class-content">
			<div class="content-box">
				<p
					v-for="(item, index) in currentClass.paragraph"
					:key="`a${index}`"
					:style="`text-indent:${currentClass.courseclass_id == 2 ? '0' : '2em'}`"
				>
					<span
						:id="`span${index}${index1}`"
						:class="{
							active: item1.status
						}"
						:key="`b${index1}`"
						v-for="(item1, index1) in item"
						v-html="replaceText(item1.text)"
					></span>
				</p>
			</div>
		</div>
		<div class="audio-box">
			<div class="audio-palyer">
				<div class="custom-audio">
					<div class="play-btn" @click="playAction">
						<i :class="`iconfont ${isplay ? 'icon-zanting' : 'icon-bofang'}`"></i>
					</div>
					<div class="play-progress">
						<van-slider
							@drag-start="changeStart"
							@input="changeMove"
							@change="changeAction"
							:max="timeMax"
							v-model="progress"
						/>
					</div>
					<div class="play-tiem">{{ audioTime }}</div>
				</div>
			</div>
		</div>

		<ul class="audio-list" v-if="0 < audioLength">
			<li v-for="(item, index) in audioList" :key="index">
				{{ item.content }}
				<audio
					class="audio-item"
					:src="setSourceUrl(item.bdttsfile)"
					:ref="`audio${index}`"
					:id="`audio${index}`"
					controlslist="nodownload"
					controls="controls"
					@ended="playEnd(index)"
				></audio>
			</li>
		</ul>
	</div>
</template>

<script>
import { html2string, setSourceUrl, deepClone } from '@/utils/public';
import { mapGetters } from 'vuex';
export default {
	name: 'readByTurn',
	computed: {
		...mapGetters({
			famousReadData: 'famousRead/getCurrentFamousRead'
		})
	},
	watch: {
		famousReadData: function (val) {
			this.initRead();
			this.getCurrentClass(this.deepClone(val));
		},

		isplay: function (val) {
			//监听是否在播放
			if (val) {
				this.getProgress();
			}
		},
		currentAudio: function (val) {
			// 监听当前播放语音片段，设置当前播放样式，及界面自动滚动
			this.getProgress();
			if (0 < val) {
				//朗读段落（除去标题）
				let index1 = 0;
				let index2 = 0;
				let text = this.audioList[val].text;
				let paragraph = this.currentClass.paragraph;
				for (let i = 0; i < paragraph.length; i++) {
					for (let j = 0; j < paragraph[i].length; j++) {
						if (paragraph[i][j].text == text) {
							paragraph[i][j].status = true;
							index1 = i;
							index2 = j;
						} else {
							paragraph[i][j].status = false;
						}
					}
				}
				this.$set(this.currentClass, 'paragraph', paragraph);
				let redaBox = document.getElementById('reda-box');
				let boxHeight = redaBox.offsetHeight;
				let currentSpan = document.getElementById(`span${index1}${index2}`);
				let spanHeight = currentSpan.offsetHeight;
				let st = currentSpan.offsetTop;
				redaBox.scrollTo({
					top: st - boxHeight / 2 + spanHeight,
					behavior: 'smooth'
				});
			}
		}
	},
	data() {
		return {
			isLoading: true,
			device: '',
			autoplay: false,
			currentClass: {},
			audioObj: [],
			audioList: [],
			currentAudio: 0,
			isplay: false,
			audioTime: '00:00:00',
			progress: 0,
			timeNum: 0,
			timeMax: 100,
			audioLength: -1,
			readTime: 0,
			durationArr: []
		};
	},
	created() {
		this.device = window.localStorage.getItem('device');
		this.initRead();
		this.getCurrentClass(this.deepClone(this.famousReadData));
		if (this.device == 'ios') {
			this.autoplay = true;
		}
	},
	mounted() {},
	destroyed() {},
	methods: {
		setSourceUrl,
		html2string,
		deepClone,
		initRead() {
			//重置data
			this.device = window.localStorage.getItem('device');
			this.autoplay = false;
			this.currentClass = {};
			this.audioObj = [];
			this.audioList = [];
			this.currentAudio = 0;
			this.isplay = false;
			this.audioTime = '00:00:00';
			this.progress = 0;
			this.timeNum = 0;
			this.timeMax = 100;
			this.audioLength = -1;
			this.readTime = 0;
			this.durationArr = [];
		},
		replaceText(str) {
			return str.replace(new RegExp('&lt;br/&gt;', 'g'), '<br/>');
		},
		getTime(time) {
			//格式化秒
			// 转换为式分秒
			let h = parseInt((time / 60 / 60) % 24);
			h = h < 10 ? '0' + h : h;
			let m = parseInt((time / 60) % 60);
			m = m < 10 ? '0' + m : m;
			let s = parseInt(time % 60);
			s = s < 10 ? '0' + s : s;
			// 作为返回值返回
			let _tiem = `${h}:${m}:${s}`;
			return _tiem;
		},
		changeAction(val) {
			this.isplay = false;
			this.pauseAllAudio();
			let at = 0;
			for (let i = 0; i < this.durationArr.length; i++) {
				at = at + this.durationArr[i];
				if (0 < at - val) {
					this.currentAudio = i;
					break;
				}
			}
			this.audioTime = this.getTime(Math.floor(this.timeNum - val));
			this.progress = at - this.durationArr[this.currentAudio];
			setTimeout(() => {
				this.audioObj[this.currentAudio].play();
				this.isplay = true;
			}, 0);
		},
		changeMove(val) {
			this.isplay = false;
			this.pauseAllAudio();
			this.audioTime = this.getTime(Math.floor(this.timeNum - val));
		},
		changeStart() {
			this.isplay = false;
			this.pauseAllAudio();
		},
		pauseAllAudio() {
			for (let i = 0; i < this.audioLength; i++) {
				let audioItem = document.getElementById(`audio${i}`);
				audioItem.pause();
			}
		},
		playEnd(i) {
			//每段播放完毕
			this.readTime = 0;
			if (this.currentAudio < this.audioLength - 1) {
				this.currentAudio = ++i;
				if (this.audioList[this.currentAudio].bdttsfile.indexOf('.mp3') < 1) {
					this.currentAudio = ++this.currentAudio;
				}
				let audioItem = document.getElementById(`audio${this.currentAudio}`);
				audioItem.muted = false;
				this.audioObj[this.currentAudio].play();
			} else {
				this.isplay = false;
				this.currentAudio = 0;
				this.progress = 0;
				this.resetParagraph();
			}
		},
		playAction() {
			//播放事件

			if (this.isplay) {
				this.isplay = false;
				this.pauseAllAudio();
			} else {
				this.isplay = true;
				if (this.audioObj[this.currentAudio].src.indexOf('.mp3') < 1) {
					this.currentAudio = ++this.currentAudio;
				}
					this.audioObj[this.currentAudio].muted = false;
					this.audioObj[this.currentAudio].play();
				
			}
		},
		getCurrentClass(val) {
			//获取当前课文数据
			this.audioList = [];
			let titleObject = [];
			let mjldcon = val?.mjldcont || null;
			if (!mjldcon) {
				return false;
			}
			if (Array.isArray(mjldcon)) {
				for (let i = 0; i < mjldcon?.length; i++) {
					if (mjldcon[i].istitle) {
						titleObject = mjldcon[i];
						break;
					}
				}
			} else {
				for (let key in mjldcon) {
					if (mjldcon[key].istitle) {
						titleObject = mjldcon[key];
						break;
					}
				}
			}

			let audioList = [].concat.apply([], val.paragraph);
			if (titleObject.audioduration) {
				audioList.unshift(titleObject);
			}

			this.audioList = audioList;
			this.audioLength = audioList.length;
			this.currentClass = val;
			this.resetParagraph();
			this.isLoading = false;
			this.$nextTick(() => {
				this.getAudoiObj();

				let duration = 0;
				let _durationArr = [];
				for (let i = 0; i < this.audioLength; i++) {
				
						duration = duration + audioList[i].audioduration || 0;
					_durationArr.push(audioList[i].audioduration);
				}
				this.timeNum = Math.floor(duration);
				this.durationArr = _durationArr;
				this.audioTime = this.getTime(Math.floor(duration));
				this.timeMax = Math.floor(duration);
			});
		},
		resetParagraph() {
			// 重置段落文本样式
			let paragraph = this.currentClass.paragraph;
			if (paragraph) {
				for (let i = 0; i < paragraph?.length; i++) {
					for (let j = 0; j < paragraph[i].length; j++) {
						paragraph[i][j].status = false;
					}
				}
				this.$set(this.currentClass, 'paragraph', paragraph);
			}
		},
		getProgress() {
			//计算播放进度
			let audioItem = document.getElementById(`audio${this.currentAudio}`);
			audioItem.addEventListener('timeupdate', () => {
				let currentTime = audioItem.currentTime;
				let timeStep = currentTime - this.readTime;
				this.readTime = currentTime;
				this.progress = this.progress + timeStep;
				let timeNum = this.timeNum - this.progress;
				this.audioTime = this.getTime(0 < timeNum ? timeNum : 0);
			});
		},

		getAudoiObj() {
			//获取语音对象

			let mjldcont = this.currentClass.mjldcont;
			let handle = mjldcont instanceof Array;
			if (!handle) {
				let _arr = [];
				Object.keys(mjldcont).forEach((item) => {
					let _item = mjldcont[item];
					_arr.push(_item);
				});
				mjldcont = _arr;
			}
			for (let i = 0; i < mjldcont.length; i++) {
				if(this.$refs[`audio${i}`]!= undefined){
					this.$set(this.audioObj, i, this.$refs[`audio${i}`][0]);
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.loading-read {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.iconfont {
		font-size: 1.2rem;
		color: #28b295;
		animation: loading 2s linear infinite;
	}
	@keyframes loading {
		0% {
			transform: rotate(0);
			transform-origin: center center;
		}
		100% {
			transform: rotate(360deg);
			transform-origin: center center;
		}
	}
}
.custom-audio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 1rem;
	padding: 0 0.36rem;
	border-radius: 0.5rem;
	background: #e2f5f1;
	::v-deep .van-slider {
		background: #fff;
	}
	::v-deep .van-slider__bar {
		background: #28b295;
	}
	::v-deep .van-slider__button {
		width: 0.16rem;
		height: 0.16rem;
		border-radius: 50%;
		border: 0.02rem #28b295 solid;
	}
	.play-progress {
		flex: 1;
		padding: 0 0.15rem;
		.van-slider {
			width: 100%;
		}
	}
	.play-tiem {
		text-align: right;
		width: 1.2rem;
		font-size: 0.28rem;
		color: #28b295;
	}
	.play-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		border-radius: 50%;
		overflow: hidden;
		width: 0.64rem;
		height: 0.64rem;
		.iconfont {
			font-size: 0.78rem;
			line-height: 1;
			color: #28b295;
		}
		.iconloading {
			font-size: 0.68rem;
			animation: circle 2s linear infinite;
		}
		@keyframes circle {
			0% {
				transform: rotate(0);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	.disable {
		.iconfont {
			color: #909090;
		}
	}
}
.audio-list {
	position: fixed;
	left: 0;
	top: 0;
	z-index: -100;
	visibility: hidden;
}
.audio-box {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	padding: 0.3rem;
	background: #fff;
	.readall-btn {
		position: absolute;
		left: 0.28rem;
		top: 0.28rem;
		width: 1rem;
		height: 1rem;
		z-index: 100;
	}
}
.read-by-turn {
	padding: 0 0.3rem 1.8rem 0.3rem;
	height: 100%;
	overflow-y: auto;
	scroll-behavior: smooth;
	.name-author {
		text-align: center;
		margin-bottom: 0.5rem;
		.name {
			display: flex;
			justify-content: center;
			font-size: 0.35rem;
			font-weight: bold;
			margin-bottom: 0.1rem;
			flex-wrap: wrap;
		}
		.author {
			display: flex;
			justify-content: center;
			font-size: 0.24rem;
			font-weight: 400;
		}
	}
	.name-author.active {
		color: #ff1e1e;
	}
	.class-content {
		display: flex;
		justify-content: center;
		font-size: 0.26rem;
		line-height: 1.6;
		color: #1c1c1c;
		.active {
			color: #ff1e1e;
		}
	}
}
</style>
