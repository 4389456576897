<template>
  <div
    class="famous-read-box"
    :class="{ 'more-class': 1 < Object.keys(classData).length }"
  >
    <navBar>
      <span class="title">名家朗读</span>
    </navBar>
    <div class="read-box-con">
      <ul class="class-list" v-if="1 < Object.keys(classData).length">
        <li
          v-for="(item, tag) in classData"
          :key="tag"
          :class="{ active: currentClass == tag }"
          @click="changeClass(tag)"
        >
          <span> 文章 {{ Number(tag) + 1 }}</span>
        </li>
      </ul>

      <div class="read-type">
        <customTabNav
          :tabList="tabList"
          :selectTab="currentTab"
          @change="tabChange"
        ></customTabNav>
      </div>

      <div class="famous-read-route">
        <router-view  style="mini-height:100%"/>
      </div>
    </div>
  </div>
</template>

<script>
import customTabNav from "@/components/customTabNav.vue";
export default {
  name: "famousRead",
  components: { customTabNav },
  data() {
    return {
      currentClass: 0,
      currentTab: 0,
      classData: {},
      tabList: [
        {
          name: 0,
          text: "名家朗读",
        },
        {
          name: 1,
          text: "单句跟读",
        },
        {
          name: 2,
          text: "背诵练习",
        },
      ],
    };
  },
  created() {
    this.getCourseReadContent();
    this.currentTab = this.$route.query.type;
    this.$store.commit("famousRead/setFamousReadData", []);
    this.$store.commit("famousRead/setCurrentFamousRead", {});
  },
  mounted() {
    this.addHistory()
  },
  methods: {
    addHistory() {
      this.$api
        .addLearnHistory({
          course_id: this.$route.query.courseId,
          coursechapters_id: this.$route.query.id,
          rateoflearning: "0",
          learning_info: this.$store.getters["common/getUnitNav"],
          active: this.$route.query.active,
          showToast:1
        })
        .then();
    },
    getCourseReadContent() {
      this.$api
        .getCourseReadContent({
          mjldid: this.$route.query.id,
          showToast:1
        })
        .then((res) => {
          let resData = res.data || {};
          let famousReadData = {};
          Object.keys(resData).forEach((item, index) => {
            let objItem = {
              name: resData[item].toptitle || "",
              preview: resData[item].topyuxi || "",
              topimg: resData[item].topimg || "",
              mjldcont: resData[item].mjldcont || [],
              paragraph: this.computeContent({
                arr: resData[item].mjldcont || [],
              }),
              toptitle: resData[item].toptitle || "",
              auther: resData[item].auther || "",
              courseclass_id: resData[item].courseclass_id||""
            };
            famousReadData[index] = objItem;
            this.$set(this.classData, index, objItem);
          });
          this.$store.commit("famousRead/setFamousReadData", famousReadData);
          this.$store.commit(
            "famousRead/setCurrentFamousRead",
            famousReadData[0]
          );
        });
    },
    computeContent(parmas) {
      let paragraph = [];
      let privateParmas = {};
      privateParmas.type = parmas.type || 1;
      privateParmas.arr =
        parmas.arr || this.bookObj[this.currentClass].mjldcont;
      privateParmas.num = parmas.num || 0;
      let mjldcont = privateParmas.arr;
      if (mjldcont instanceof Object) {
        let _mjldcont = [];
        Object.keys(mjldcont).forEach((item) => {
          _mjldcont.push(mjldcont[item]);
        });
        mjldcont = _mjldcont;
      }
      if (privateParmas.type == 1) {
        mjldcont.forEach((item) => {
          if (item.istitle != 1) {
            let cancleReplace = false;
            if (item.content.indexOf("style=") < 0) {
              cancleReplace = true;
            }
            paragraph[item.paragraph - 1] = paragraph[item.paragraph - 1] || [];
            paragraph[item.paragraph - 1].push({
              text: cancleReplace
                ? item.content
                    .replace(new RegExp("<p>", "g"), "<span>")
                    .replace(new RegExp("</p>", "g"), "</span>")
                    .replace(new RegExp("&lt;br/&gt;", "g"), "<br/>")
                    
                : item.content,
              status: false,
              recite: false,
              bdttsfile:item.bdttsfile,
              audioduration:item.audioduration,
              muted:true
            });
          }
        });
      }

      if (privateParmas.type == 2) {
        let idxArr = [];
        let bookObjParagraph = this.deepClone(
          this.paragraphSource[this.currentClass]
        );
        bookObjParagraph.forEach((item, index) => {
          item.forEach((item, idx) => {
            idxArr.push([index, idx]);
          });
        });
        let ran = -1;
        for (let i = 0; i < privateParmas.num; i++) {
          ran = this.getRandom(0, idxArr.length, ran);
          let item = idxArr[ran];
          if (bookObjParagraph[item[0]].length) {
            if (bookObjParagraph[item[0]][item[1]]) {
              bookObjParagraph[item[0]][item[1]].recited = false;
              bookObjParagraph[item[0]][item[1]].recite = true;
              bookObjParagraph[item[0]][item[1]].isRecite = true;
            }
          }
        }
        paragraph = bookObjParagraph;
      }
      return paragraph;
    },
    changeClass(n) {
      let famousReadData = this.$store.getters["famousRead/getFamousReadData"];
      this.$store.commit(
        "famousRead/setCurrentFamousRead",
        famousReadData[Number(n)]
      );
      this.currentClass = n;
      this.currentTab = 0;
    },
    tabChange(val) {
      switch (val) {
        case 0: {
          this.$router.replace({
            path: "/famousRead",
            query: {
              name: this.$route.query.name,
              id: this.$route.query.id,
              type: 0,
              class: this.$route.query.class,
            },
          });
          break;
        }
        case 1: {
          this.$router.replace({
            path: "/readBySigin",
            query: {
              name: this.$route.query.name,
              id: this.$route.query.id,
              type: 1,
              class: this.$route.query.class,
            },
          });
          break;
        }
        case 2: {
          this.$router.replace({
            path: "/reciteAll",
            query: {
              name: this.$route.query.name,
              id: this.$route.query.id,
              type: 2,
              class: this.$route.query.class,
            },
          });
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.famous-read-box {
  height: 100vh;
  background: #fafafa;
  .read-box-con {
    position: fixed;
    left: 0;
    right: 0;
    top: 1.28rem;
    bottom: 0;
    background: #fff;
  }
  .read-type {
    position: absolute;
    left: 0.3rem;
    right: 0.3rem;
    top: 0.3rem;
  }
  .famous-read-route {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 1.3rem;
  }
  .class-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    background: #fff;
    font-size: 0.26rem;
    border-bottom: 0.02rem #dcdcdc solid;
    li {
      flex: 1;
      height: 0.72rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active {
      color: #28b295;
      span {
        position: relative;
        height: 100%;
        line-height: 0.72rem;
        &::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -0.01rem;
          content: "";
          height: 0.01rem;
          background: #28b295;
        }
      }
    }
  }
}
.famous-read-box.more-class {
  .read-type {
    top: 1rem;
  }
  .famous-read-route {
    top: 2rem;
  }
}
</style>