<template>
  <div class="video-player">
    <video
      class="video"
      controls="controls"
      controlslist="nodownload"
      ref="video"
      id="video"
    ></video>
    <div class="mask" v-if="showMask"></div>
  </div>
</template>

<script>
export default {
  name: "videoPlayer",
  props: ["videoUrl", "isPlayed", "isPause"],
  components: {},
  watch: {
    videoUrl:function(val){
      this.$refs.video.src =val;
    },
    isPlayed: function (val) {
      if (val) {
        this.$refs.video.src = this.videoUrl;
        let video = document.getElementById("video");
        if (video !== undefined) {
          video.play();
          this.showMask = false;
        }
      }
    },
  },
  data() {
    return {
      showMask: true,
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
  height: 4.25rem;
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.video {
  width: 100%;
  height: 4.25rem;
}
</style>