<template>
  <div class="inner-page user-page">
    <div class="user-main" :style="`backgroundImage:url(${mainBg})`">
      <div class="user-infs" @click="goEdit">
        <div class="avator">
          <van-image
            fit="cover"
            :src="userInfs.avatar"
            class="img"
            @click.stop="previewAvator"
          ></van-image>
        </div>

        <div class="user-base">
          <div class="user-name">{{ userInfs.nickname }}</div>
          <div class="user-num">学号：{{ userInfs.username }}</div>
        </div>
      </div>

      <ul class="user-learning">
        <!-- <li>
          <div class="num">
            <span>{{ userInfs.studyduration || 0 }}</span
            >分钟
          </div>
          <div class="moudle">累计学习</div>
        </li> -->
        <li>
          <div class="num">
            <span>{{ userInfs.studyhours || 0 }}</span
            >课时
          </div>
          <div class="moudle">已学课程</div>
        </li>
        <li>
          <div class="num">
            <span>{{ userInfs.surpasspeers || 0 }}</span>
          </div>
          <div class="moudle">超越同级</div>
        </li>
      </ul>
    </div>

    <ul class="user-menu">
      <li
        :class="{ isLink: item.viewType != 2 }"
        v-for="(item, index) in menuData"
        :key="`b${index}`"
        @click="goNext(item.viewType, item.url)"
      >
        <van-image class="icon" fit="cover" :src="item.icon"></van-image>
        <div class="text">{{ item.text }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { removeToken } from "@/utils/token";
import { setSourceUrl } from "@/utils/public";
export default {
  name: "userPage",
  data() {
    return {
      avator: "",
      mainBg: require("@/assets/img/userBg.png"),
      //userInfs: {},
      defaultId: 2000,
      menuData: [
      {
          icon: require("@/assets/icon/version.png"),
          viewType: 0,
          text: "教材版本",
          url: "/version",
        },
        {
          icon: require("@/assets/icon/cuotiben.png"),
          text: "错题本",
          viewType: 0,
          url: "/errorBook",
        },
        {
          icon: require("@/assets/icon/jilu.png"),
          text: "我的学习记录",
          viewType: 0,
          url: "/moreHistory",
        },
        {
          icon: require("@/assets/icon/password.png"),
          viewType: 0,
          text: "更改密码",
          url: "/changePassword",
        },
        {
          icon: require("@/assets/icon/serve.png"),
          viewType: 1,
          text: "售后服务",
          url: "server",
        },
        
        {
          icon: require("@/assets/icon/exit.png"),
          viewType: 2,
          text: "退出登录",
          url: "",
        },
      ],
    };
  },
  computed: {
    userInfs: {
      get: function () {
        return this.$store.getters["userCenter/getUserInfs"];
      },
      set: function (data) {
        this.$store.commit("userCenter/setUserInfs", data);
      },
    },
  },
  created() {
    this.userInfs = this.dataList;
    this.initUser();
  },
  methods: {
    setSourceUrl,
    removeToken,
    initUser() {
      this.$api.getUserInfs().then((res) => {
        let resData = res.data || {};
        //this.userInfs = resData;
        this.$store.commit("userCenter/setUserInfs", resData);
      });
    },
    previewAvator() {
      this.$eventBus.$emit("showPreview", {
        handle: true,
        imgList: [this.userInfs.avatar],
      });
    },
    goEdit() {
      this.$router.push({
        path: "/editUser",
        query: {
          active: 2,
        },
      });
    },
    goNext(type, url) {
      switch (type) {
        case 0: {
          if (url) {
            this.$router.push({
              path: url,
              query: {
                active: 2,
              },
            });
          }
          break;
        }
        case 1: {
          if (url == "server") {
            this.$eventBus.$emit("showServer", true);
          }
          break;
        }
        case 2: {
          this.$api.userSignout().then((res) => {
            if (res.code) {
              window.localStorage.clear();
              window.location.reload();
            }
          });
          break;
        }
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-page {
  overflow-y: auto;
  bottom: 1rem;
  padding-bottom: 1rem;
}
.user-main {
  position: relative;
  width: 100%;
  height: 4.38rem;
  background-size: cover;
  background-position: 0 -1rem;
  margin-bottom: 0.4rem;
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  &::before {
    position: absolute;
    right: 0.28rem;
    top: 28%;
    transform: translateY(-50%) rotate(180deg);
    content: "\e600";
    color: #fff;
  }
  .user-infs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0.28rem;
    right: 0.28rem;
    top: 28%;
    transform: translateY(-50%);
    .user-base {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #fff;
      .user-name {
        font-size: 0.36rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
      }
      .user-num {
        font-size: 0.26rem;
        font-weight: 400;
      }
    }
    .avator {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.32rem;
      height: 1.31rem;
      border-radius: 50%;
      margin-right: 0.28rem;
      border: 0.05rem #fff solid;
      overflow: hidden;
      .img {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
  .user-learning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0.28rem;
    right: 0.28rem;
    bottom: 0;
    height: 1.98rem;
    padding: 0 0.48rem;
    background: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(160, 160, 160, 0.35);
    border-radius: 0.2rem;
    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .num {
        font-weight: 400;
        color: #909090;
        font-size: 0.22rem;
        margin-bottom: 0.16rem;
        span {
          font-weight: 600;
          font-size: 0.42rem;
          color: #2db79a;
          padding: 0 0.05rem;
        }
      }
      .moudle {
        font-weight: 400;
        color: #909090;
        font-size: 0.24rem;
      }
    }
  }
}
.user-menu {
  padding: 0 0 0 0.32rem;
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0.25rem 0.28rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #333;
    height: 0.97rem;
    border-bottom: 0.01rem #dcdcdc solid;
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;

    &:active {
      background: rgba(0, 0, 0, 0.01);
    }
    .icon {
      width: 0.42rem;
      height: 0.42rem;
      margin-right: 0.18rem;
    }
    .text {
      font-size: 0.30rem;
    }
  }
  .isLink {
    &::before {
      position: absolute;
      right: 0.28rem;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      content: "\e600";
      color: #909090;
    }
  }
}
.user-page {
  top: 0;
}
</style>