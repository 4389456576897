<template>
  <div class="common-content">
    <navBar>
      <span>{{ currentTitle }}</span>
      <template v-slot:right v-if="computeTotal">
        <template
          v-if="
            !isLoading &&
            questionData.list.length &&
            !['8', '9', '10', '11', '12', '13', '14'].includes(
              questionData.list[currentQuestion].type
            )
          "
          ><div
            class="common-step"
            v-if="0 < currentType && computeTotal && 1 < computeTotal"
          >
            <span class="current">{{ currentQuestionModle + 1 }}</span>
            <span class="total">/{{ accountRequest }}</span>
          </div></template
        >

        <template v-else
          ><div
            class="common-step"
            v-if="0 < currentType && computeTotal && 1 < computeTotal"
          >
            <span class="current">{{ currentQuestion + 1 }}</span>
            <span class="total">/{{ computeTotal }}</span>
          </div></template
        >
      </template>
    </navBar>
    <template v-if="!isLoading">
      <template v-if="!isEmpty">
        <div class="common-progress" v-if="0 < currentType && 1 < computeTotal">
          <div
            class="con"
            :style="`width:${((currentQuestion + 1) / computeTotal) * 100}%`"
          ></div>
        </div>

        <div
          class="image-text-content"
          :class="{
            isContent: currentType == 0 && contentData.content,
            isQuestion: 0 < currentType && questionData.list,
            isWordsQuestion: isWordsQuestion,
            more: 1 < jiexiData.length,
          }"
        >
          <template v-if="currentType == 0">
            <template v-if="currentTitle == '原文解析'">
              <template v-if="jiexiData.length">
                <!-- 内容为图文混排 -->
                <van-image
                  fit="cover"
                  :src="setSourceUrl(jiexiData[currentClass].img)"
                  class="class-image"
                ></van-image>
                <div class="class-title">
                  {{ jiexiData[currentClass].title }}
                </div>
                <div class="class-auther">
                  {{ jiexiData[currentClass].auther }}
                </div>
                <div class="class-content" id="classContent">
                  <div class="con">
                    <p
                      v-for="(paragraph, index) in jiexiData[currentClass]
                        .content"
                      :key="`paragraph${index}`"
                      v-html="paragraph"
                    ></p>
                  </div>
                </div>
              </template>

            </template>

            <template v-else>
              <template v-if="contentData.content || contentData.videourl">
                <!-- 内容为图文混排 -->
                <div class="isContent" v-if="contentData.videourl" id="content">
                  <div class="video-box" style="margin-bottom: 0.3rem">
                    <video-player
                      :isPlayed="isPlayed"
                      :videoUrl="setSourceUrl(contentData.videourl)"
                    ></video-player>
                    <div
                      class="video-mask"
                      v-show="!isPlayed"
                      @click.stop="playAction"
                    >
                      <van-image
                        fit="cover"
                        :src="setSourceUrl(contentData.img)"
                        class="post-img"
                      ></van-image>
                      <div class="video-play">
                        <i class="iconfont icon-bofang"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="video-des" id="content">
                  <div class="title-box" v-if="contentData.title">
                    《{{ html2string(contentData.title) }}》
                  </div>
                  <div class="img-text-box">
                    <van-swipe
                      class="my-swipe"
                      :class="{ textBody: currentTitle == '文章结构' }"
                      indicator-color="#20a287"
                    >
                      <van-swipe-item
                        v-for="(img, index) in imageData"
                        :key="index"
                      >
                        <div
                          class="image-box"
                          :class="{ textBody: currentTitle == '文章结构' }"
                        >
                          <van-image
                            fit="scale-down"
                            :src="img"
                            @click="previewImg(index)"
                          ></van-image>
                        </div>
                      </van-swipe-item>
                    </van-swipe>
                    <div class="text" v-html="contentData.content"></div>
                  </div>
                </div>
              </template>

            </template>
          </template>

          <!-- 内容为试题 -->
          <div class="isquestion" v-else-if="0 < currentType">
            <div
              v-if="
                !['8', '9', '10', '11', '12', '13', '14'].includes(
                  questionData.list[currentQuestion].type
                )
              "
              class="question-content"
              v-html="questionData.list[currentQuestion].content"
            ></div>
            <template
              v-if="questionData.list ? questionData.list.length : false"
            >
              <questionType
                :questionData="questionData.list[currentQuestion]"
              ></questionType>
              <div
                class="qusetion-parse"
                v-if="questionData.list[currentQuestion].showParse"
              >
                <div
                  class="question-proper"
                  v-if="questionData.list[currentQuestion].rightkey"
                >
                  <span class="tit">答案：</span>
                  <span
                    v-html="
                      `${html2string(
                        questionData.list[currentQuestion].rightkey
                      )}`
                    "
                  ></span>
                </div>
                <div
                  class="question-proper"
                  v-if="!['文言文虚词'].includes(moudleName)"
                >
                  <span class="tit">解析：</span>
                  <span
                    v-html="
                      `${
                        html2string(
                          questionData.list[currentQuestion].analysiscontent
                        ) || '略'
                      }`
                    "
                  ></span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template v-if="!isWordsQuestion">
          <div class="question-handle" v-if="0 < currentType && computeTotal">
            <div class="btn" @click="changeQuestion(1)" v-if="1 < computeTotal">
              上一题
            </div>
            <span v-else></span>
            <div class="btn" @click="changeQuestion(0)" v-if="1 < computeTotal">
              下一题
            </div>
            <div
              class="btn jiexi-btn"
              v-if="
                !exceptParse.includes(questionData.list[currentQuestion].type)
              "
              @click="showParseAction"
            >
              查看解析
            </div>
          </div>
        </template>
      </template>
    </template>
    <audio
      :src="errorVoice"
      controlslist="nodownload"
      controls="controls"
      ref="errorAudio"
      class="status-vioce"
    ></audio>

    <audio
      :src="properVoice"
      controlslist="nodownload"
      controls="controls"
      ref="properAudio"
      class="status-vioce"
    ></audio>

    <ul class="class-list" v-if="1 < jiexiData.length">
      <li
        v-for="(item, index) in jiexiData"
        :key="index"
        :class="{ active: currentClass == index }"
        @click="changeClass(index)"
      >
        <span> 文章{{ Number(index) + 1 }}</span>
      </li>
    </ul>

    <div
      class="select-transelate-mudule"
      @click="changeTranselateModule"
      v-if="currentSuject.name == '英语' && currentTitle == '练习'"
    >
      {{ translateModule == 1 ? "汉/英" : "英/汉" }}
    </div>
  </div>
</template>

<script>
import videoPlayer from "@/components/videoPlayer.vue";
import questionType from "@/views/commonContent/questionType.vue";
import {
  setSourceUrl,
  html2string,
  deepClone,
  computeOptionjson,
  formatAddPointWords,
} from "@/utils/public";
import { ImagePreview } from "vant";
export default {
  name: "commonContent",
  components: {
    videoPlayer,
    questionType,
  },
  computed: {
    computeTotal: function () {
      let len = this.questionData?.list?.length || 0;
      this.$store.commit("common/setTotalQuestion", len);
      return len;
    },
    isWordsQuestion: function () {
      let handler = false;

      if (this.questionData?.list?.length) {
        if (
          this.questionData.list[this.currentQuestion].type == 12 ||
          this.questionData.list[this.currentQuestion].type == 13
        ) {
          handler = true;
        }
      }
      return handler;
    },
  },
  watch: {
    accountRequest: function (val) {
      if (this.totalRequest == val) {
        this.isLoading = false;
        this.selectTranselateModule();
        if (this.questionData.length < 1) {
          this.isEmpty = true;
        }
      }
    },
    currentQuestion: function (val) {
      let num = this.questionData.list[val]?.parentNum;
      this.currentQuestionModle = num;
    },
  },
  data() {
    return {
      errorVoice: require("@/assets/audio/error.mp3"),
      properVoice: require("@/assets/audio/proper.mp3"),
      errorAudio: null,
      properAudio: null,
      mergeData: false,
      isPlayed: false,
      isEmpty: false,
      isLoading: true,
      tzg: require("@/assets/img/tzg.png"),
      currentSuject: "",
      currentQuestion: 0,
      currentQuestionModle: 0,
      contentData: {},
      questionData: [],
      dataSource: [],
      exceptParse: ["8", "9", "11"],
      unknowId: "",
      totalRequest: 0,
      accountRequest: 0,
      imageData: [],
      jiexiData: [],
      currentClass: 0,
      moudleName: "",
      translateModule: 1, //1 汉译英 2 英译汉
    };
  },
  created() {
    this.currentSuject = this.$store.getters["common/getCurrentSuject"];
    this.currentType = this.$route.query.type || 0; //0 是图文内容， 1是普通题目，100是错误题目
    this.moudleName = this.$route.query.name;
    this.initCommonData();
  },
  mounted() {
    this.properAudio = this.$refs["properAudio"];
    this.errorAudio = this.$refs["errorAudio"];
    this.$eventBus.$on("changeQuestion", (val) => {
      this.currentQuestion = val;
    });
    this.$eventBus.$on("repeatOperate", () => {
      this.currentQuestion = 0;
      this.questionData = this.dataSource;
    });
    this.$eventBus.$on("unKnowWord", (val) => {
      this.currentQuestion = val;
      let dataList = this.questionData.list;
      let unKuowObj = dataList[val];
      let unknowNum = ++unKuowObj.unknowNum;
      let step = 0;
      step = val + unknowNum + 1;
      if (dataList.length < step) {
        step = dataList.length;
      }
      unKuowObj.unknowNum = unknowNum;
      dataList.splice(val, 1);
      dataList.splice(step, 0, unKuowObj);

      this.$set(this.questionData, "list", dataList);
    });
  },
  methods: {
    setSourceUrl,
    html2string,
    deepClone,
    computeOptionjson,
    formatAddPointWords,
    changeTranselateModule() {
      if (this.translateModule == 1) {
        this.translateModule = 2;
      } else {
        this.translateModule = 1;
      }
      this.selectTranselateModule();
    },
    selectTranselateModule() {
      let dataList = this.dataSource.list;
      let targetList = [];
      console.log(dataList);
      dataList.forEach((item) => {
        if (item.chineseenglish == this.translateModule) {
          targetList.push(item);
        }
      });

      this.$set(this.questionData, "list", targetList);
    },
    changeClass(n) {
      this.currentClass = n;
      this.$nextTick(() => {
        this.initEvent();
      });
    },
    previewImg(index) {
      ImagePreview({
        images: this.imageData,
        startPosition: index,
      });
    },
    playAction() {
      if (this.contentData.videourl) {
        this.isPlayed = true;
      } else {
        this.$toast({
          message: "视频还未加载！",
        });
      }
    },

    initCommonData() {
      if (this.currentType == 0) {
        this.ininCommonContent();
      } else {
        if (this.currentType == 100) {
          this.initCommonErrorQuestion();
        } else {
          this.initCommonQuestion();
        }
      }
    },

    ininCommonContent() {
      this.isLoading = true;
      this.$api
        .getCourseUnitContent({
          chapterid: this.currentId,
          type: this.$route.query.type,
          iscourse: this.$route.query.iscourse || 1,
        })
        .then((res) => {
          let resData = res.data || {};
          if (this.currentTitle == "原文解析") {
            resData.forEach((item) => {
              item.content = this.formatContent(item.content);
            });

            this.jiexiData = resData;
            this.$nextTick(() => {
              this.initEvent();
            });
          } else {
            if (resData.img) {
              this.imageData = [this.setSourceUrl(resData.img)];
            }
            let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
            let srcReg = /src=['"]?([^'"]*)['"]?/i; // 匹配图片中的src
            let arr = resData.content.match(imgReg); //筛选出所有的img
            if (arr) {
              for (let i = 0; i < arr.length; i++) {
                let src = arr[i].match(srcReg)[1];
                this.$set(this.imageData, i, src);
              }
              resData.content = resData.content.replace(imgReg, "");
            }
            this.contentData = resData;
          }
          this.isLoading = false;
        });
    },
    formatContent(str) {
      // 格式化 “原文解析” 文章内容
      let content = str || "";
      content = content.replace(
        new RegExp("(&lt;p&gt;|&gt;&lt;/p&gt;)", "g"),
        ""
      );
      content = content.replace(new RegExp("&lt;br /&gt;", "g"), "&lt;br/&gt;");
      let content1 = content.replace(
        /&lt;段析--/g,
        '<span class="jixiebtn"><span class="jiexi-handle duanxi">段</span><span class="jiexi">'
      );
      let content2 = content1.replace(/--段析&gt;/g, "</span></span>");
      let content3 = content2.replace(
        /&lt;句析--/g,
        '<span class="jixiebtn"><span class="jiexi-handle juxi">句</span><span class="jiexi">'
      );
      let content4 = content3.replace(/--句析&gt;/g, "</span></span>");
      let content5 = content4.replace(
        /&lt;词析--/g,
        '<span class="jixiebtn"><span class="jiexi-handle cixi">词</span><span class="jiexi">'
      );
      let content6 = content5.replace(/--词析&gt;/g, "</span></span>");

      let content7 = content6.replace(
        /&lt;译文--/g,
        '<span class="jixiebtn"><span class="jiexi-handle yiwen">译</span><span class="jiexi">'
      );
      let content8 = content7.replace(/--译文&gt;/g, "</span></span>");
      let content9 = content8.split("&lt;br/&gt;");
      return content9;
    },
    initEvent() {
      let that = this;
      let content = document.getElementById("classContent");
      let jiexibtn = content?.getElementsByClassName("jixiebtn");
      if (jiexibtn) {
        for (var i = 0; i < jiexibtn.length; i++) {
          (function () {
            let item = jiexibtn[i];
            item.onclick = function () {
              let jiexi = item.getElementsByClassName("jiexi")[0].innerHTML;
              that.$eventBus.$emit("showAlert", jiexi);
            };
          })(i);
        }
      }
    },

    initCommonErrorQuestion() {
      this.isLoading = true;
      this.$api
        .errorQuestionContent({
          papetypeid: this.$route.query.id,
          page: 1,
          rows: 1000,
        })
        .then((res) => {
          let resData = res.data.list || [];
          if (resData.length) {
            resData.forEach((item, index) => {
              if (item) {
                item.answerVal = "";
                item.answerIdx = "";
                item.questionIdx = [0, index];
                item.showParse = false;
                item.showAnswer = false;
                item.optionjson = this.computeOptionjson(
                  this.formatAddPointWords(item.optionjson)
                );
                item.unknowNum = 0;
              }
            });
          }

          let listData = this.questionData.list || [];
          listData = [...listData, ...resData];
          this.questionData = { title: this.$route.query.name, list: listData };
          this.isLoading = false;
        });
    },

    initCommonQuestion() {
      this.isLoading = true;
      this.$api
        .questionBase({
          papeid: this.currentId,
          type: this.$route.query.type,
          iscourse: this.$route.query.iscourse || 1,
        })
        .then((res) => {
          if (res.data?.id) {
            this.getCommonQuestionType(res.data.id);
          } else {
            this.isEmpty = true;
            this.isLoading = false;
          }
        });
    },
    getCommonQuestionType(id) {
      this.$api.questionTitle({ papeinfoid: id, showToast: 1 }).then((res) => {
        let resData = res.data || [];
        this.totalRequest = resData.length;
        if (resData.length) {
          for (let i = 0; i < resData.length; i++) {
            this.getCommonQuestionContent(resData, i);
          }
        } else {
          this.isEmpty = true;
          this.isLoading = false;
        }
      });
    },
    getCommonQuestionContent(data, i) {
      let content = data[i].content;
      this.$api
        .questionContent({ papetypeid: data[i].id, showToast: 1 })
        .then((res) => {
          let resData = res.data;
          this.accountRequest = ++this.accountRequest;
          if (resData.length) {
            resData.forEach((item, index) => {
              item.parentNum = i;
              item.answerVal = "";
              item.answerIdx = "";
              item.questionIdx = [i, index];
              item.showParse = false;
              item.showAnswer = false;
              item.optionjson = this.computeOptionjson(
                this.formatAddPointWords(item.optionjson)
              );
              item.content = this.formatAddPointWords(content);
              item.testpapecontent = this.formatAddPointWords(
                item.testpapecontent
              );
              item.rightkey = this.formatAddPointWords(item.rightkey);
              item.analysiscontent = this.formatAddPointWords(
                item.analysiscontent
              );
              item.unknowNum = 0;

              if (item.type == 9) {
                let reg = new RegExp(item.mubiaozi);
                let text = this.html2string(item.testpapecontent);
                let space = "";
                for (let i = 0; i < item.mubiaozi.length; i++) {
                  space = space + " ";
                }
                item.testpapecontent = text.replace(reg, space);
                console.log(item.testpapecontent);
              }
            });
            let listData = this.questionData.list || [];
            listData = [...listData, ...resData];
            this.$set(this.questionData, "title", data[i].title);
            this.$set(this.questionData, "list", listData);
            this.dataSource = this.deepClone(this.questionData);
          }
        });
    },

    deleteTag(str) {
      return str.replace(/<\/?.+?>/g, "").replace(/ /g, "");
    },

    changeQuestion(n) {
      switch (n) {
        case 0: {
          if (this.currentQuestion < this.questionData.list.length - 1) {
            this.currentQuestion = ++this.currentQuestion;
          }
          break;
        }
        case 1: {
          if (this.currentQuestion) {
            this.currentQuestion = --this.currentQuestion;
          }
          break;
        }
        default: {
          break;
        }
      }
      this.$store.commit("common/setCurrentQuestion", this.currentQuestion);
    },
    showParseAction() {
      let answerStatus = 1;
      let questionId = this.questionData.list[this.currentQuestion].id || "";
      let optionJson = this.questionData.list[this.currentQuestion].optionjson;
      let rightKey = this.questionData.list[this.currentQuestion].rightkey;
      let type = this.questionData.list[this.currentQuestion].type;
      let answerIdx = String(
        this.questionData.list[this.currentQuestion].answerIdx
      );
      let answerVal = String(
        this.questionData.list[this.currentQuestion].answerVal
      );
      if (type < 3) {
        answerVal = answerVal.split("").sort().join("");
        optionJson.forEach((item, index) => {
          if (rightKey.includes(item.value)) {
            item.status = 2;
          } else {
            if (answerIdx.includes(index)) {
              item.status = 3;
            }
          }
        });
        this.$set(
          this.questionData.list[this.currentQuestion],
          "optionjson",
          optionJson
        );
        if (answerVal == rightKey) {
          answerStatus = 2;
        } else {
          answerStatus = 3;
        }
        if (answerStatus == 2) {
          this.properAudio.play();
        }
        if (answerStatus == 3) {
          this.errorAudio.play();
          this.$api
            .addErrorQuestion({ testpapeid: questionId, showToast: 1 })
            .then((res) => {
              console.log(res);
            });
        }
      }

      this.$set(
        this.questionData.list[this.currentQuestion],
        "showParse",
        true
      );
    },
    showAnswerAction() {
      this.$set(
        this.questionData.list[this.currentQuestion],
        "showAnswer",
        true
      );
    },
  },
};
</script>

<style lang="scss">
.img-text-box {
  * {
    font-size: 0.3rem !important;
    line-height: 2 !important;
  }
}
.question-content {
  margin-bottom: 0.5rem;
  .content-body {
    img {
      max-width: 100%;
    }
  }

  .mubiaozi {
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      top: 0.38rem;
      transform: translateX(-50%);
      content: "";
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 50%;
      background: #333;
    }
  }
}
.class-content {
  p {
    text-indent: 2em;
  }
  .jiexi-handle {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    text-align: center;
    border-radius: 50%;
    color: #fff !important;
    font-size: 0.2rem !important;
    line-height: 0.4rem !important;
    text-indent: 0 !important;
  }
  .duanxi {
    background: #ff3939;
  }
  .juxi {
    background: #28b295;
  }
  .cixi {
    background: #ff9600;
  }
  .yiwen {
    background: #007eff;
  }
  .jiexi {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.select-transelate-mudule {
  position: fixed;
  right: 0.3rem;
  bottom: 1.5rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  z-index: 500;
  background: #28b295;
  font-size: 0.2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.class-list {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  background: #fff;
  font-size: 0.26rem;
  border-bottom: 0.02rem #dcdcdc solid;
  li {
    flex: 1;
    height: 0.72rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    color: #28b295;
    span {
      position: relative;
      height: 100%;
      line-height: 0.72rem;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.01rem;
        content: "";
        height: 0.01rem;
        background: #28b295;
      }
    }
  }
}
.class-image {
  width: 100%;
}
.class-title {
  text-align: center;
  font-size: 0.3rem;
  font-weight: 500;
}
.class-auther {
  font-size: 0.26rem;
  text-align: center;
  margin-bottom: 0.2rem;
}
.class-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 0.24rem;
  line-height: 1.8;
}

.status-vioce {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.title-box {
  font-size: 0.4rem !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}
.question-proper {
  display: flex;
  margin-bottom: 0.2rem;
  font-size: 0.32rem;
  font-family: initial !important;
  .tit {
    word-break: keep-all;
    display: flex;
  }
  ::v-deep * {
    font-size: 0.32rem !important;
    font-family: initial !important;
  }
}
.video-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  .video-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    .post-img {
      width: 100%;
      height: 100%;
    }
    .video-play {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      .iconfont {
        color: #f5f5f5;
        font-size: 1rem;
      }
      span {
        font-size: 0.32rem;
        color: #f5f5f5;
      }
    }
  }
}

.question-handle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.28rem;
  background: #fff;

  .btn {
    width: 2rem;
    height: 0.68rem;
    background-size: cover;
    background-repeat: no-repeat;
    border: 0.02rem solid #28b295;
    border-radius: 0.34rem;
    text-align: center;
    line-height: 0.68rem;
    font-size: 0.26rem;
    font-weight: 400;
    color: #333;
    background: #fff;
    &:active {
      background: rgba(40, 178, 149, 0.1);
    }
    &.disable {
      border-color: #e1e1e1;
      background: #f1f1f1;
    }
  }
}

.common-content {
  height: 100vh;
  background: #fafafa;
  overflow: hidden;

  .common-main {
    min-height: calc(100vh - 1.23rem);
    background: #fff;
    ::v-deep * {
      font-family: initial !important;
    }
  }
  .common-step {
    display: flex;
    align-items: flex-end;
    background: #fff;
    line-height: 1;
    .current {
      font-size: 0.52rem;
      color: #36ba9e;
      transform: translateY(0.03rem);
    }
    .total {
      font-size: 0.3rem;
      color: #969799;
    }
  }
  .common-progress {
    position: fixed;
    left: 0;
    right: 0;
    top: 1.08rem;
    height: 0.18rem;
    z-index: 100;
    background: #fafafa;
    .con {
      height: 0.18rem;
      background: #36ba9e;
    }
  }
  .image-text-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 1.28rem;
    bottom: 0;
    padding: 0.3rem;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .more {
    padding-top: 0.92rem;
  }
  // .isContent {
  //   min-height: calc(100vh - 1.23rem);
  // }
  .isQuestion {
    bottom: 1.24rem;
  }
  .isWordsQuestion {
    min-height: calc(100vh - 1.23rem);
  }
}
.my-swipe.textBody {
  width: 100vw;
  height: calc(100vh - 2.44rem);
}
.image-box.textBody {
  width: 100%;
  height: 100%;
  .van-image {
    transform: rotate(90deg) scale(2);
    width: 100%;
    height: 100%;
  }
}
.empty-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>