<template>
  <div class="custom-alert" @click="clickAlert">
    <div class="alert-main" v-if="alertHtml" v-html="alertHtml"></div>
    <div class="alert-main" v-if="alertContent"  v-html="alertContent"> </div>
  </div>
</template>

<script>
export default {
  name: "customAlert",
  props: {
    alertHtml: {
      type: [Number, String],
      default: "",
    },
    alertContent: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    clickAlert() {
      this.$eventBus.$emit("clickAlert");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .alert-main {
    width: 80%;
    padding: 0.28rem;
    min-height: 1rem;
    background: #fff;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    animation: fadeIn 0.3s;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -3rem, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>