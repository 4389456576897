<template>
  <div id="app">
    <router-view />
    <!-- <div class="loading-box" v-if="isLoading">
      <van-loading size=".72rem" vertical type="spinner" color="#1989fa" />
    </div> -->
    <customAlert v-if="showAlert" :alertContent="alertContent"></customAlert>
  </div>
</template>

<script>
import { getToken, setToken } from "@/utils/token";
import { ImagePreview } from "vant";
export default {
  name: "App",
  data() {
    return {
      whiteList: [],
      showAlert: false,
      alertContent: "",
      timer: null,
      //isLoading: false,
    };
  },
  watch: {
    $route: function (val) {
      if (val.path == "/login") {
        window.document.title="登录"
      } else{
        window.document.title="精准学移动端"
      }
    },
  },
  created() {
    window.document.title = "登录";
  },
  mounted() {
    this.timer = setTimeout(() => {
      let path = this.$route.path;
      if (path != "/login") {
        let token = this.getToken();
        if (token && token != "") {
          this.checkToken();
        }
      }
    }, 300);

    this.$eventBus.$on("showAlert", (val) => {
      this.showAlert = true;
      this.alertContent = val;
    });
    this.$eventBus.$on("clickAlert", () => {
      this.showAlert = false;
    });
    window.addEventListener("click", (e) => {
      let disableView = e.target.dataset.disable;
      let routerPath = this.$route.path;
      if (
        [
          "/questionContent",
          "/userTest",
          "/answerParse",
          "/textContent",
        ].includes(routerPath) &&
        !disableView
      ) {
        let childrenDom = e.path[0].children;
        let imgStr = null;
        for (let i = 0; i < childrenDom.length; i++) {
          if (childrenDom[i].nodeName == "IMG") {
            imgStr = childrenDom[i];
            break;
          }
        }
        if (imgStr) {
          if (imgStr.currentSrc) {
            ImagePreview({
              images: [imgStr.currentSrc],
              startPosition: 0,
            });
          }
        }
      }
    });
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    getToken,
    setToken,
    checkToken() {
      this.$api
        .checkToken({
          showToast: 1,
        })
        .then((res) => {
          let expiresTime = res.data.expires_in;
          if (expiresTime < 300000) {
            this.$store.commit("common/setRefreshToken", true);
            this.refreshToken();
          } else {
            this.$store.commit("common/setRefreshToken", false);
          }
        });
    },
    refreshToken() {
      this.$api.refreshToken().then((res) => {
        this.setToken(res.data.token);
        this.$store.commit("common/setRefreshToken", false);
      });
    },
  },
};
</script>

<style lang="scss">
@import "assets/css/baseStyle.scss";
@import "assets/css/iconfont.css";
img {
  max-width: 100%;
}
.math-ba {
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0.07rem;
    content: "";
    height: 0.01rem;
    background: #333;
  }
}
.cimg {
  // display: inline-block;
  // padding-bottom: 0.8rem;
  img {
    // transform: translateY(43%);
    vertical-align: middle;
  }
}
.van-image-preview {
  .van-image {
    background: #fff;
  }
}
.question-box.question-common {
  .question-test {
    overflow: auto;
    img {
      max-width: 100%;
    }
    table {
      width: 100%;
      border-left: 0.01rem #333 solid;
      border-top: 0.01rem #333 solid;
      border-collapse: collapse;
      td {
        border-right: 0.01rem #333 solid;
        border-bottom: 0.01rem #333 solid;
      }
    }
  }
}
.math-formula {
  line-height: 1 !important;

  font-family: MathJax_Main !important;
  * {
    line-height: 1 !important;
  }
}
#app {
  font-family: PingFangSC-Medium, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  user-select: none;
}
.edit-content {
  height: calc(100vh - 2.72rem);
  overflow-x: hidden;
  overflow-y: auto;
  img {
    max-width: 100%;
  }
}
.vantabs {
  .van-tabs__line {
    width: 0.8rem;
    height: 0.06rem;
    border-radius: 0.03rem;
    background-color: #2db79a;
  }
  .van-tabs__wrap {
    height: 0.88rem !important;
  }
  .van-tab {
    font-size: 0.28rem !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    color: #969799 !important;
  }
  .van-tab--active {
    color: #2db79a !important;
  }
}
.public-content {
  background: #fff;
  margin-top: 0.15rem;
  min-height: calc(100vh - 1.23rem);
  .con {
    padding: 0.28rem;
  }
}
.loading-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
}
.page-content {
  padding-top: 1.08rem;
  min-height: 100vh;
  background: #f7f8fa;
  overflow-x: hidden;
}
.white-bg {
  background: #fff;
}
.inner-page {
  position: fixed;
  left: 0;
  top: 1.08rem;
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.no-more {
  font-size: 0.22rem;
  color: #909090;
  font-weight: 400;
  line-height: 0.52rem;
  text-align: center;
}
.jiexi-btn {
  background: #2db79a !important;
  color: #fff !important;
  &:active {
    opacity: 0.8;
  }
}
</style>
