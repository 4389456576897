<template>
  <div class="course-page">
    <navBar>
      <span class="title">知识全解</span>
    </navBar>
    <div class="page-content">
      <ul class="class-list" v-if="dataList.length">
        <li class="unit" v-for="(unit, index) in dataList" :key="`a${index}`">
          <p class="unit-title">{{ unit.title }}</p>
          <ul class="unit-list">
            <li
              class="course"
              v-for="(course, index1) in unit.children"
              :key="`b${index1}`"
            >
              <div
                class="course-header"
                @click.stop="toggleHandle(index, index1, course)"
              >
                <div class="left">
                  <van-image
                    class="course-img"
                    fit="cover"
                    :src="setSourceUrl(course.iconimage)"
                  ></van-image>
                  <p class="course-name">
                    <span>{{
                      computeIndex(index, index1, course)
                        ? `${computeIndex(index, index1, course)}、`
                        : ""
                    }}</span
                    >{{ course.title }}
                  </p>
                </div>
                <div class="right" :class="{ active: course.showHandle }">
                  <van-icon
                    name="arrow"
                    class="icon"
                    :class="{ arrowRight: course.url }"
                  />
                </div>
              </div>
              <ul class="nav-list" v-show="course.showHandle">
                <li
                  class="nav"
                  v-for="(nav, index3) in course.children"
                  :key="`c${index3}`"
                  @click.stop="goNext(nav)"
                >
                  <div class="con">
                    <van-image
                      class="icon-img"
                      fit="cover"
                      :src="setSourceUrl(nav.iconimage)"
                    ></van-image>
                    <span>{{ nav.title }}</span>
                  </div>

                  <van-icon name="arrow" class="icon-arrow" />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
export default {
  name: "classCourse",
  computed: {
    classNav: {
      get: function () {
        return this.$store.getters["course/getClassNav"];
      },
      set: function (data) {
        this.$store.commit("course/setClassNav", data);
      },
    },
    subNav: {
      get: function () {
        return this.$store.getters["common/getSubNav"];
      },
      set: function (data) {
        this.$store.commit("common/setSubNav", data);
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    setSourceUrl,
    init() {
      this.getCourseUnit();
    },
    computeIndex(idx1, idx2, item) {
      if (item.url && item.url != "") {
        return "";
      }
      let _len = this.dataList[idx1 - 1]
        ? this.dataList[idx1 - 1].children.length
        : 0;
      if (_len) {
        for (let i = 0; i < _len; i++) {
          if (
            this.dataList[idx1 - 1].children[i].url &&
            this.dataList[idx1 - 1].children[i].url != ""
          ) {
            _len = _len - 1;
          }
        }
      }
      return _len + idx2 + 1;
    },
    getCourseUnit() {
      this.$api
        .getCourseUnit({
          courseid: this.currentId,
        })
        .then((res) => {
          let _data = res.data || [];
          for (let i = 0; i < _data.length; i++) {
            for (let j = 0; j < _data[i].children.length; j++) {
              this.$set(_data[i].children[j], "showHandle", false);
            }
          }

          this.setData(_data);
        });
    },
    toggleHandle(idx1, idx2, item) {
      if (item.children.length) {
        let newData = this.dataList[idx1].children;
        newData[idx2].showHandle = !newData[idx2].showHandle;
        this.$set(this.dataList[idx1], "children", newData);
      }
      if (item.url && item.url != "") {
        this.$router.push({
          path: "/userTest",
          query: {
            name: item.title,
            id: item.id,
          },
        });
      }
    },

    goNext(nav) {
      let path = nav.url || "/commonContent";
      this.$router.push({
        path: path,
        query: {
          id: nav.id,
          type: nav.type,
          name: nav.title,
        },
      });
      this.classNav = nav.children;
      this.subNav = nav.children;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list {
  padding-bottom: 0.32rem;
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.12rem;
    padding: 0 0.26rem 0 0.22rem;
    background: #f9fafb;
    border-bottom: 0.01rem #dcdcdc solid;
    &:active {
      opacity: 0.8;
    }
    &:last-child {
      border: 0;
    }
    .con {
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333334;
      .icon-img {
        width: 0.72rem;
        height: 0.72rem;
        margin-right: 0.24rem;
      }
    }
    .icon-arrow {
      color: #333;
      opacity: 0.5;
      font-size: 0.24rem;
    }
  }
}
.unit-list {
  .course {
    background: #ffffff;
    margin-bottom: 0.16rem;
    padding: 0 0.32rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .course-header {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .course-img {
        width: 0.96rem;
        height: 0.76rem;
        border-radius: .08rem;
        overflow: hidden;
        margin-right: 0.32rem;
      }
      .course-name {
        font-size: 0.28rem;
        font-weight: 400;
        color: #323233;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 1rem;
      width: 1rem;
      .icon {
        transform: rotate(90deg);
        font-size: 0.24rem;
        color: #333;
        opacity: 0.5;
        &.arrowRight {
          transform: rotate(0);
        }
      }
      &.active {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
.unit {
  .unit-title {
    position: relative;
    display: flex;
    align-items: center;
    height: 1rem;
    padding-left: 0.32rem;
    font-weight: 500;
    font-size: 0.3rem;
  }
}
.page-content {
  background: #f7f8fa;
}
</style>