//设置一个固定的key值
const KEY='Authorization'
//暴露三个方法

//设置token
export function setToken(token){
    window.localStorage.setItem(KEY,token);
}

//获取token
export function getToken(){
   return window.localStorage.getItem(KEY) || '';
}

//删除token
export function removeToken(){
    //根据key,删除指定的值
    window.localStorage.removeItem(KEY);
}

